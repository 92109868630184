import {
  Grid,
  GridItem,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useReassignDonorTicketMutation } from "../../gql/generated/graphql";

const ReassignDonorTicketForm = ({ donorTicket, onComplete }) => {
  const { register, handleSubmit } = useForm();
  const toast = useToast();
  const [reassignDonorTicker] = useReassignDonorTicketMutation();
  const onSubmit = (data) => {
    reassignDonorTicker({
      variables: {
        token: donorTicket.token,
        name: data.name,
        email: data.email,
      },
    }).then(({ data }) => {
      if (!data.reassignDonorTicket.errors) {
        toast({
          title: "Ticket details updated.",
          description: "Ticket details successfully updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onComplete && onComplete();
        return;
      } else {
        toast({
          title: "Ticket details not updated.",
          description: data.reassignDonorTicker.errors,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={4}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              defaultValue={donorTicket.name}
              placeholder="Name"
              {...register("name")}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              defaultValue={donorTicket.email}
              placeholder="Email"
              {...register("email")}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <Button colorScheme="blue" type="submit">
            Save
          </Button>
        </GridItem>
      </Grid>
    </form>
  );
};

export default ReassignDonorTicketForm;
