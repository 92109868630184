import { Routes, Route } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import Home from "./pages/home";
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";
import { usePageTracking } from "./hooks/usePageTracking";
import PublicTicket from "./pages/public_ticket";

// import Dashboard from "./pages/dashboard";
// const Dashboard = lazy(() => import('./pages/Dashboard'));
const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const Campaigns = lazyWithRetry(() => import("./pages/campaigns"));
const Campaign = lazyWithRetry(() => import("./pages/campaigns/campaign"));
const EditCampaign = lazyWithRetry(() =>
  import("./pages/campaigns/campaign/Edit")
);
const ScanTicket = lazyWithRetry(() =>
  import("./pages/campaigns/campaign/ScanTicket")
);
const EditFundraiser = lazyWithRetry(() =>
  import("./pages/fundraisers/fundraiser/Edit")
);
const Teams = lazyWithRetry(() => import("./pages/teams"));
const Team = lazyWithRetry(() => import("./pages/teams/team"));
const Donations = lazyWithRetry(() => import("./pages/donations"));
const Donation = lazyWithRetry(() => import("./pages/donations/donation"));
const EditDonation = lazyWithRetry(() =>
  import("./pages/donations/donation/Edit")
);
const DonorTickets = lazyWithRetry(() => import("./pages/donor_tickets"));
const Donors = lazyWithRetry(() => import("./pages/donors"));
const Donor = lazyWithRetry(() => import("./pages/donors/donor"));
const EditDonor = lazyWithRetry(() => import("./pages/donors/donor/Edit"));
const CreateDonation = lazyWithRetry(() => import("./pages/donations/Create"));
const CreateDonor = lazyWithRetry(() => import("./pages/donors/Create"));
const CreateCampaign = lazyWithRetry(() => import("./pages/campaigns/Create"));
const Give = lazyWithRetry(() => import("./pages/give"));
const CreateFundraiser = lazyWithRetry(() =>
  import("./pages/fundraisers/Create")
);
const Subscriptions = lazyWithRetry(() => import("./pages/subscriptions"));
const EditTeam = lazyWithRetry(() => import("./pages/teams/team/EditTeam"));
const EditDonationReceipt = lazyWithRetry(() =>
  import("./pages/donation_receipts/donation_receipt/Edit")
);
const CreateDonationReceipt = lazyWithRetry(() =>
  import("./pages/donation_receipts/Create")
);
const Login = lazyWithRetry(() => import("./pages/login"));
const Signup = lazyWithRetry(() => import("./pages/signup"));
const ForgotPassword = lazyWithRetry(() => import("./pages/forgot_password"));
const ResetPassword = lazyWithRetry(() => import("./pages/reset_password"));
const CreateTeam = lazyWithRetry(() => import("./pages/teams/Create"));
const ManageSubscription = lazyWithRetry(() =>
  import("./pages/subscriptions/ManageSubscription")
);
const DonationSuccess = lazyWithRetry(() => import("./pages/donation_success"));
const PurchaseSuccess = lazyWithRetry(() =>
  import("./pages/donation_success/PurchaseSuccess")
);
const PledgeSuccess = lazyWithRetry(() =>
  import("./pages/donation_success/PledgeSuccess")
);
const ManageTeam = lazyWithRetry(() => import("./pages/teams/team/Manage"));
const TeamSettings = lazyWithRetry(() => import("./pages/teams/team/settings"));
const Integrations = lazyWithRetry(() => import("./pages/integrations"));
const CreateEvent = lazyWithRetry(() => import("./pages/events/Create"));
const EditEvent = lazyWithRetry(() => import("./pages/events/event/Edit"));
const EventsPublic = lazyWithRetry(() => import("./pages/events_public"));
const PledgePublic = lazyWithRetry(() => import("./pages/pledges_public"));
const DonationReceipts = lazyWithRetry(() =>
  import("./pages/donation_receipts")
);

const Payments = lazyWithRetry(() => import("./pages/payments"));

const Dashboard = lazyWithRetry(() => import("./pages/dashboard"));
const Loading = () => <div></div>;

const Routing = () => {
  usePageTracking();
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="teams" element={<Teams />} />
        <Route path="/give/:campaignId" element={<Give />} />
        <Route path="/event/:campaignId" element={<EventsPublic />} />
        <Route path="/pledge/:campaignId" element={<PledgePublic />} />
        <Route path="/manage-donations" element={<ManageSubscription />} />
        <Route path="/donation-success" element={<DonationSuccess />} />
        <Route path="/pledge-success" element={<PledgeSuccess />} />
        <Route path="/purchase-success" element={<PurchaseSuccess />} />
        <Route path="/:campaignId/tickets" element={<PublicTicket />} />
        <Route path="/teams/new" element={<CreateTeam />} />
        <Route path="/teams" element={<Dashboard />}>
          <Route path="/teams/:teamId" element={<Team />} />
          <Route path="/teams/:teamId/manage" element={<ManageTeam />} />
          <Route path="/teams/:teamId/settings" element={<TeamSettings />} />
          <Route
            path="/teams/:teamId/integrations"
            element={<Integrations />}
          />
          <Route
            path="/teams/:teamId/payments"
            element={<Payments />}
          />
          <Route path="/teams/:teamId/edit" element={<EditTeam />} />
          <Route path="/teams/:teamId/campaigns" element={<Campaigns />} />
          <Route path="/teams/:teamId/donations" element={<Donations />} />
          <Route
            path="/teams/:teamId/donations/:donationId"
            element={<Donation />}
          />
          <Route
            path="/teams/:teamId/donation-receipts"
            element={<DonationReceipts />}
          />
          <Route path="/teams/:teamId/tickets" element={<DonorTickets />} />
          <Route
            path="/teams/:teamId/subscriptions"
            element={<Subscriptions />}
          />
          <Route path="/teams/:teamId/donors" element={<Donors />} />
          <Route
            path="/teams/:teamId/campaigns/:campaignId"
            element={<Campaign />}
          />
          <Route
            path="/teams/:teamId/campaigns/:campaignId/edit"
            element={<EditCampaign />}
          />
          <Route
            path="/teams/:teamId/scan-ticket"
            element={<ScanTicket />}
          />
          <Route
            path="/teams/:teamId/donation-receipts/new"
            element={<CreateDonationReceipt />}
          />
          <Route
            path="/teams/:teamId/donation-receipts/edit/:donationReceiptId"
            element={<EditDonationReceipt />}
          />
          <Route
            path="/teams/:teamId/campaigns/:campaignId/fundraisers/new"
            element={<CreateFundraiser />}
          />
          <Route
            path="/teams/:teamId/campaigns/:campaignId/fundraisers/:fundraiserId/edit"
            element={<EditFundraiser />}
          />

          <Route
            path="/teams/:teamId/campaigns/:campaignId/events/new"
            element={<CreateEvent />}
          />
          <Route
            path="/teams/:teamId/campaigns/:campaignId/events/:eventId/edit"
            element={<EditEvent />}
          />

          <Route path="/teams/:teamId/donors/:donorId" element={<Donor />} />
          <Route
            path="/teams/:teamId/donors/:donorId/edit"
            element={<EditDonor />}
          />
          <Route
            path="/teams/:teamId/donations/:donationId/edit"
            element={<EditDonation />}
          />
          <Route
            path="/teams/:teamId/donations/new"
            element={<CreateDonation />}
          />
          <Route path="/teams/:teamId/donors/new" element={<CreateDonor />} />
          <Route
            path="/teams/:teamId/campaigns/new"
            element={<CreateCampaign />}
          />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Routing;
