import { VStack, Text } from "@chakra-ui/react";

const PurchaserDetails = ({ donorTicket }) => {
  return (
    <VStack>
      <Text color="white" fontSize="2xl">
        Purchased By: {donorTicket.donation.donor.name}
      </Text>
      {donorTicket.name && (
        <Text color="white" fontSize="2xl">
          Assigned To: {donorTicket.name}
        </Text>
      )}
      <Text color="white">{donorTicket.ticket.name}</Text>
      <Text fontWeight="bold" color="white">
        {donorTicket.checkedInAt ? "Checked In" : "Not Checked In"}
      </Text>
    </VStack>
  );
};

export default PurchaserDetails;
