import { Container } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import DonorTicket from "../../components/public_tickets/DonorTicket";
import { useDonorTicketsByDonationTokenQuery } from "../../gql/generated/graphql";
import Loader from "../../ui/Loader";

const PublicTicket = () => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { data = {}, loading } = useDonorTicketsByDonationTokenQuery({
    variables: {
      token,
      campaignId,
    },
    pollInterval: 1000,

  });

  if (loading) {
    return <Loader />;
  }

  const { donorTicketsByDonationToken } = data;
  return (
    <Container maxWidth="container.xl">
      {donorTicketsByDonationToken.map((donorTicket) => (
        <DonorTicket
          key={donorTicket.id}
          donorTicket={donorTicket} />
      ))}
    </Container>
  );
};

export default PublicTicket;
