import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  IconButton,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { FaQrcode } from "react-icons/fa";
import PurchaserDetails from "./PurchaserDetails";

const QRTicketModal = ({ donorTicket }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label="Print Ticket"
        icon={<FaQrcode />}
      />
      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          backgroundColor={donorTicket.checkedInAt ? "green.500" : "blue.900"}
        >
          <ModalCloseButton color="white" />
          <ModalBody>
            <Flex flexDir="column" alignContent="center" alignItems="center">
              <Text color="white" fontSize="2xl" p={8}>
                Scan this QR code to check in
              </Text>
              <QRCode id="QRCode" value={donorTicket.token} />
              <Box p={4}>
                <PurchaserDetails donorTicket={donorTicket} />
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QRTicketModal;
