import {
  Box,
  HStack,
  Flex
} from "@chakra-ui/react";
import PurchaserDetails from "./PurchaserDetails";
import QRTicketModal from "./QRTicketModal";
import ReassignDonorTicketModal from "./ReassignDonorTicketModal";

const DonorTicket = ({ donorTicket }) => {
  return (
    <Box>
      <Box m={4} p={4} backgroundColor={donorTicket.checkedInAt ? "green.500" : "blue.900"}>
        <Flex direction={["column", "row"]} justifyContent="space-around">
          <PurchaserDetails donorTicket={donorTicket} />
          <HStack>
            <QRTicketModal donorTicket={donorTicket} />
            <ReassignDonorTicketModal donorTicket={donorTicket} />
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default DonorTicket;
