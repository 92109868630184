import { Box, Text, Container } from "@chakra-ui/react";

const Privacy = () => {
  return (
    <Container>
      <Box>
        <Text py={4}>FUNDERY PRIVACY POLICY</Text>
        <Text py={4}>
          Last revised November 18, 2022 This Privacy Policy explains how we
          collect, use and share information we collect on our online interfaces
          and properties (e.g., websites and mobile applications) owned and
          controlled by Fundery, (“Company,” “we,” “us” or “our” ), including
          the www.fundery.co website (collectively, the “Site”). The Site
          provides (a) an online platform for appropriately qualified charities
          and other entities (“Fundraiser”) to raise funds from Site visitors
          (“Donors”) through the sale of goods or services or to solicit
          donations to support their organization and/or mission and (b) related
          services (collectively the “Services”). Fundery. only provides
          technology to allow Fundraisers and Donors or other users to connect
          and does not participate in the fundraising activities or the
          interactions between Fundraisers and Donors using the Services.
          <Text py={4}>
            By accessing the Site or using the Services you consent to our
            collection, storage, use and disclosure of your personal information
            as described in this Privacy Policy. If you do not agree to the
            terms of this Policy, you should discontinue using the Site
            immediately.
          </Text>
          <Text py={4}>I. INFORMATION WE COLLECT</Text>
          <Text py={4}>
            We collect both “Non-Personal Information” about usage of the Site
            and “Personally Identifiable Information” about Site visitors.
            Non-Personal Information includes automatically collected
            information that cannot be used to personally identify you, such as
            anonymous usage data, general demographic information we may
            collect, referring/exit pages and URLs, platform types, preferences
            you submit and preferences that are generated based on the data you
            submit and number of clicks. Personally Identifiable Information
            includes information that Site visitors submit to us that identifies
            or relates to a specific individual.
          </Text>
          <Text py={4}>
            We collect Personally Identifiable Information that you submit,
            including registration information submitted by Fundraisers (such as
            name, email and postal address, telephone number, organization name,
            billing information, campaign information), information submitted by
            Donors when interacting with Fundraisers (name, email and postal
            addresses, and other information requested by Fundraisers), and
            contact information if you contact us with an inquiry (email
            address, telephone number).
          </Text>
          <Text py={4}>
            We do not collect payment information from Donors; Donors wishing to
            make a purchase from or donate funds to Fundraisers are connected to
            Stripe or another of our third-party payment processors, who
            collects and processes payment information.
          </Text>
          <Text py={4}>
            We use cookies and similar technology to collect aggregate,
            Non-Personal Information about Site usage by all of our visitors and
            to help us remember you and your preferences when you revisit the
            Site. We track information provided to us by your browser or by our
            software application when you view or use the Service, such as the
            website you came from (known as the “referring URL”), the type of
            browser you use, the device from which you connected to the Service,
            the time and date of access, and other information that does not
            personally identify you. We track this information using cookies, or
            small text files which include an anonymous unique identifier.
            Cookies are sent to a user’s browser from our servers and are stored
            on the user’s computer hard drive. Sending a cookie to a user’s
            browser enables us to collect Non-Personal Information about that
            visitor and keep a record of the visitor’s preferences when
            utilizing our services, both on an individual and aggregate basis.
            We may use both persistent and session cookies; persistent cookies
            remain on your computer after you close your session and until you
            delete them, while session cookies expire when you close your
            browser. For example, we may use a persistent cookie to track
            whether you have visited Fundery before, so we can show you basic
            information about our Service on your first visit. Further general
            information about cookies and how they work is available at
            www.allaboutcookies.org.
          </Text>
          <Text py={4}>
            We allow selected third parties to place cookies through the Site to
            provide us with better insights into the use of the Site or user
            demographics or to provide relevant advertising to you. These third
            parties may collect information about a consumer’s online activities
            over time and across different websites when he or she uses our
            website. We may also permit third-party service providers to place
            cookies through our Site to perform analytic or marketing functions.
            For instance, we use Google Analytics to collect information about
            use of our Site. This information is used for the sole purpose of
            improving our Site. Google Analytics does not collect any Personally
            Identifying Information, nor do we combine the information collected
            with Personally Identifiable information. We do not control the use
            of such third-party cookies or the resulting information and we are
            not responsible for any actions or policies of such third parties.
          </Text>
          <Text py={4}>
            {" "}
            We do not use technology that recognizes a “do-not-track” signal
            from your web browser.
          </Text>
          <Text py={4}> ‍ II. HOW WE USE YOUR INFORMATION</Text>
          <Text py={4}>
            {" "}
            We will use the information you provide for our general commercial
            purposes such as to improve our Site, grow our business, protect the
            security of our Site and Services, and to offer our own or
            third-party products or services that we think you may find of
            interest. We may also use the information you provide us to notify
            you of relevant information pertinent to the Site or Services or
            your registered account with us. We use your contact information to
            respond to your inquiries or to provide information on products or
            services to you.
          </Text>
          <Text py={4}>‍ III. SHARING OF PERSONAL INFORMATION</Text>
          <Text py={4}>
            We do not sell, share, or disclose Personal Information about our
            users and Site visitors except as described in this Privacy Policy.
          </Text>
          <Text py={4}> We may disclose your information if</Text>
          <Text py={4}> i) you request or authorize it;</Text>
          <Text py={4}>
            (ii) the information is provided to help complete a transaction for
            you;
          </Text>
          <Text py={4}>
            (iii) the information is provided to comply with the law, applicable
            regulations, governmental and quasi-governmental requests, court
            orders or subpoenas, to enforce our Terms of Use or other
            agreements, or to protect our rights, property or safety or the
            rights, property or safety of our users or others (e.g., to a
            consumer reporting agency for fraud protection etc.);
          </Text>{" "}
          <Text py={4}>
            (iv) the disclosure is done as part of a purchase, transfer or sale
            of services or assets (e.g., in the event that substantially all of
            our assets are acquired by another party, your information may be
            one of the transferred assets);
          </Text>
          <Text py={4}>
            (v) the information is provided to our agents, outside vendors or
            service providers to perform functions on our behalf (e.g.,
            analyzing data, providing marketing assistance, providing customer
            service, processing orders, etc.); or{" "}
          </Text>
          <Text py={4}>
            (vi) as otherwise described in this Privacy Policy. We may disclose
            your non-private, aggregated, or otherwise Non-Personal Information,
            such as usage statistics of our Site or Services, to our affiliates
            and third parties.{" "}
          </Text>
          <Text py={4}>‍ IV. DATA PROTECTION</Text>
          <Text py={4}>
            We use reasonable technical, administrative and physical measures to
            protect information contained in our system against misuse, loss or
            alteration. However, no method of electronic transmission or storage
            is 100% secure and all Site visitors assume the risk of any
            information disclosed to the Site online. If we receive instructions
            using your log-in information we will consider that you have
            authorized the instructions.
          </Text>{" "}
          ‍ V. THIRD-PARTY LINKS We may permit others to link to this Site or to
          post a link to their site on ours. We do not endorse these linked
          sites. These sites are out of our direct control, and by visiting the
          Site our Site visitors acknowledge this fact, and accept any risks
          that may be incurred by visiting other sites. These third-party sites
          have separate and independent privacy policies. Please read their
          privacy policies before submitting information. We hold no
          responsibility or liability for content and activities on these sites.
          The Site may contain advertisements or sponsorships, which are offered
          by third-parties, as well as, in some instances, the Site itself or
          parties associated with Company. We do not endorse the companies
          placing such advertisements or sponsorships and are not responsible
          for any Personally Identifiable Information collected by
          advertisements not associated with the Site or the Company. ‍
          <Text py={4}>VI. ACCESSING YOUR INFORMATION AND YOUR CHOICES</Text>
          <Text py={4}>
            {" "}
            Registered users can update, amend or delete information at any time
            by logging into their account.{" "}
          </Text>
          <Text py={4}>
            All users may unsubscribe or opt out of any promotional emails sent
            by Company, by sending an email to info@fundery.co, with the subject
            “UNSUBSCRIBE” or by following the instructions in an email you
            receive from us.
          </Text>
          <Text py={4}>
            {" "}
            You can choose to delete or block cookies by setting your browser to
            either reject all cookies or to allow cookies only from selected
            sites. If you block cookies performance of the Site may be impaired
            and certain features may not function at all.
          </Text>
          <Text py={4}>VII. CHILDREN’S PRIVACY</Text>
          <Text py={4}>
            This Site is not designed nor intended to be attractive to use by
            children under the age of 13. If you are under 13 please do not
            submit any information to us. We do not knowingly collect
            information of minors under the age of 13 years of age online. If we
            discover that we hold information from an individual under the age
            of 13, we will delete such information immediately. Furthermore, we
            restrict all visitors under the age of 18 from registering an
            account on the Site or using the Services.{" "}
          </Text>
          ‍<Text py={4}>VIII. NOTICE TO CALIFORNIA RESIDENTS</Text>{" "}
          <Text py={4}>
            Your California Privacy Rights. Certain California residents may
            request disclosure about the manner in which we share certain
            categories of information with others for their marketing purposes.
            If you would like a copy of this notice, send an email to
            info@fundery.co with the subject of “CALIFORNIA PRIVACY RIGHTS.”
            California Consumer Privacy Act. At this time the Company is not
            required to comply with the California Consumer Privacy Act (CCPA).
            We will update this Privacy Policy when we qualify for coverage
            under the CCPA. ‍
          </Text>
          <Text py={4}>IX. NOTICE TO NEVADA RESIDENTS</Text>
          <Text py={4}>
            We do not transfer personal information for monetary consideration.
            If you would like to tell us not to sell your information in the
            future please email us at info@fundery.co with your name, postal
            address, telephone number and email address with “Nevada do not
            sell” in the subject line.
          </Text>{" "}
          <Text py={4}>‍ X. DATA PROCESSING AND CROSS-BORDER DATA TRANSFERS</Text>
          <Text py={4}>
            Our Site is maintained on servers located in the United States, and
            personal information submitted is stored on our servers in the
            United States. If you are visiting our Site from outside the United
            States, please be advised that your information is transferred to
            our U.S. servers. Disclosing your personal information to us
            pursuant to this Privacy Policy is at your own risk. We strive to
            comply with laws of jurisdictions in which we maintain operations
            but we make no representations that the practices described in this
            Privacy Policy are compliant with laws outside those jurisdictions
            that apply to the collection, security, use and disclosure of
            personal information.
          </Text>
          <Text py={4}>‍ XI. CHANGES TO OUR PRIVACY POLICY</Text>
          <Text py={4}>
            We reserve the right to make changes to our privacy policy at any
            time, at our sole discretion. The Policy in effect at the time you
            use the Site governs how we may use your information. If we make
            material changes we will post the revised Policy and the revised
            effective date on this Site. Please check back here from time to
            time to review any changes.
          </Text>
          <Text py={4}>‍ XII. HOW TO CONTACT US</Text>
          <Text py={4}>
            We would love to hear from you. This Site is owned and operated by
            Fundery. Please direct all inquiries to info@fundery.co.
          </Text>
        </Text>
      </Box>
    </Container>
  );
};

export default Privacy;
