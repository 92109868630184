import { ChakraProvider } from "@chakra-ui/react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import ApolloContainer from "./ApolloContainer";
import customTheme from "./utils/theme";

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={customTheme}>
        <CookiesProvider>
          <ApolloContainer>
            <Routes />
          </ApolloContainer>
        </CookiesProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
