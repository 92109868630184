import { Box, SimpleGrid } from '@chakra-ui/react'
import { FcDoughnutChart, FcMultipleDevices, FcPrivacy, FcTimeline } from 'react-icons/fc'
import Feature from './Feature'

const Features = () => (
  <Box as="section" maxW="5xl" mx="auto" py="12" px={{ base: '6', md: '8' }}>
    <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="10" spacingY={{ base: '8', md: '14' }}>
      <Feature title="Accept Donations" icon={<FcPrivacy />}>
        Accept donations for fundraisers with just a few clicks.
      </Feature>
      <Feature title="Recurring Donations" icon={<FcTimeline />}>
        Accept recurring donations.
      </Feature>
      <Feature title="Donation Receipts" icon={<FcDoughnutChart />}>
        Provide your donors tax receipts.
      </Feature>
      <Feature title="Easy Management" icon={<FcMultipleDevices />}>
        See all your donors and donations at a glance.
      </Feature>
    </SimpleGrid>
  </Box>
)

export default Features
