import { Box, Text, Container } from "@chakra-ui/react";

const Terms = () => {
  return (
    <Container>
      <Box>
        <Text py={4}>Fundery Online Terms Of Service</Text>
        <Text py={4}>
          Last revised November 18, 2022 These terms and conditions of use
          (“Terms”) govern your use of our online interfaces and properties
          (e.g., websites and mobile applications) owned and controlled by
          Fundery. (“Company,” “we,” “us” or “our” ), including the
          www.fundery.co website (collectively, the “Site”). Your compliance
          with these Terms is a condition to your use of the Site. If you do not
          agree to be bound by these Terms, promptly exit the Site.
        </Text>
        <Text py={4}>
          Agreement to Binding Arbitration. By accessing the Site, you
          irrevocably agree that all disputes between you and Company that in
          any way relate to these Terms or your use of the Site will be resolved
          by BINDING ARBITRATION. ACCORDINGLY, YOU EXPRESSLY WAIVE ANY AND ALL
          RIGHTS TO GO TO COURT (INCLUDING IN A CLASS ACTION PROCEEDING) to
          assert or defend your rights under these Terms (except for matters
          that may be taken to small claims court). Your rights will be
          determined by a NEUTRAL ARBITRATOR and NOT a judge or jury, and your
          claims cannot be brought as a class action. Please review the Section
          below entitled Dispute Resolution; Arbitration Agreement for the
          details regarding your agreement to arbitrate any disputes with
          Company. All claims must be brought within one year.
        </Text>
        <Text py={4}>
          1. The Site. The Site provides (a) an online platform for
          appropriately qualified charities, individuals and other entities
          (“Fundraisers”) to raise funds through the sale of goods or services
          or to solicit donations to support their organization and/or mission
          and (b) related services (collectively, the “Services”). Site visitors
          (“Users”) wishing to make purchases from or to make donations to a
          registered Fundraiser (“Supporters”) interact directly with the
          Fundraiser. The Services including such transactions are governed by
          these Terms. In the course of your use of the Site, you may be asked
          to provide certain information to us. You acknowledge and agree that
          you are solely responsible for the accuracy and content of such
          information. Our use of any information you provide via the Site shall
          be governed by our Privacy Policy available at
          https://www.fundery.co/privacy (our “Privacy Policy”). We urge you to
          read our Privacy Policy.
        </Text>
        <Text py={4}>
          2. Access to the Site; Eligibility; License. Company provides Users
          with access to and use of the Site and Services subject to their
          compliance with these Terms and our Privacy Policy. Your use of the
          Site constitutes your express agreement to these Terms and our Privacy
          Policy; if you do not agree, you may not access or use the Site.
          Eligibility. Users of the Site must be 18 years of age (or the age of
          majority in your jurisdiction) or older to use the Site. The Site is
          not directed at children under the age of 13 and does not knowingly
          collect information from minor children. By accessing the Site and
          using the Services, you represent that you are over the age of
          majority in your jurisdiction. License from Company. The Site,
          including all of its contents, such as text, images, and the HTML used
          to generate the pages (“Materials”), is our property or that of our
          suppliers or licensors and is protected by patent, trademark and/or
          copyright under United States and/or foreign laws. Except as otherwise
          provided herein, you may not use, download, upload, copy, print,
          display, perform, reproduce, publish, modify, delete, add to, license,
          post, transmit, or distribute any Materials from the Site, in whole or
          in part, for any public or commercial purpose without our specific
          written permission. We grant you a personal, non-exclusive,
          non-transferable license to access the Site and to use the information
          and services contained there. License from Users. Each User of the
          Site grants us a non-exclusive, royalty-free, perpetual, irrevocable,
          and fully sub-licensable right to use, reproduce, modify, adapt,
          publish, translate, create derivative works from, distribute, and
          display any content that they post on the Site throughout the world in
          any media.
        </Text>
        <Text py={4}>
          3. The Role of Company. The Services are provided as a platform only.
          The Site is offered only to provide technology to allow Fundraisers
          and Supporters or other Users to connect. Company’s role is expressly
          limited to making the Site available and maintaining the Site and
          Services for Fundraisers, Supporters and Users. We are a platform and
          not an agent, consultant, professional fundraiser, broker, financial
          institution, charity or fiduciary for any Fundraiser, Supporter or
          User for any purpose. We are not responsible for the sale of items
          offered by Fundraisers and do not control the information provided by
          Fundraisers. We are not responsible for any content posted by any
          Fundraiser, Supporter or other User, including the accuracy of any
          description or the timeliness or reliability of any information or
          content provided by Fundraisers, Supporters or other Users Company is
          not a payment processor, money services business or other financial
          institution. Payments made to a Fundraiser are processed using a
          third-party payment processor (“Payment Processor”). All Users
          acknowledge and agree that the use of Payment Processors is essential
          to the Services and that we exchange information with Payment
          Processors to facilitate the Services provided. Company does not
          engage in any solicitation activities on behalf of any individual,
          entity, organization, or charity. Company does not intend to and does
          not post information to provide financial, legal, tax or other
          professional advice. Before making any payment to any Fundraiser
          through the Services, you should consult your own financial, legal,
          tax or other professional advisor as you deem appropriate. Users
          acknowledge that all information and content they access is at their
          own risk. Company has no control over the conduct of or any
          information provided by any Fundraiser, Supporter or other User and
          expressly disclaims all liability to the fullest extent permitted by
          law, as more specifically described below. We are not responsible for
          and do not endorse any Fundraiser, Supporter or other User and do not
          guarantee or warrant, expressly or impliedly, that any information
          provided through the Services is accurate or how the funds raised by
          any Fundraiser will be used. Company may, but is not obligated to
          provide intermediary services to assist Fundraisers and Supporters in
          resolving disputes between them with the agreement of all parties. In
          the event Company elects, in its sole discretion, to provide such
          services, then our decision is final and binding on all parties and
          cannot be appealed, challenged or reversed.
        </Text>
        <Text py={4}>
          4. Verified Accounts. Fundraisers have the opportunity to provide us
          with enhanced information about their IRS status and their
          organization to earn a Verified Account status once we have verified
          their information. We use our best efforts in this process but we do
          not guarantee or warrant a Fundraiser’s legitimacy or its status when
          it is given a Verified Account status.
        </Text>
        <Text py={4}>
          5. Communication Features. Company may from time to time offer
          Fundraisers the ability to send communications through the Site using
          Company facilitated email or text message features. These features are
          offered free of charge, but may have limitations on the number of
          communications that may be sent during any specific period.
          Fundraisers using such features acknowledge and agree that they, and
          not Company, are solely responsible for complying with all applicable
          laws, including without limitation the Consumer Telephone Protection
          Act and CAN-SPAM and for obtaining the consents required by law before
          sending communications. Company does not participate in the
          communications; it does not determine when a communication is sent, to
          whom it is sent, the content of the communication, and does not
          participate in any way in the Fundraiser’s initiation or sending of
          the communication other than to provide a channel through which the
          communication may be transmitted.
        </Text>
        <Text py={4}>
          6. Fundraiser Responsibility. Fundraisers represent and warrant that:
          All information provided in connection with their use of the Services
          is accurate, complete and not likely to deceive reasonable Supporters
          or other Users; All information posted with respect to items listed on
          events, campaigns, or auctions offered for sale by the Fundraiser on
          the Site is accurate; the Fundraiser owns or is authorized to sell the
          item; the item is genuine and available or subject to limited
          availability (in which case the Fundraiser will disclose such limited
          availability on the Site); and the Fundraiser, and not Company, is
          solely responsible for the delivery or transfer of such items to
          Supporters; Fundraisers are responsible for any lost disputes a
          Supporter files due to any issue related to items listed by the
          Fundraiser; They will promptly correct any errors or omissions in
          posted information; All payments received through the use of the
          Services will be used only as described in the content posted on the
          Site or otherwise provided to each Supporter; Supporter information
          received will be used in accordance with the Fundraiser’s publicly
          posted privacy policy; They will not infringe upon the rights of
          others; They will abide by the Community Guidelines set forth below;
          They will comply with all applicable laws and financial reporting
          obligations and will provide us and any Supporter with all information
          to establish such compliance; Company may use any information provided
          by the Fundraiser and share it with any Supporter, any regulator, law
          enforcement or others in connection with any investigation of the
          Fundraiser; If the Fundraiser provides information claiming any
          tax-exempt status, the cause or activity of the Fundraiser is legal
          under all applicable laws and regulations and the Fundraiser’s
          organization is a tax-exempt organization in good standing with the
          IRS. Fundraisers are required to provide accurate and complete
          information in order to register on the Site and use the Services and
          to keep such information current and accurate. Company can rely on the
          accuracy of the information Fundraisers provide, and we have the
          right, but not the obligation, to verify or validate such information.
          Fundraisers are responsible to determine what, if any, taxes apply to
          payments received by Fundraisers; it is Fundraiser’s sole
          responsibility to assess, collect, report and remit the correct tax,
          if any, to the appropriate taxing authority.
        </Text>
        <Text py={4}>
          7. Supporter Responsibility. All payments made through the Services
          are at the Supporter’s own risk. It is the Supporter’s responsibility
          to investigate the Fundraiser and to understand how your payment will
          be used. Company is not responsible for any offers, promises, or
          promotions made by any Fundraiser. We are not responsible for the
          accuracy of the information that Fundraisers supply and do not
          guarantee that payments Supporters make will be used in accordance
          with promises made by Fundraisers or applicable law. If a Supporter
          believes that a Fundraiser is not raising or using funds for their
          stated purpose, please contact us using the information below. Company
          does not make any representation about the tax deductibility or
          treatment of payments made by Supporters. We do not make any
          representation about the tax status or charitable status of any
          Fundraiser, including those with Verified Accounts. Fundraisers, and
          not Company, are solely responsible for providing Supporters with
          receipts acknowledging receipt of funds from the Supporter.
          Information provided by Supporters through the Services is shared with
          the Fundraiser to whom a payment is made and may be used by such
          Fundraiser in accordance with its own privacy policies. Company is not
          responsible for any Fundraiser’s use of a Supporter’s information.
          Supporters are responsible to determine what, if any, taxes or tax
          exemptions apply to payments made to Fundraisers. If a Supporter
          purchases items from a Fundraiser, the Supporter is responsible for
          payment of all applicable sales and use taxes. Supporters who bid on
          an item or “buy now” are agreeing to pay for the item if their bid is
          accepted as the winning bid. Auction payments are non-refundable and
          the Company does not make any guarantees on the condition or delivery
          of items won by Supporters. Company makes no claims on the
          tax-deductibility of an item listed by a Fundraiser.
        </Text>
        <Text py={4}>
          8. Registration and Accounts. Fundraisers are required to create an
          account in order to use the Site. Fundraisers are required to provide
          certain validation information and to select a user name and password
          for access to their account. Account access credentials should be kept
          safe; any instructions we receive using account credentials will be
          considered to be authorized by the Fundraiser. Fundraisers may log
          into their account to view and edit their profile and transaction
          information. Accounts are subject to these Terms and may be canceled
          or suspended by us in our sole discretion if we believe these Terms
          have been violated. Supporters and other Users are not required to
          create an account in order to use the Site. Information that is
          provided by Supporters and other Users to Fundraisers is collected by
          the Fundraiser as well as by us. We use this information in accordance
          with our Privacy Policy.
        </Text>
        <Text py={4}>
          9. Ownership of the Site. All pages within the Site and any Material
          made available for download are the property of Company, or its
          licensors or suppliers, as applicable. The Site is protected by U.S.
          and international copyright and trademark laws. The contents of the
          Site, including without limitation, all data, files, documents, text,
          photographs, images, audio, and video, and any materials accessed
          through or made available for use or download through the Site
          (“Content”), may not be copied, distributed, modified, reproduced,
          published or used, in whole or in part, except for purposes authorized
          by these Terms or otherwise approved in writing by Company. You may
          not frame or utilize framing techniques to enclose, or deep link to,
          any name, trademarks, service marks, logo, Content or other
          proprietary information (including images, text, page layout, or form)
          of Company without our express written consent.
        </Text>
        <Text py={4}>
          10. User-Generated Content. Users are responsible for any User
          Generated Content posted to the Site. “User-Generated Content” means
          any comments, ideas, suggestions, information, files, videos, images
          or other materials a User provides to us or posts on the Site. We are
          not responsible for the personally identifiable or other information
          you choose to submit as User-Generated Content, and we reserve the
          right to remove any User-Generated Content generated by any User at
          our sole discretion. By posting to the Site, you understand that once
          you post User-Generated Content, it becomes public. We are not
          responsible for keeping any User-Generated Content confidential, so if
          you do not want anyone to read or see your User-Generated Content, do
          not submit or post it to the Site. Users may not: (i) provide
          User-Generated Content that they do not have the right to submit,
          unless they have the owner’s permission; this includes material
          covered by someone else’s copyright, patent, trade secret, or privacy,
          publicity, or other proprietary right; (ii) forge headers or
          manipulate other identifiers in order to disguise the origin of any
          User-Generated Content they provide; (iii) provide any User-Generated
          Content that contains lies, falsehoods or misrepresentations that
          could damage us or anyone else; (iv) provide User-Generated Content
          that is illegal, obscene, defamatory, libelous, threatening,
          pornographic, harassing, hateful, or racially or ethnically offensive,
          or encourages conduct that would be considered a criminal offense,
          give rise to civil liability or violate any law or is otherwise
          inappropriate; (v) impersonate anyone else or lie about your
          affiliation with another person or entity in your User-Generated
          Content; (vi) use meta tags or any other “hidden text” utilizing any
          of our or our suppliers’ product names or trademarks in their
          User-Generated Content; or (vii) provide User-Generated Content that
          disparages us or our vendors, partners, representatives or affiliates.
          Each User represents and warrants that such User owns or otherwise
          controls all of the rights to the content that such User posts; that
          such content is accurate; that use of such content does not violate
          these Terms or any law or regulation; and such content will not cause
          injury to any person or entity. We have the right but not the
          obligation to monitor and edit or remove any activity or content from
          the Site. User-Generated Content comes from a variety of sources. We
          do not endorse or support any views, opinions, recommendations, or
          advice that may be in User-Generated Content, nor do we vouch for its
          accuracy, reliability, usefulness, or safety or the intellectual
          property rights of any User-Generated Content. We take no
          responsibility and assume no liability for any User-Generated Content
          posted by any User or any third party. All Users agree that they will
          abide by the Community Guidelines set forth below.
        </Text>
        <Text py={4}>
          11. Security and Restrictions. The Site may be used only for lawful
          purposes by individuals using authorized Services of Company. You are
          responsible for your own communications, including the upload,
          transmission and posting of information, and the consequences of their
          posting on or through the Site. Company specifically prohibits any use
          of the Site, and requires all Users to agree not to use the Site, for
          any of the following: Providing to us, posting, or publicly sharing
          any information which is incomplete, false, inaccurate or not your
          own; Impersonating another person; Advocating or encouraging conduct
          that would constitute a criminal offense, give rise to civil liability
          or otherwise violate any city, state, national or international law or
          regulation or that fails to comply with accepted Internet protocol;
          Posting material that is copyrighted or otherwise owned by a third
          party unless you are the copyright owner or have the permission of the
          owner to post it; Posting material that reveals trade secrets, unless
          you own them or have the permission of the owner; Posting material
          that infringes on any other intellectual property, privacy or
          publicity right of another; Using the Services in or for the benefit
          of a country, organization, entity, or person embargoed or blocked by
          any government, including those on sanctions lists identified by the
          U.S. Office of Foreign Assets Control (“OFAC”); Transmitting or
          transferring (by any means) information or software derived from the
          Site to foreign countries or nations in violation of U.S. export
          control laws; or Attempting to interfere in any way with the Site’s or
          our networks or network security or attempting to use the Site’s
          service to gain unauthorized access to any other computer system.
          Violations of system or network security may result in civil or
          criminal liability. Company will investigate occurrences and may
          involve, and cooperate with, law enforcement authorities in
          prosecuting the User or Users who are involved in such violations. You
          are prohibited from violating or attempting to violate the security of
          the Site, including, without limitation, the following: Accessing data
          not intended for you or logging into a server or account that you are
          not authorized to access; Using any scraper, crawler, spider, robot or
          other automated means of any kind to access or copy data on the Site,
          deep-link to any feature or content on the Site, bypass our robot
          exclusion headers or other measures we may use to prevent or restrict
          access to the Site; Attempting to probe, scan or test the
          vulnerability of a system or network or to breach security or
          authentication measures without proper authorization; Attempting to
          interfere with service to any User, host or network, including,
          without limitation, via means of submitting a virus to the Site,
          overloading, “flooding”, “mailbombing” or “crashing”; or sending
          unsolicited e-mail, including promotions and/or advertising of
          products or services, or Forging any TCP/IP packet header or any part
          of the header information in any e-mail or newsgroup posting.
        </Text>
        <Text py={4}>
          12. Passwords. In the event access to the Site or a portion thereof is
          limited requiring a user name and password (“Protected Areas”), you
          agree to access Protected Areas using only your own user name and
          password. You agree to protect the confidentiality of your user name
          and password, and not to share or disclose your user name or password
          to any third party. You agree that you are fully responsible for all
          activity occurring under your user name. Your access to the Site may
          be revoked by Company at any time with or without cause.
        </Text>
        <Text py={4}>
          13. Copyright Complaints. We respect the intellectual property rights
          of others and prohibit Users from uploading and posting materials that
          infringe another party’s intellectual property rights. If you believe
          that your material has been copied in a way that constitutes copyright
          infringement, please provide our copyright agent the following written
          information: (i) an electronic or physical signature of the person
          authorized to act on behalf of the owner of the copyright interest;
          (ii) a description of the copyrighted work that you claim has been
          infringed upon; (iii) a description of where the material that you
          claim is infringing is located on the Site; (iv) your address,
          telephone number, and e-mail address; (v) a statement by you that you
          have a good-faith belief that the disputed use is not authorized by
          the copyright owner, its agent, or the law; and (vi) a statement by
          you, made under penalty of perjury, that the above information in your
          notice is accurate and that you are the copyright owner or authorized
          to act on the copyright owner’s behalf.‍ It is often difficult to determine if
          your intellectual property rights have been violated or if the Digital
          Millennium Copyright Act (DMCA) requirements have been met. We may
          request additional information before we remove any infringing
          material. If a dispute develops as to the correct owner of the rights
          in question, we reserve the right to remove or disable access to the
          allegedly infringing material pending resolution of the matter. We
          will terminate the accounts of Users that we determine are repeat
          infringers. Please note that, under Section 512(f) of the Copyright
          Act any person who makes false claims that material or an activity is
          infringing may be subject to liability for damages.
        </Text>
        <Text py={4}>
          14. Our Right to Refuse, Terminate or Suspend Use. Company expressly
          reserves the right to terminate the use of, or to refuse to permit the
          use of, the Site or the Services by any person or entity, at the sole
          discretion of Company, for any reason or no reason at all, and without
          prior notice. In the event of termination, any rights or obligations
          regarding pending or completed purchases, or your indemnity
          obligations related to use of the Site or the Services, shall survive
          such termination. We reserve the right, but accept no obligation, to
          monitor any activity and content on the Site. We may investigate any
          reported violations of law, rule or regulation applicable to
          Fundraisers, Supporters or other Users, or transactions on the Site or
          using the Services, and take action that we deem appropriate,
          including, but not limited to, issuing warnings, suspending or
          terminating service, or denying access to or removing any content from
          the Site. We may also investigate the use of a credit card by a User
          and take such action as we deem appropriate, including, but not
          limited to, canceling any transaction by such User.
        </Text>
        <Text py={4}>
          15. Accuracy and Integrity of Information; Errors. Although Company
          attempts to ensure the integrity and accuracy of the Site, it makes no
          representations, warranties or guarantees whatsoever as to the
          correctness, accuracy, or accessibility of the Site and Content
          thereon. It is possible that the Site could include typographical
          errors, inaccuracies or other errors and that unauthorized additions,
          deletions and alterations could be made to the Site by third parties.
          All information and content regarding Fundraisers are provided solely
          by the Fundraiser, who is expressly responsible for the accuracy of
          the content it provides and posts to the Site. Company shall have no
          responsibility or liability for information or content posted to the
          Site from any non-Company affiliated third party. In the event a
          product or service is listed at an incorrect price due to
          typographical error or error in pricing information provided by a
          Fundraiser, the Fundraiser shall have the right to refuse or cancel
          any orders placed for such product / service listed at the incorrect
          price. In the event that an inaccuracy arises, please inform us so
          that it can be corrected. We reserve the right to unilaterally correct
          any inaccuracies on the Site without notice. Information contained on
          the Site may be changed or updated without notice.
        </Text>
        <Text py={4}>
          16. Refunds. All transactions and payments made on the Site are
          between the Fundraiser and Supporter and not between a Supporter and
          Company. Fundraisers are solely responsible for determining when and
          whether to issue refunds at their sole discretion. Company is not
          responsible for donations and/or any other form of payment made on the
          Site.
        </Text>
        <Text py={4}>
          17. Payments to Fundraisers. Payment Processor provides all financial
          services for payments made to Fundraisers through the Site. Payment
          Processor accepts credit and debit cards issued by U.S. banks and ACH
          payments. If a credit card account is being used for a transaction,
          Payment Processor may obtain pre-approval for an amount up to the
          amount of the payment. If you enroll to make recurring payments
          automatically, all charges and fees will be billed to the credit card
          you designate during the setup process. If you want to designate a
          different credit card or if there is a change in your credit card, you
          must change your information online. This may temporarily delay your
          ability to make payments to Fundraisers through the Site while we
          verify your new payment information. Payment Processor will use
          information received in connection with payments to Fundraisers made
          through the Site in accordance with its privacy policy available at
          https://stripe.com/privacy. You represent and warrant that if you are
          making payments to Fundraisers through the Site (i) any credit card,
          debit card, or bank account information you supply is true, correct
          and complete, (ii) charges incurred by you will be honored by your
          credit/debit card company or bank, (iii) you will pay the charges
          incurred by you in the amounts posted, including any applicable taxes,
          and (iv) you are the person in whose name the card was issued and you
          are authorized to make a purchase or other transaction with the
          relevant card and card information. In connection with payments to
          Fundraisers made through the Site, you shall: Review and clearly
          accept the agreement to be entered into with Payment Processor (the
          “Payment Processor Agreement”), including without limitation all
          updated versions of the Payment Processor Agreement, which agreement
          makes clear that Payment Processor shall make such payments in
          accordance with such agreement; Not use the Services in violation of
          the Payment Processor Agreement or for any activity expressly
          prohibited by Payment Processor; Comply with the Payment Processor
          Agreement and all guidelines and requirements established by Payment
          Processor or its affiliates or banks; Shall not take or fail to take
          any action that may cause Payment Processor or its affiliates or banks
          to violate any applicable law; Shall not access, use or disclose any
          information except to the extent necessary for you to access and use
          the services provided by Payment Processor; Only make electronic
          payments and funds transfers to and from a prepaid account maintained
          with Payment Processor and represented by a virtual bank account
          number (if applicable) for business purposes; Provide all information
          necessary to enable Payment Processor to determine your eligibility to
          receive services and make services available to you and ensure that
          such information will at all times be complete and accurate; and
          Authorize us to receive on your behalf any notices or forms provided
          by Payment Processor.
        </Text>
        <Text py={4}>
          18. Export Policy and Restrictions. You acknowledge that the products
          and Content that are sold or licensed on the Site, which may include
          technology and software, are subject to U.S. customs and export
          control laws and regulations and may also be subject to the customs
          and export laws and regulations of the country where the products are
          manufactured and/or received. By purchasing, downloading or using
          technology or software from the Site, you agree to abide by the
          applicable laws, rules and regulations - including, but not limited
          to, the Export Administration Act and the Arms Export Control Act -
          and you represent and warrant that you will not transfer, by
          electronic transmission or otherwise, the software or technology to a
          foreign national or a foreign destination in violation of the law. You
          agree that you will not use or provide products to any person who is
          forbidden from receiving the product under the Export Administration
          Regulations or any economic sanctions maintained by the U.S.
          Department of Treasury, U.S. anti-boycott regulations, or U.S.
          economic sanctions, including the export and anti-boycott restrictions
          found in the Export Administration Regulations or the sanctions
          regulations administered by OFAC. You shall indemnify and hold
          harmless Company from all claims, demands, damages, costs, fines,
          penalties, attorneys’ fees and other expenses arising from your
          failure to comply with this provision and/or applicable export
          control, antiboycott, or economic sanctions laws and regulations.
        </Text>
        <Text py={4}>
          19. Links to and from Other Sites. You may be able to link to
          third-party websites (“Linked Sites”) from the Site. Linked Sites are
          not, however, reviewed, controlled or examined by Company in any way,
          and we are not responsible for the content, availability, advertising,
          products, information or use of user information or other materials of
          any such Linked Sites or any additional links contained therein. These
          links do not imply our endorsement of or association with the Linked
          Sites. It is your sole responsibility to comply with the appropriate
          terms of service of the Linked Sites as well as with any other
          obligation under copyright, secrecy, defamation, decency, privacy,
          security and export laws related to the use of such Linked Sites and
          any content contained thereon. In no event shall we be liable,
          directly or indirectly, to anyone for any loss or damage arising from
          or occasioned by the creation or use of the Linked Sites or the
          information or material accessed through any Linked Sites. You should
          direct any concerns to the applicable Linked Site’s administrator or
          Webmaster. We reserve the exclusive right, at our sole discretion, to
          add, change, decline or remove, without notice, any feature or link to
          any of the Linked Sites from the Site and/or introduce different
          features or links to different Users. Permission must be granted by us
          for any type of link to the Site. To seek our permission, you may
          create and maintain a registered account in good standing or write to
          us using the information below. We reserve the right, however, to deny
          any request or rescind any permission granted by us to link through
          such other type of link and to require termination of any such link to
          the Site, at our discretion at any time. 20. Indemnity. Each User
          agrees to defend, indemnify and hold harmless Company, its directors,
          officers, employees, agents and affiliates harmless from any and all
          claims, liabilities, damages, costs and expenses, including reasonable
          attorneys’ fees, in any way arising from, related to or in connection
          with (a) your use of or access to the Site or the Services, including
          any act or omission related to services provided by Payment Processor,
          (b) your violation of these Terms, the Payment Processor Agreement, or
          applicable law; (c) the posting or transmission of any materials,
          content or User-Generated Content on the Site or through the Services
          by you or allowed by you, including, but not limited to, any
          third-party claim that any information or materials you provide
          infringes any third-party proprietary right; (d) access to the Site by
          anyone using your user name and password; or (e) your providing
          inaccurate or incomplete information to us or Payment Processor.
        </Text>
        <Text py={4}>
          20. Disclaimer of Warranties. YOU ASSUME TOTAL RESPONSIBILITY AND RISK
          FOR YOUR USE OF THE SITE, SITE-RELATED SERVICES, AND LINKED WEBSITES.
          YOU UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW: THE SITE, INCLUDING, WITHOUT LIMITATION, ALL CONTENT,
          FUNCTION, MATERIALS AND SERVICES, IS PROVIDED “AS IS,” WITHOUT
          WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
          LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING
          SERVICES OR UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE
          AVAILABILITY, ACCURACY, COMPLETENESS, USEFULNESS, OR CONTENT OF
          INFORMATION, AND ANY WARRANTIES OF TITLE, NON-INFRINGEMENT,
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT
          WARRANT THAT THE SITE OR THE FUNCTION, CONTENT OR SERVICES MADE
          AVAILABLE THEREBY WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE
          OR THAT DEFECTS WILL BE CORRECTED. COMPANY MAKES NO WARRANTY THAT THE
          SITE WILL MEET USERS’ EXPECTATIONS OR REQUIREMENTS. NO ADVICE,
          RESULTS, INFORMATION, OR MATERIALS WHETHER ORAL OR WRITTEN, OBTAINED
          BY YOU THROUGH THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE
          HEREIN. IF YOU ARE DISSATISFIED WITH THE SITE, YOUR SOLE REMEDY IS TO
          DISCONTINUE USING THE SITE. ANY MATERIAL DOWNLOADED OR OTHERWISE
          OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION
          AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT
          RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. COMPANY DOES NOT
          ENDORSE, WARRANT OR GUARANTEE ANY PRODUCTS OR SERVICES OFFERED OR
          PROVIDED BY OR ON BEHALF OF THIRD PARTIES ON OR THROUGH THE SITE.
          COMPANY IS NOT A PARTY TO, AND DOES NOT MONITOR, ANY TRANSACTION
          BETWEEN USERS AND THIRD PARTIES WITHOUT THE DIRECT INVOLVEMENT OF
          COMPANY.
        </Text>
        <Text py={4}>
          21. Limitation of Liability Regarding Use of Site. TO THE FULLEST
          EXTENT PERMITTED BY APPLICABLE LAW, NEITHER COMPANY, ITS AFFILIATES OR
          ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR
          CONTENT OR SERVICE PROVIDERS SHALL BE LIABLE FOR ANY INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES
          ARISING FROM OR DIRECTLY OR INDIRECTLY RELATED TO THE USE/MISUSE OF,
          OR THE INABILITY TO USE, THE SITE OR THE CONTENT, MATERIALS AND
          FUNCTION RELATED THERETO, INCLUDING, WITHOUT LIMITATION, LOSS OF
          REVENUE, OR ANTICIPATED PROFITS, LOST BUSINESS, DATA OR SALES, OR COST
          OF SUBSTITUTE SERVICES, EVEN IF COMPANY OR ITS REPRESENTATIVE OR SUCH
          INDIVIDUAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
          JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY SO
          SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL
          THE TOTAL LIABILITY OF COMPANY TO YOU FOR ALL DAMAGES, LOSSES, AND
          CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT
          LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THESE TERMS OR YOUR
          USE OF THE SITE EXCEED, IN THE AGGREGATE, $100.00.
        </Text>
        <Text py={4}>
          22. International Use. We control and operate the Site from our
          offices in the U.S., and all information is processed within the U.S.
          We do not represent that materials on the Site are appropriate or
          available for use in other locations. Persons who choose to access the
          Site from other locations do so on their own initiative and are
          responsible for compliance with local laws if and to the extent local
          laws are applicable. You agree to comply with all applicable laws,
          rules and regulations in connection with your use of the Site. Without
          limiting the generality of the foregoing, you agree to comply with all
          applicable laws regarding the transmission of technical data exported
          from the U.S. or the country in which you reside. 26. Modification of
          Terms. In its sole discretion, Company may from time-to-time revise
          these Terms by updating this posting. You should, therefore,
          periodically visit this page to review the current Terms so you are
          aware of any such revisions to which you are bound. Your continued use
          of the Site after revisions to these Terms shall constitute your
          agreement to the revised Terms.
        </Text>
        <Text py={4}>
          23. General. Company reserves the right, in its sole discretion, to
          terminate your access to all or part of the Site, with or without
          cause and with or without notice. In the event that any of these Terms
          are held by a court or other tribunal of competent jurisdiction to be
          unenforceable, such provisions shall be eliminated or limited to the
          minimum extent necessary so that these Terms shall otherwise remain in
          full force and effect. These Terms constitute the entire agreement
          between Company and you pertaining to the subject matter hereof. You
          also may be subject to additional terms and conditions that are
          applicable to certain parts of the Site. Certain provisions of these
          Terms may be superseded by expressly designated legal notices or terms
          located on particular pages within the Site. You agree that no joint
          venture, partnership, employment, or agency relationship exists
          between Company and you as a result of these Terms or your use of the
          Site or Services. Any claim or cause of action you may have with
          respect to Company, the Site or the Services must be commenced within
          one (1) year after the claim or cause of action arose. The failure of
          Company to exercise or enforce any right or provision of these Terms
          shall not constitute a waiver of such right or provision. You may not
          assign these Terms or any of your rights or obligations under these
          Terms without our express written consent. These Terms inure to the
          benefit of Company’s successors, assigns and licensees. The section
          titles in these Terms are for convenience only and have no legal or
          contractual effect. 28. Contacting Us. To contact us with any
          questions or concerns in connection with these Terms, the Services or
          the Site, or to provide any notice under these Terms to us please go
          to Contact Us or write to us at: Email: info@fundery.co COMMUNITY
          GUIDELINES You are solely responsible for compliance with all
          applicable law in relation to your use of the Services. You are
          further solely responsible for all User-Generated Content that you
          upload, post, publish, display, transmit or otherwise use
          (hereinafter, “Upload”). If you are not the beneficiary of the
          campaign you organize, you agree to deliver funds to the ultimate
          beneficiary directly and as soon as possible. You agree to fully
          cooperate with any request we make for evidence we deem necessary to
          verify your compliance with these Terms. The following are examples of
          User-Generated Content and/or use that is illegal or prohibited by
          Company. This list is not exhaustive, and we reserve the right to
          remove any campaign and/or investigate any User who, in our sole
          discretion, violates any of the terms or spirit of these Terms. As we
          investigate your campaign, User, or User-Generated Content, we may
          consider all available material, including, but not limited to, social
          media, related news, and any other information that we, in our sole
          discretion, deem relevant in our review. We further reserve the right,
          without limitation, to ban or disable your use of the Services, remove
          the offending User-Generated Content, suspend or terminate your
          account, stop payments to any such campaign, freeze or place a hold on
          donations, and report you to law enforcement authorities or otherwise
          take appropriate legal action including seeking restitution on behalf
          of ourselves and/or our Users. Without limiting the foregoing, you
          agree not to use the Services to raise funds or establish or
          contribute to any campaign with the implicit or explicit purpose of or
          involving: Breaking the law: the violation of any law or regulation;
          Fraud: information that is fraudulent, misleading, inaccurate,
          dishonest, or impossible, particularly if done knowingly with the
          intent to deceive; Illegal drugs: narcotics, steroids, controlled
          substances, abuse of pharmaceuticals or similar products or therapies
          that are either illegal or prohibited; Violence: knives, explosives,
          ammunition, firearms, or other weaponry or accessories, as well as the
          promotion of violent ideas such as genocide, execution, and other
          violent acts; Financial schemes: annuities, investments, loans, equity
          or lottery contracts, lay-away systems, off-shore banking or similar
          transactions, money service businesses (including currency exchanges,
          check cashing or the like), pyramid schemes, “get rich quick schemes”
          (i.e., investment opportunities or other services that promise high
          rewards), network marketing and referral marketing programs, debt
          collection or crypto-currencies; Gambling: casino games, sports
          betting, fantasy sports, horse or greyhound racing, lottery tickets,
          and other ventures that facilitate gambling, games of skill or chance
          (whether or not it is legally defined as a lottery), promotions
          involving monetary rewards, including gift cards, or sweepstakes;
          Hate, discrimination, and harassment: content that we deem, in our
          sole discretion, to be in support of hate, violence, harassment,
          bullying, discrimination, terrorism, or intolerance of any kind
          relating to race, ethnicity, national origin, religious affiliation,
          sexual orientation, sex, gender or gender identity, or mental,
          physical, or other medical conditions; Legal defense for serious
          crimes: campaigns that we deem, in our sole discretion, to be for the
          legal defense of alleged crimes associated with hate, violence,
          harassment, bullying, discrimination, terrorism, or intolerance of any
          kind relating to race, ethnicity, national origin, religious
          affiliation, sexual orientation, sex, gender or gender identity,
          mental, physical, or other medical conditions, or financial crimes or
          crimes of deception; International sanctions: activities with, in, or
          involving countries, regions, governments, persons, or entities that
          are subject to U.S. and other economic sanctions under applicable law,
          unless such activities are expressly authorized by the appropriate
          governmental authority; Exploitation: funding a ransom, human
          trafficking or exploitation, vigilantism, bribes or bounty;
          Pornography: pornographic content or other sexual content relating to
          minors or persons appearing to possibly be minors; Graphic: offensive,
          graphic, perverse or sensitive content; Pre-sales: the sale of items
          before the seller has control or possession of the item; Payment
          manipulation: collecting payments on behalf of merchants by payment
          processors or otherwise; including, but not limited to, self-payments
          on campaigns or an attempt to bypass or otherwise circumvent the
          designated method of payment as provided by Company; Credit: credit
          repair or debt settlement services; Misuse of funds: the receipt or
          grant of cash advances or lines of credit to yourself or to another
          person for purposes other than those purposes clearly stated in the
          campaign or collecting or providing funds for any purpose other than
          as described in a campaign description; Slander and personal attacks:
          publication or removal of User-Generated Content (such as mug shots),
          where the primary purpose of posting such User-Generated Content is to
          cause or raise concerns of reputational harm, as well as personal
          attacks, slurs, caustic or combative content submitted with the aim of
          harming other people in some way; No-value-added services: sale or
          resale of a service without added benefit to the buyer or resale of
          government offerings without authorization or added value; Money
          laundering: aggregation of funds owed to third parties, factoring, or
          other activities intended to obfuscate the origin of funds; Piracy:
          counterfeit music, movies, software, or other licensed materials
          without the appropriate authorization from the rights holder;
          Intellectual property violations: products or services that directly
          infringe or facilitate infringement upon the trademark, patent,
          copyright, trade secrets, or proprietary or privacy rights of any
          third party; Unauthorized sale or resale: of brand name or designer
          products or services; Trade violations: sale of goods or services that
          are illegally imported or exported; Credit card fraud: processing
          where there is no bona fide donation accepted; cash advance; card
          testing; or evasion of card network chargeback monitoring programs;
          Financial crimes: any other activity that Company may deem in its sole
          discretion to be in support of individuals and/or entities associated
          with alleged financial crimes, including, but not limited to,
          corruption, bribery, tax evasion, fraud, and activities of a similar
          nature; or Generally unacceptable activity: any other activity that
          Company may deem in its sole discretion to be unacceptable.
        </Text>
      </Box>
    </Container>
  );
};

export default Terms;
