import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import ReassignDonorTicketForm from "./ReassignDonorTicketForm";

const ReassignDonorTicketModal = ({ donorTicket }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen}>Re-assign Ticket</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Re-assign Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReassignDonorTicketForm
              onComplete={onClose}
              donorTicket={donorTicket}
            />
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReassignDonorTicketModal;
