import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Upload: any;
};

export type ActiveStorageAttachment = {
  __typename?: 'ActiveStorageAttachment';
  filename: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type AddDonorTicketDetailsAttributes = {
  comments?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of AddDonorTicketDetails */
export type AddDonorTicketDetailsInput = {
  attributes: AddDonorTicketDetailsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonorTickets::AddDonorTicketDetails possible responses */
export type AddDonorTicketDetailsResult = AddDonorTicketDetailsSuccess | Error;

export type AddDonorTicketDetailsSuccess = {
  __typename?: 'AddDonorTicketDetailsSuccess';
  donorTicket: DonorTicket;
};

export type AddIntegrationToTeamAttributes = {
  apiFieldThree?: InputMaybe<Scalars['String']>;
  apiFieldTwo?: InputMaybe<Scalars['String']>;
  apiKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  integrationId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of AddIntegrationToTeam */
export type AddIntegrationToTeamInput = {
  attributes: AddIntegrationToTeamAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::AddIntegrationToTeam possible responses */
export type AddIntegrationToTeamResult = AddIntegrationToTeamSuccess | Error;

export type AddIntegrationToTeamSuccess = {
  __typename?: 'AddIntegrationToTeamSuccess';
  team: Team;
};

export type AddTicketsToDonationAttributes = {
  donationId?: InputMaybe<Scalars['ID']>;
  donorTicket?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of AddTicketsToDonation */
export type AddTicketsToDonationInput = {
  attributes: AddTicketsToDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::AddTicketsToDonation possible responses */
export type AddTicketsToDonationResult = AddTicketsToDonationSuccess | Error;

export type AddTicketsToDonationSuccess = {
  __typename?: 'AddTicketsToDonationSuccess';
  donation: Donation;
};

export type AssignDonationReceiptAttributes = {
  donationReceiptId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of AssignDonationReceipt */
export type AssignDonationReceiptInput = {
  attributes: AssignDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Campaigns::AssignDonationReceipt possible responses */
export type AssignDonationReceiptResult = AssignDonationReceiptSuccess | Error;

export type AssignDonationReceiptSuccess = {
  __typename?: 'AssignDonationReceiptSuccess';
  campaign: Campaign;
};

export type Campaign = {
  __typename?: 'Campaign';
  active: Scalars['Boolean'];
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  canDestroy: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  donationReceipt?: Maybe<DonationReceipt>;
  emails?: Maybe<Array<Email>>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  goalAmount?: Maybe<Scalars['String']>;
  goalCompletionPercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  lastDonation?: Maybe<Donation>;
  locationName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publicName?: Maybe<Scalars['String']>;
  sendDonationReceipt: Scalars['Boolean'];
  sendTickets?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<Scalars['String']>;
  tickets?: Maybe<Array<Ticket>>;
  timeInfo?: Maybe<Scalars['String']>;
  totalDonationCount?: Maybe<Scalars['String']>;
  totalRaised?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for Campaign. */
export type CampaignConnection = {
  __typename?: 'CampaignConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CampaignEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Campaign>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CampaignEdge = {
  __typename?: 'CampaignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Campaign>;
};

export type CancelSubscriptionAttributes = {
  token?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  attributes: CancelSubscriptionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CheckInAttributes = {
  checkedIn?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of CheckIn */
export type CheckInInput = {
  attributes: CheckInAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonorTickets::CheckIn possible responses */
export type CheckInResult = CheckInSuccess | Error;

export type CheckInSuccess = {
  __typename?: 'CheckInSuccess';
  donorTicket: DonorTicket;
};

export type Comment = {
  __typename?: 'Comment';
  comment: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  user?: Maybe<User>;
};

/** The connection type for Comment. */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Comment>;
};

export type ConfirmDonationAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of ConfirmDonation */
export type ConfirmDonationInput = {
  attributes: ConfirmDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::ConfirmDonation possible responses */
export type ConfirmDonationResult = ConfirmDonationSuccess | Error;

export type ConfirmDonationSuccess = {
  __typename?: 'ConfirmDonationSuccess';
  donation: Donation;
};

export type CreateCampaignAttributes = {
  active?: InputMaybe<Scalars['Boolean']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  goalAmount?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publicName?: InputMaybe<Scalars['String']>;
  sendDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  sendTickets?: InputMaybe<Scalars['Boolean']>;
  startsAt?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  timeInfo?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCampaign */
export type CreateCampaignInput = {
  attributes: CreateCampaignAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Campaigns::CreateCampaign possible responses */
export type CreateCampaignResult = CreateCampaignSuccess | Error;

export type CreateCampaignSuccess = {
  __typename?: 'CreateCampaignSuccess';
  campaign: Campaign;
};

export type CreateCommentAttributes = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateComment */
export type CreateCommentInput = {
  attributes: CreateCommentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Comments::CreateComment possible responses */
export type CreateCommentResult = CreateCommentSuccess | Error;

export type CreateCommentSuccess = {
  __typename?: 'CreateCommentSuccess';
  comment: Comment;
};

export type CreateDonationAttributes = {
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Scalars['String']>;
  sendReceipt?: InputMaybe<Scalars['Boolean']>;
  sentAt?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDonation */
export type CreateDonationInput = {
  attributes: CreateDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CreateDonationReceiptAttributes = {
  mainContent?: InputMaybe<Scalars['String']>;
  signatureName?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDonationReceipt */
export type CreateDonationReceiptInput = {
  attributes: CreateDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonationReceipts::CreateDonationReceipt possible responses */
export type CreateDonationReceiptResult = CreateDonationReceiptSuccess | Error;

export type CreateDonationReceiptSuccess = {
  __typename?: 'CreateDonationReceiptSuccess';
  donationReceipt: DonationReceipt;
};

/** Mutations::Donations::CreateDonation possible responses */
export type CreateDonationResult = CreateDonationSuccess | Error;

export type CreateDonationSuccess = {
  __typename?: 'CreateDonationSuccess';
  donation: Donation;
};

export type CreateDonorAttributes = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDonor */
export type CreateDonorInput = {
  attributes: CreateDonorAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donors::CreateDonor possible responses */
export type CreateDonorResult = CreateDonorSuccess | Error;

export type CreateDonorSuccess = {
  __typename?: 'CreateDonorSuccess';
  donor: Donor;
};

export type CreateDonorTicketAttributes = {
  donationId?: InputMaybe<Scalars['ID']>;
  ticketId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateDonorTicket */
export type CreateDonorTicketInput = {
  attributes: CreateDonorTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonorTickets::CreateDonorTicket possible responses */
export type CreateDonorTicketResult = CreateDonorTicketSuccess | Error;

export type CreateDonorTicketSuccess = {
  __typename?: 'CreateDonorTicketSuccess';
  donorTicket: DonorTicket;
};

export type CreateEmailAttributes = {
  campaignId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateEmail */
export type CreateEmailInput = {
  attributes: CreateEmailAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Emails::CreateEmail possible responses */
export type CreateEmailResult = CreateEmailSuccess | Error;

export type CreateEmailSuccess = {
  __typename?: 'CreateEmailSuccess';
  email: Email;
};

export type CreateEventAttributes = {
  body?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  header?: InputMaybe<Scalars['String']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
  sidebar?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of CreateEvent */
export type CreateEventInput = {
  attributes: CreateEventAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Events::CreateEvent possible responses */
export type CreateEventResult = CreateEventSuccess | Error;

export type CreateEventSuccess = {
  __typename?: 'CreateEventSuccess';
  fundraiserContent: FundraiserContent;
};

export type CreateFundraiserAttributes = {
  allowPublicComments?: InputMaybe<Scalars['Boolean']>;
  body?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  header?: InputMaybe<Scalars['String']>;
  includeHonoree?: InputMaybe<Scalars['Boolean']>;
  includeRecipient?: InputMaybe<Scalars['Boolean']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
  sidebar?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of CreateFundraiser */
export type CreateFundraiserInput = {
  attributes: CreateFundraiserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Fundraisers::CreateFundraiser possible responses */
export type CreateFundraiserResult = CreateFundraiserSuccess | Error;

export type CreateFundraiserSuccess = {
  __typename?: 'CreateFundraiserSuccess';
  fundraiserContent: FundraiserContent;
};

export type CreatePaymentIntentAttributes = {
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  coverFees?: InputMaybe<Scalars['Boolean']>;
  employerName?: InputMaybe<Scalars['String']>;
  honoreeKind?: InputMaybe<Scalars['String']>;
  honoreeName?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  recipientMessage?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePaymentIntent */
export type CreatePaymentIntentInput = {
  attributes: CreatePaymentIntentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::CreatePaymentIntent possible responses */
export type CreatePaymentIntentResult = CreatePaymentIntentSuccess | Error;

export type CreatePaymentIntentSuccess = {
  __typename?: 'CreatePaymentIntentSuccess';
  paymentIntent: PaymentIntent;
};

export type CreatePledgeAttributes = {
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePledge */
export type CreatePledgeInput = {
  attributes: CreatePledgeAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::CreatePledge possible responses */
export type CreatePledgeResult = CreatePledgeSuccess | Error;

export type CreatePledgeSuccess = {
  __typename?: 'CreatePledgeSuccess';
  donation: Donation;
};

export type CreatePublicCommentAttributes = {
  displayAmount?: InputMaybe<Scalars['Boolean']>;
  displayDonorInfo?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  stripeId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePublicComment */
export type CreatePublicCommentInput = {
  attributes: CreatePublicCommentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::CreatePublicComment possible responses */
export type CreatePublicCommentResult = CreatePublicCommentSuccess | Error;

export type CreatePublicCommentSuccess = {
  __typename?: 'CreatePublicCommentSuccess';
  publicComment: PublicComment;
};

export type CreateSessionAttributes = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateSession */
export type CreateSessionInput = {
  attributes: CreateSessionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Users::CreateSession possible responses */
export type CreateSessionResult = CreateSessionSuccess | Error;

export type CreateSessionSuccess = {
  __typename?: 'CreateSessionSuccess';
  token: Scalars['String'];
  user: User;
};

/** Autogenerated input type of CreateSubscriptionBillingPortal */
export type CreateSubscriptionBillingPortalInput = {
  attributes: CreateSubscrptionBillingPortalAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CreateSubscriptionIntentAttributes = {
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  coverFees?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  honoreeKind?: InputMaybe<Scalars['String']>;
  honoreeName?: InputMaybe<Scalars['String']>;
  interval?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  recipientMessage?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateSubscriptionIntent */
export type CreateSubscriptionIntentInput = {
  attributes: CreateSubscriptionIntentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::CreateSubscriptionIntent possible responses */
export type CreateSubscriptionIntentResult = CreateSubscriptionIntentSuccess | Error;

export type CreateSubscriptionIntentSuccess = {
  __typename?: 'CreateSubscriptionIntentSuccess';
  paymentIntent: PaymentIntent;
};

export type CreateSubscrptionBillingPortalAttributes = {
  customerId?: InputMaybe<Scalars['String']>;
};

export type CreateTeamAttributes = {
  name?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTeam */
export type CreateTeamInput = {
  attributes: CreateTeamAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::CreateTeam possible responses */
export type CreateTeamResult = CreateTeamSuccess | Error;

export type CreateTeamSuccess = {
  __typename?: 'CreateTeamSuccess';
  team: Team;
};

export type CreateTicketAttributes = {
  attendeesPerTicket?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  fairMarketValueAmount?: InputMaybe<Scalars['String']>;
  maximumPerOrder?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTicket */
export type CreateTicketInput = {
  attributes: CreateTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CreateTicketOrderDonationAttributes = {
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  donorTicket?: InputMaybe<Array<Scalars['JSON']>>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  sendTaxPdf?: InputMaybe<Scalars['Boolean']>;
  sentAt?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTicketOrderDonation */
export type CreateTicketOrderDonationInput = {
  attributes: CreateTicketOrderDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::CreateTicketOrderDonation possible responses */
export type CreateTicketOrderDonationResult = CreateTicketOrderDonationSuccess | Error;

export type CreateTicketOrderDonationSuccess = {
  __typename?: 'CreateTicketOrderDonationSuccess';
  donation: Donation;
};

export type CreateTicketPaymentIntentAttributes = {
  campaignId?: InputMaybe<Scalars['ID']>;
  coverFees?: InputMaybe<Scalars['Boolean']>;
  tickets?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of CreateTicketPaymentIntent */
export type CreateTicketPaymentIntentInput = {
  attributes: CreateTicketPaymentIntentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Events::CreateTicketPaymentIntent possible responses */
export type CreateTicketPaymentIntentResult = CreateTicketPaymentIntentSuccess | Error;

export type CreateTicketPaymentIntentSuccess = {
  __typename?: 'CreateTicketPaymentIntentSuccess';
  paymentIntent: PaymentIntent;
};

/** Mutations::Tickets::CreateTicket possible responses */
export type CreateTicketResult = CreateTicketSuccess | Error;

export type CreateTicketSuccess = {
  __typename?: 'CreateTicketSuccess';
  ticket: Ticket;
};

export type CreateUserAttributes = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  teamInvitationToken?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  attributes: CreateUserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Users::CreateUser possible responses */
export type CreateUserResult = CreateUserSuccess | Error;

export type CreateUserSuccess = {
  __typename?: 'CreateUserSuccess';
  token: Scalars['String'];
  user: User;
};

export type DeleteCampaignAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteCampaign */
export type DeleteCampaignInput = {
  attributes: DeleteCampaignAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Campaigns::DeleteCampaign possible responses */
export type DeleteCampaignResult = DeleteCampaignSuccess | Error;

export type DeleteCampaignSuccess = {
  __typename?: 'DeleteCampaignSuccess';
  campaign: Campaign;
};

export type DeleteDonationAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteDonation */
export type DeleteDonationInput = {
  attributes: DeleteDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteDonationReceiptAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteDonationReceipt */
export type DeleteDonationReceiptInput = {
  attributes: DeleteDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonationReceipts::DeleteDonationReceipt possible responses */
export type DeleteDonationReceiptResult = DeleteDonationReceiptSuccess | Error;

export type DeleteDonationReceiptSignatureAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteDonationReceiptSignature */
export type DeleteDonationReceiptSignatureInput = {
  attributes: DeleteDonationReceiptSignatureAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonationReceipts::DeleteDonationReceiptSignature possible responses */
export type DeleteDonationReceiptSignatureResult = DeleteDonationReceiptSignatureSuccess | Error;

export type DeleteDonationReceiptSignatureSuccess = {
  __typename?: 'DeleteDonationReceiptSignatureSuccess';
  donationReceipt: DonationReceipt;
};

export type DeleteDonationReceiptSuccess = {
  __typename?: 'DeleteDonationReceiptSuccess';
  donationReceipt: DonationReceipt;
};

/** Mutations::Donations::DeleteDonation possible responses */
export type DeleteDonationResult = DeleteDonationSuccess | Error;

export type DeleteDonationSuccess = {
  __typename?: 'DeleteDonationSuccess';
  donation: Donation;
};

export type DeleteDonorAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteDonor */
export type DeleteDonorInput = {
  attributes: DeleteDonorAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donors::DeleteDonor possible responses */
export type DeleteDonorResult = DeleteDonorSuccess | Error;

export type DeleteDonorSuccess = {
  __typename?: 'DeleteDonorSuccess';
  donor: Donor;
};

export type DeleteDonorTicketAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteDonorTicket */
export type DeleteDonorTicketInput = {
  attributes: DeleteDonorTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonorTickets::DeleteDonorTicket possible responses */
export type DeleteDonorTicketResult = DeleteDonorTicketSuccess | Error;

export type DeleteDonorTicketSuccess = {
  __typename?: 'DeleteDonorTicketSuccess';
  donorTicket: DonorTicket;
};

export type DeleteFundraiserContentImageAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteFundraiserContentImage */
export type DeleteFundraiserContentImageInput = {
  attributes: DeleteFundraiserContentImageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Fundraisers::DeleteFundraiserContentImage possible responses */
export type DeleteFundraiserContentImageResult = DeleteFundraiserContentImageSuccess | Error;

export type DeleteFundraiserContentImageSuccess = {
  __typename?: 'DeleteFundraiserContentImageSuccess';
  fundraiserContent: FundraiserContent;
};

export type DeleteTeamAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteTeam */
export type DeleteTeamInput = {
  attributes: DeleteTeamAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeleteTeamIntegrationAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  integrationId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteTeamIntegration */
export type DeleteTeamIntegrationInput = {
  attributes: DeleteTeamIntegrationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::DeleteTeamIntegration possible responses */
export type DeleteTeamIntegrationResult = DeleteTeamIntegrationSuccess | Error;

export type DeleteTeamIntegrationSuccess = {
  __typename?: 'DeleteTeamIntegrationSuccess';
  team: Team;
};

export type DeleteTeamInvitationAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteTeamInvitation */
export type DeleteTeamInvitationInput = {
  attributes: DeleteTeamInvitationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::DeleteTeamInvitation possible responses */
export type DeleteTeamInvitationResult = DeleteTeamInvitationSuccess | Error;

export type DeleteTeamInvitationSuccess = {
  __typename?: 'DeleteTeamInvitationSuccess';
  team: Team;
};

/** Mutations::Teams::DeleteTeam possible responses */
export type DeleteTeamResult = DeleteTeamSuccess | Error;

export type DeleteTeamSuccess = {
  __typename?: 'DeleteTeamSuccess';
  team: Team;
};

export type DeleteTicketAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DeleteTicket */
export type DeleteTicketInput = {
  attributes: DeleteTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Tickets::DeleteTicket possible responses */
export type DeleteTicketResult = DeleteTicketSuccess | Error;

export type DeleteTicketSuccess = {
  __typename?: 'DeleteTicketSuccess';
  ticket: Ticket;
};

export type Donation = {
  __typename?: 'Donation';
  amount: Scalars['String'];
  campaign: Campaign;
  checkedInCount: Scalars['Int'];
  comments?: Maybe<Array<Comment>>;
  donor: Donor;
  donorTickets?: Maybe<Array<DonorTicket>>;
  employerMatchEmailSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  employerMatching?: Maybe<Scalars['Boolean']>;
  honoree?: Maybe<Honoree>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  kindHumanized?: Maybe<Scalars['String']>;
  needsReviewAt?: Maybe<Scalars['ISO8601DateTime']>;
  offline: Scalars['Boolean'];
  receiptDownloadLink?: Maybe<Scalars['String']>;
  receiptSent: Scalars['Boolean'];
  refundedAt?: Maybe<Scalars['ISO8601DateTime']>;
  sentAt?: Maybe<Scalars['ISO8601DateTime']>;
  stripeId?: Maybe<Scalars['String']>;
  totalTicketCount: Scalars['Int'];
};

/** The connection type for Donation. */
export type DonationConnection = {
  __typename?: 'DonationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DonationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Donation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DonationEdge = {
  __typename?: 'DonationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Donation>;
};

export type DonationReceipt = {
  __typename?: 'DonationReceipt';
  canDestroy: Scalars['Boolean'];
  default?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  mainContent?: Maybe<Scalars['String']>;
  signatureName?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** The connection type for DonationReceipt. */
export type DonationReceiptConnection = {
  __typename?: 'DonationReceiptConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DonationReceiptEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DonationReceipt>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DonationReceiptEdge = {
  __typename?: 'DonationReceiptEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DonationReceipt>;
};

export type Donor = {
  __typename?: 'Donor';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  canDestroy: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  donationCount?: Maybe<Scalars['Int']>;
  donationTotal?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastDonation?: Maybe<Donation>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  rfmScore?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueCampaigns?: Maybe<Array<Campaign>>;
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for Donor. */
export type DonorConnection = {
  __typename?: 'DonorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DonorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Donor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DonorEdge = {
  __typename?: 'DonorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Donor>;
};

export type DonorTicket = {
  __typename?: 'DonorTicket';
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
  comments?: Maybe<Scalars['String']>;
  donation?: Maybe<Donation>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ticket?: Maybe<Ticket>;
  token?: Maybe<Scalars['String']>;
};

/** The connection type for DonorTicket. */
export type DonorTicketConnection = {
  __typename?: 'DonorTicketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DonorTicketEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DonorTicket>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DonorTicketEdge = {
  __typename?: 'DonorTicketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DonorTicket>;
};

export type DownloadDonationReceiptAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of DownloadDonationReceipt */
export type DownloadDonationReceiptInput = {
  attributes: DownloadDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::DownloadDonationReceipt possible responses */
export type DownloadDonationReceiptResult = DownloadDonationReceiptSuccess | Error;

export type DownloadDonationReceiptSuccess = {
  __typename?: 'DownloadDonationReceiptSuccess';
  donation: Donation;
};

export type EditCampaignAttributes = {
  active?: InputMaybe<Scalars['Boolean']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  goalAmount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publicName?: InputMaybe<Scalars['String']>;
  sendDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  sendTickets?: InputMaybe<Scalars['Boolean']>;
  startsAt?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  timeInfo?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditCampaign */
export type EditCampaignInput = {
  attributes: EditCampaignAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Campaigns::EditCampaign possible responses */
export type EditCampaignResult = EditCampaignSuccess | Error;

export type EditCampaignSuccess = {
  __typename?: 'EditCampaignSuccess';
  campaign: Campaign;
};

export type EditDonationAttributes = {
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Scalars['String']>;
  sentAt?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditDonation */
export type EditDonationInput = {
  attributes: EditDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type EditDonationReceiptAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  mainContent?: InputMaybe<Scalars['String']>;
  signatureName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditDonationReceipt */
export type EditDonationReceiptInput = {
  attributes: EditDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonationReceipts::EditDonationReceipt possible responses */
export type EditDonationReceiptResult = EditDonationReceiptSuccess | Error;

export type EditDonationReceiptSuccess = {
  __typename?: 'EditDonationReceiptSuccess';
  donationReceipt: DonationReceipt;
};

/** Mutations::Donations::EditDonation possible responses */
export type EditDonationResult = EditDonationSuccess | Error;

export type EditDonationSuccess = {
  __typename?: 'EditDonationSuccess';
  donation: Donation;
};

export type EditDonorAttributes = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditDonor */
export type EditDonorInput = {
  attributes: EditDonorAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donors::EditDonor possible responses */
export type EditDonorResult = EditDonorSuccess | Error;

export type EditDonorSuccess = {
  __typename?: 'EditDonorSuccess';
  donor: Donor;
};

export type EditEmailAttributes = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditEmail */
export type EditEmailInput = {
  attributes: EditEmailAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Emails::EditEmail possible responses */
export type EditEmailResult = EditEmailSuccess | Error;

export type EditEmailSuccess = {
  __typename?: 'EditEmailSuccess';
  email: Email;
};

export type EditEventAttributes = {
  body?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
  sidebar?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of EditEvent */
export type EditEventInput = {
  attributes: EditEventAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Events::EditEvent possible responses */
export type EditEventResult = EditEventSuccess | Error;

export type EditEventSuccess = {
  __typename?: 'EditEventSuccess';
  fundraiserContent: FundraiserContent;
};

export type EditFundraiserAttributes = {
  allowPublicComments?: InputMaybe<Scalars['Boolean']>;
  body?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  includeHonoree?: InputMaybe<Scalars['Boolean']>;
  includeRecipient?: InputMaybe<Scalars['Boolean']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
  sidebar?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Autogenerated input type of EditFundraiser */
export type EditFundraiserInput = {
  attributes: EditFundraiserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Fundraisers::EditFundraiser possible responses */
export type EditFundraiserResult = EditFundraiserSuccess | Error;

export type EditFundraiserSuccess = {
  __typename?: 'EditFundraiserSuccess';
  fundraiserContent: FundraiserContent;
};

export type EditTeamAttributes = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditTeam */
export type EditTeamInput = {
  attributes: EditTeamAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::EditTeam possible responses */
export type EditTeamResult = EditTeamSuccess | Error;

export type EditTeamSuccess = {
  __typename?: 'EditTeamSuccess';
  team: Team;
};

export type EditTicketAttributes = {
  attendeesPerTicket?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  fairMarketValueAmount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  maximumPerOrder?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of EditTicket */
export type EditTicketInput = {
  attributes: EditTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Tickets::EditTicket possible responses */
export type EditTicketResult = EditTicketSuccess | Error;

export type EditTicketSuccess = {
  __typename?: 'EditTicketSuccess';
  ticket: Ticket;
};

export type Email = {
  __typename?: 'Email';
  content?: Maybe<Scalars['String']>;
  emailEvents?: Maybe<Array<EmailEvent>>;
  id: Scalars['ID'];
  sentAt?: Maybe<Scalars['ISO8601DateTime']>;
  sentCount?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
};

/** The connection type for Email. */
export type EmailConnection = {
  __typename?: 'EmailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmailEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Email>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EmailEdge = {
  __typename?: 'EmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Email>;
};

export type EmailEvent = {
  __typename?: 'EmailEvent';
  attachments: Array<ActiveStorageAttachment>;
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  eventName?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventable: Eventable;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['ISO8601DateTime']>;
};

/** The connection type for EmailEvent. */
export type EmailEventConnection = {
  __typename?: 'EmailEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmailEventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EmailEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EmailEventEdge = {
  __typename?: 'EmailEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EmailEvent>;
};

/** A user-readable error */
export type Error = {
  __typename?: 'Error';
  errors?: Maybe<Array<Scalars['JSON']>>;
};

export type Eventable = Donation | Donor;

export type ExportDonationsAttributes = {
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of ExportDonations */
export type ExportDonationsInput = {
  attributes: ExportDonationsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type ExportDonorTicketsAttributes = {
  campaignIds?: InputMaybe<Array<Scalars['ID']>>;
  donorId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of ExportDonorTickets */
export type ExportDonorTicketsInput = {
  attributes: ExportDonorTicketsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of ForgotPassword */
export type ForgotPasswordInput = {
  attributes: ResetUserPasswordAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type FundraiserContent = {
  __typename?: 'FundraiserContent';
  addressRequired: Scalars['Boolean'];
  allowPublicComments: Scalars['Boolean'];
  body: Scalars['String'];
  campaignKind: Scalars['String'];
  defaultCampaignUrl?: Maybe<Scalars['String']>;
  emailRequired: Scalars['Boolean'];
  fundraiserActive: Scalars['Boolean'];
  fundraiserUrl?: Maybe<Scalars['String']>;
  goalAmount?: Maybe<Scalars['String']>;
  goalCompletionPercentage?: Maybe<Scalars['Float']>;
  googleAnalyticsId?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  id: Scalars['ID'];
  includeEmployerMatch: Scalars['Boolean'];
  includeFees: Scalars['Boolean'];
  includeHonoree: Scalars['Boolean'];
  includeRecipient: Scalars['Boolean'];
  includeRecurring: Scalars['Boolean'];
  locationFullAddress?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  nameRequired: Scalars['Boolean'];
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  pledgeUrl?: Maybe<Scalars['String']>;
  publicImageUrl?: Maybe<Scalars['String']>;
  purchaseableTickets: Array<Ticket>;
  showGoal?: Maybe<Scalars['Boolean']>;
  sidebar: Scalars['JSON'];
  teamId: Scalars['ID'];
  teamName: Scalars['String'];
  teamUrl?: Maybe<Scalars['String']>;
  timeInfo?: Maybe<Scalars['String']>;
  totalDonationCount?: Maybe<Scalars['String']>;
  totalRaised?: Maybe<Scalars['String']>;
};

export type Honoree = {
  __typename?: 'Honoree';
  id: Scalars['ID'];
  name: Scalars['String'];
  recipientEmail?: Maybe<Scalars['String']>;
  recipientMessage?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
};

export type Integration = {
  __typename?: 'Integration';
  apiFieldThreeName?: Maybe<Scalars['String']>;
  apiFieldTwoName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InviteUserToTeamAttributes = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of InviteUserToTeam */
export type InviteUserToTeamInput = {
  attributes: InviteUserToTeamAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::InviteUserToTeam possible responses */
export type InviteUserToTeamResult = Error | InviteUserToTeamSuccess;

export type InviteUserToTeamSuccess = {
  __typename?: 'InviteUserToTeamSuccess';
  team: Team;
};

export type ManualDonationRefundAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of ManualDonationRefund */
export type ManualDonationRefundInput = {
  attributes: ManualDonationRefundAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::ManualDonationRefund possible responses */
export type ManualDonationRefundResult = Error | ManualDonationRefundSuccess;

export type ManualDonationRefundSuccess = {
  __typename?: 'ManualDonationRefundSuccess';
  donation: Donation;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDonorTicketDetails?: Maybe<AddDonorTicketDetailsResult>;
  addIntegrationToTeam?: Maybe<AddIntegrationToTeamResult>;
  addTicketsToDonation?: Maybe<AddTicketsToDonationResult>;
  assignDonationReceipt?: Maybe<AssignDonationReceiptResult>;
  cancelSubscription?: Maybe<Scalars['Boolean']>;
  checkIn?: Maybe<CheckInResult>;
  confirmDonation?: Maybe<ConfirmDonationResult>;
  createCampaign?: Maybe<CreateCampaignResult>;
  createComment?: Maybe<CreateCommentResult>;
  createDonation?: Maybe<CreateDonationResult>;
  createDonationReceipt?: Maybe<CreateDonationReceiptResult>;
  createDonor?: Maybe<CreateDonorResult>;
  createDonorTicket?: Maybe<CreateDonorTicketResult>;
  createEmail?: Maybe<CreateEmailResult>;
  createEvent?: Maybe<CreateEventResult>;
  createFundraiser?: Maybe<CreateFundraiserResult>;
  createPaymentIntent?: Maybe<CreatePaymentIntentResult>;
  createPledge?: Maybe<CreatePledgeResult>;
  createPublicComment?: Maybe<CreatePublicCommentResult>;
  createSession?: Maybe<CreateSessionResult>;
  createSubscriptionBillingPortal?: Maybe<Scalars['String']>;
  createSubscriptionIntent?: Maybe<CreateSubscriptionIntentResult>;
  createTeam?: Maybe<CreateTeamResult>;
  createTicket?: Maybe<CreateTicketResult>;
  createTicketOrderDonation?: Maybe<CreateTicketOrderDonationResult>;
  createTicketPaymentIntent?: Maybe<CreateTicketPaymentIntentResult>;
  createUser?: Maybe<CreateUserResult>;
  deleteCampaign?: Maybe<DeleteCampaignResult>;
  deleteDonation?: Maybe<DeleteDonationResult>;
  deleteDonationReceipt?: Maybe<DeleteDonationReceiptResult>;
  deleteDonationReceiptSignature?: Maybe<DeleteDonationReceiptSignatureResult>;
  deleteDonor?: Maybe<DeleteDonorResult>;
  deleteDonorTicket?: Maybe<DeleteDonorTicketResult>;
  deleteFundraiserContentImage?: Maybe<DeleteFundraiserContentImageResult>;
  deleteTeam?: Maybe<DeleteTeamResult>;
  deleteTeamIntegration?: Maybe<DeleteTeamIntegrationResult>;
  deleteTeamInvitation?: Maybe<DeleteTeamInvitationResult>;
  deleteTicket?: Maybe<DeleteTicketResult>;
  downloadDonationReceipt?: Maybe<DownloadDonationReceiptResult>;
  editCampaign?: Maybe<EditCampaignResult>;
  editDonation?: Maybe<EditDonationResult>;
  editDonationReceipt?: Maybe<EditDonationReceiptResult>;
  editDonor?: Maybe<EditDonorResult>;
  editEmail?: Maybe<EditEmailResult>;
  editEvent?: Maybe<EditEventResult>;
  editFundraiser?: Maybe<EditFundraiserResult>;
  editTeam?: Maybe<EditTeamResult>;
  editTicket?: Maybe<EditTicketResult>;
  exportDonations?: Maybe<Scalars['Boolean']>;
  exportDonorTickets?: Maybe<Scalars['Boolean']>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  inviteUserToTeam?: Maybe<InviteUserToTeamResult>;
  manualDonationRefund?: Maybe<ManualDonationRefundResult>;
  reassignDonorTicket?: Maybe<ReassignDonorTicketResult>;
  refundStripeDonation?: Maybe<RefundStripeDonationResult>;
  reorderTicket?: Maybe<ReorderTicketResult>;
  sendDonationReceipt?: Maybe<SendDonationReceiptResult>;
  sendEmail?: Maybe<SendEmailResult>;
  sendEmployerMatchEmail?: Maybe<SendEmployerMatchEmailResult>;
  sendTicketDonationReceipts?: Maybe<SendTicketDonationReceiptsResult>;
  setDefaultDonationReceipt?: Maybe<SetDefaultDonationReceiptResult>;
  updateTeamLogo?: Maybe<UpdateTeamLogoResult>;
  updateUserPasswordFromToken?: Maybe<UpdateUserPasswordFromTokenResult>;
  uploadDonationReceiptSignature?: Maybe<UploadDonationReceiptSignatureResult>;
  uploadFundraiserContentImage?: Maybe<UploadFundraiserContentImageResult>;
};


export type MutationAddDonorTicketDetailsArgs = {
  input: AddDonorTicketDetailsInput;
};


export type MutationAddIntegrationToTeamArgs = {
  input: AddIntegrationToTeamInput;
};


export type MutationAddTicketsToDonationArgs = {
  input: AddTicketsToDonationInput;
};


export type MutationAssignDonationReceiptArgs = {
  input: AssignDonationReceiptInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCheckInArgs = {
  input: CheckInInput;
};


export type MutationConfirmDonationArgs = {
  input: ConfirmDonationInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateDonationArgs = {
  input: CreateDonationInput;
};


export type MutationCreateDonationReceiptArgs = {
  input: CreateDonationReceiptInput;
};


export type MutationCreateDonorArgs = {
  input: CreateDonorInput;
};


export type MutationCreateDonorTicketArgs = {
  input: CreateDonorTicketInput;
};


export type MutationCreateEmailArgs = {
  input: CreateEmailInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateFundraiserArgs = {
  input: CreateFundraiserInput;
};


export type MutationCreatePaymentIntentArgs = {
  input: CreatePaymentIntentInput;
};


export type MutationCreatePledgeArgs = {
  input: CreatePledgeInput;
};


export type MutationCreatePublicCommentArgs = {
  input: CreatePublicCommentInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationCreateSubscriptionBillingPortalArgs = {
  input: CreateSubscriptionBillingPortalInput;
};


export type MutationCreateSubscriptionIntentArgs = {
  input: CreateSubscriptionIntentInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTicketArgs = {
  input: CreateTicketInput;
};


export type MutationCreateTicketOrderDonationArgs = {
  input: CreateTicketOrderDonationInput;
};


export type MutationCreateTicketPaymentIntentArgs = {
  input: CreateTicketPaymentIntentInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


export type MutationDeleteDonationArgs = {
  input: DeleteDonationInput;
};


export type MutationDeleteDonationReceiptArgs = {
  input: DeleteDonationReceiptInput;
};


export type MutationDeleteDonationReceiptSignatureArgs = {
  input: DeleteDonationReceiptSignatureInput;
};


export type MutationDeleteDonorArgs = {
  input: DeleteDonorInput;
};


export type MutationDeleteDonorTicketArgs = {
  input: DeleteDonorTicketInput;
};


export type MutationDeleteFundraiserContentImageArgs = {
  input: DeleteFundraiserContentImageInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteTeamIntegrationArgs = {
  input: DeleteTeamIntegrationInput;
};


export type MutationDeleteTeamInvitationArgs = {
  input: DeleteTeamInvitationInput;
};


export type MutationDeleteTicketArgs = {
  input: DeleteTicketInput;
};


export type MutationDownloadDonationReceiptArgs = {
  input: DownloadDonationReceiptInput;
};


export type MutationEditCampaignArgs = {
  input: EditCampaignInput;
};


export type MutationEditDonationArgs = {
  input: EditDonationInput;
};


export type MutationEditDonationReceiptArgs = {
  input: EditDonationReceiptInput;
};


export type MutationEditDonorArgs = {
  input: EditDonorInput;
};


export type MutationEditEmailArgs = {
  input: EditEmailInput;
};


export type MutationEditEventArgs = {
  input: EditEventInput;
};


export type MutationEditFundraiserArgs = {
  input: EditFundraiserInput;
};


export type MutationEditTeamArgs = {
  input: EditTeamInput;
};


export type MutationEditTicketArgs = {
  input: EditTicketInput;
};


export type MutationExportDonationsArgs = {
  input: ExportDonationsInput;
};


export type MutationExportDonorTicketsArgs = {
  input: ExportDonorTicketsInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationInviteUserToTeamArgs = {
  input: InviteUserToTeamInput;
};


export type MutationManualDonationRefundArgs = {
  input: ManualDonationRefundInput;
};


export type MutationReassignDonorTicketArgs = {
  input: ReassignDonorTicketInput;
};


export type MutationRefundStripeDonationArgs = {
  input: RefundStripeDonationInput;
};


export type MutationReorderTicketArgs = {
  input: ReorderTicketInput;
};


export type MutationSendDonationReceiptArgs = {
  input: SendDonationReceiptInput;
};


export type MutationSendEmailArgs = {
  input: SendEmailInput;
};


export type MutationSendEmployerMatchEmailArgs = {
  input: SendEmployerMatchEmailInput;
};


export type MutationSendTicketDonationReceiptsArgs = {
  input: SendTicketDonationReceiptsInput;
};


export type MutationSetDefaultDonationReceiptArgs = {
  input: SetDefaultDonationReceiptInput;
};


export type MutationUpdateTeamLogoArgs = {
  input: UpdateTeamLogoInput;
};


export type MutationUpdateUserPasswordFromTokenArgs = {
  input: UpdateUserPasswordFromTokenInput;
};


export type MutationUploadDonationReceiptSignatureArgs = {
  input: UploadDonationReceiptSignatureInput;
};


export type MutationUploadFundraiserContentImageArgs = {
  input: UploadFundraiserContentImageInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount: Scalars['String'];
  paymentIntent: Scalars['String'];
  stripeAccountId: Scalars['String'];
};

export type PublicComment = {
  __typename?: 'PublicComment';
  amount: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
  redirectUrl: Scalars['String'];
};

/** The connection type for PublicComment. */
export type PublicCommentConnection = {
  __typename?: 'PublicCommentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PublicCommentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PublicComment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PublicCommentEdge = {
  __typename?: 'PublicCommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PublicComment>;
};

export type Query = {
  __typename?: 'Query';
  /** Get campaign for the user logged in */
  campaign?: Maybe<Campaign>;
  /** Get paginated campaigns for the user logged in */
  campaigns: CampaignConnection;
  /** Get paginated donors for the user logged in */
  comments: CommentConnection;
  connectAccount?: Maybe<Scalars['String']>;
  /** Get donation for the user logged in */
  donation?: Maybe<Donation>;
  /** Get donation receipt for campaign */
  donationReceipt?: Maybe<DonationReceipt>;
  /** Get paginated donation receipts for the user logged in */
  donationReceipts: DonationReceiptConnection;
  /** Get paginated donations for the user logged in */
  donations: DonationConnection;
  /** Get paginated donations for the user logged in */
  donationsAmountChartData?: Maybe<Scalars['JSON']>;
  /** Get paginated donations for the user logged in */
  donationsCampaigns?: Maybe<Scalars['JSON']>;
  /** Get paginated donations for the user logged in */
  donationsCampaignsChartData?: Maybe<Scalars['JSON']>;
  /** Get paginated donations for the user logged in */
  donationsDonors?: Maybe<Scalars['JSON']>;
  /** Get donor for the user logged in */
  donor?: Maybe<Donor>;
  /** Get donor ticket for the user logged in */
  donorTicket?: Maybe<DonorTicket>;
  /** Get donor ticket for the user logged in by token */
  donorTicketByToken?: Maybe<DonorTicket>;
  /** Get paginated donations for the user logged in */
  donorTickets: DonorTicketConnection;
  /** Get donor ticket by donation token */
  donorTicketsByDonationToken: Array<DonorTicket>;
  /** Get paginated donors for the user logged in */
  donors: DonorConnection;
  /** Get email */
  email: Email;
  /** Get paginated donors for the user logged in */
  emailEvents: EmailEventConnection;
  /** Get paginated donors for the user logged in */
  emails: EmailConnection;
  /** Get content for fundraiser */
  fundraiserContent?: Maybe<FundraiserContent>;
  /** Get subscription */
  getSubscriptionFromToken?: Maybe<StripeSubscription>;
  /** Get integration */
  integration?: Maybe<Integration>;
  /** Get the current logged in user */
  me?: Maybe<User>;
  /** Get paginated public comments for campaign ID */
  publicComments: PublicCommentConnection;
  /** Get subscription */
  stripeSubscription?: Maybe<StripeSubscription>;
  /** Get paginated subscriptions for the user logged in */
  stripeSubscriptions: StripeSubscriptionConnection;
  /** Get a team for the user logged in */
  team?: Maybe<Team>;
  teamPortalSession: Scalars['JSON'];
  teamSessionCheckout: Scalars['JSON'];
  /** Get paginated teams for the user logged in */
  teams: TeamConnection;
  /** Get ticket for the user logged in */
  ticket?: Maybe<Ticket>;
};


export type QueryCampaignArgs = {
  id: Scalars['ID'];
};


export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type QueryConnectAccountArgs = {
  id: Scalars['ID'];
};


export type QueryDonationArgs = {
  id: Scalars['ID'];
};


export type QueryDonationReceiptArgs = {
  id: Scalars['ID'];
};


export type QueryDonationReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  needsReview?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonationsAmountChartDataArgs = {
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonationsCampaignsArgs = {
  donorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonationsCampaignsChartDataArgs = {
  donorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonationsDonorsArgs = {
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonorArgs = {
  id: Scalars['ID'];
};


export type QueryDonorTicketArgs = {
  id: Scalars['ID'];
};


export type QueryDonorTicketByTokenArgs = {
  token: Scalars['String'];
};


export type QueryDonorTicketsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignIds?: InputMaybe<Array<Scalars['ID']>>;
  donationId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryDonorTicketsByDonationTokenArgs = {
  campaignId: Scalars['ID'];
  token: Scalars['String'];
};


export type QueryDonorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryEmailArgs = {
  id: Scalars['ID'];
};


export type QueryEmailEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type QueryEmailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryFundraiserContentArgs = {
  campaignId: Scalars['ID'];
};


export type QueryGetSubscriptionFromTokenArgs = {
  token: Scalars['String'];
};


export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryPublicCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStripeSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryStripeSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['ID']>;
  donorId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamPortalSessionArgs = {
  id: Scalars['ID'];
};


export type QueryTeamSessionCheckoutArgs = {
  id: Scalars['ID'];
};


export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};

export type ReassignDonorTicketAttributes = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of ReassignDonorTicket */
export type ReassignDonorTicketInput = {
  attributes: ReassignDonorTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonorTickets::ReassignDonorTicket possible responses */
export type ReassignDonorTicketResult = Error | ReassignDonorTicketSuccess;

export type ReassignDonorTicketSuccess = {
  __typename?: 'ReassignDonorTicketSuccess';
  donorTicket: DonorTicket;
};

export type RefundDonationAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of RefundStripeDonation */
export type RefundStripeDonationInput = {
  attributes: RefundDonationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::RefundStripeDonation possible responses */
export type RefundStripeDonationResult = Error | RefundStripeDonationSuccess;

export type RefundStripeDonationSuccess = {
  __typename?: 'RefundStripeDonationSuccess';
  donation: Donation;
};

export type ReorderTicketAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of ReorderTicket */
export type ReorderTicketInput = {
  attributes: ReorderTicketAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Tickets::ReorderTicket possible responses */
export type ReorderTicketResult = Error | ReorderTicketSuccess;

export type ReorderTicketSuccess = {
  __typename?: 'ReorderTicketSuccess';
  ticket: Ticket;
};

export type ResetUserPasswordAttributes = {
  email?: InputMaybe<Scalars['String']>;
};

export type SendDonationReceiptAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  includePdf?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of SendDonationReceipt */
export type SendDonationReceiptInput = {
  attributes: SendDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::SendDonationReceipt possible responses */
export type SendDonationReceiptResult = Error | SendDonationReceiptSuccess;

export type SendDonationReceiptSuccess = {
  __typename?: 'SendDonationReceiptSuccess';
  donation: Donation;
};

export type SendEmailAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  sendTest?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of SendEmail */
export type SendEmailInput = {
  attributes: SendEmailAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Emails::SendEmail possible responses */
export type SendEmailResult = Error | SendEmailSuccess;

export type SendEmailSuccess = {
  __typename?: 'SendEmailSuccess';
  email: Email;
};

export type SendEmployerMatchEmailAttributes = {
  employerMatchUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of SendEmployerMatchEmail */
export type SendEmployerMatchEmailInput = {
  attributes: SendEmployerMatchEmailAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Donations::SendEmployerMatchEmail possible responses */
export type SendEmployerMatchEmailResult = Error | SendEmployerMatchEmailSuccess;

export type SendEmployerMatchEmailSuccess = {
  __typename?: 'SendEmployerMatchEmailSuccess';
  donation: Donation;
};

export type SendTicketDonationReceiptsAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  includePdf?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of SendTicketDonationReceipts */
export type SendTicketDonationReceiptsInput = {
  attributes: SendTicketDonationReceiptsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Tickets::SendTicketDonationReceipts possible responses */
export type SendTicketDonationReceiptsResult = Error | SendTicketDonationReceiptsSuccess;

export type SendTicketDonationReceiptsSuccess = {
  __typename?: 'SendTicketDonationReceiptsSuccess';
  ticket: Ticket;
};

export type SetDefaultDonationReceiptAttributes = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of SetDefaultDonationReceipt */
export type SetDefaultDonationReceiptInput = {
  attributes: SetDefaultDonationReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonationReceipts::SetDefaultDonationReceipt possible responses */
export type SetDefaultDonationReceiptResult = Error | SetDefaultDonationReceiptSuccess;

export type SetDefaultDonationReceiptSuccess = {
  __typename?: 'SetDefaultDonationReceiptSuccess';
  donationReceipt: DonationReceipt;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  amount: Scalars['String'];
  campaign: Campaign;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  donations: Array<Donation>;
  donor: Donor;
  id: Scalars['ID'];
  interval?: Maybe<Scalars['String']>;
  nextBillingOn?: Maybe<Scalars['ISO8601DateTime']>;
  token?: Maybe<Scalars['String']>;
};

/** The connection type for StripeSubscription. */
export type StripeSubscriptionConnection = {
  __typename?: 'StripeSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StripeSubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<StripeSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StripeSubscriptionEdge = {
  __typename?: 'StripeSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<StripeSubscription>;
};

export type Team = {
  __typename?: 'Team';
  activeCampaignCount?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  availableIntegrations?: Maybe<Array<Integration>>;
  averageDonations?: Maybe<Scalars['String']>;
  averageRaised?: Maybe<Scalars['String']>;
  canConnect?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  donationCount?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrations?: Maybe<Array<Integration>>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pendingTeamInvitations?: Maybe<Array<TeamInvitation>>;
  state?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeEnabledAt?: Maybe<Scalars['ISO8601DateTime']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  teamUsers: Array<TeamUser>;
  totalLastThirtyRaised?: Maybe<Scalars['String']>;
  totalRaised?: Maybe<Scalars['String']>;
  totalYearRaised?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for Team. */
export type TeamConnection = {
  __typename?: 'TeamConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Team>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TeamEdge = {
  __typename?: 'TeamEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Team>;
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type TeamUser = {
  __typename?: 'TeamUser';
  id: Scalars['ID'];
  level?: Maybe<Scalars['String']>;
  user: User;
};

export type Ticket = {
  __typename?: 'Ticket';
  attendeesPerTicket?: Maybe<Scalars['Int']>;
  campaign?: Maybe<Campaign>;
  canDestroy: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  fairMarketValueAmount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maximumAvailable?: Maybe<Scalars['Int']>;
  maximumPerOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  purchasedCount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type UpdateTeamLogoAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<Scalars['Upload']>;
};

/** Autogenerated input type of UpdateTeamLogo */
export type UpdateTeamLogoInput = {
  attributes: UpdateTeamLogoAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Teams::UpdateTeamLogo possible responses */
export type UpdateTeamLogoResult = Error | UpdateTeamLogoSuccess;

export type UpdateTeamLogoSuccess = {
  __typename?: 'UpdateTeamLogoSuccess';
  team: Team;
};

export type UpdateUserPasswordFromTokenAttributes = {
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUserPasswordFromToken */
export type UpdateUserPasswordFromTokenInput = {
  attributes: UpdateUserPasswordFromTokenAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Users::UpdateUserPasswordFromToken possible responses */
export type UpdateUserPasswordFromTokenResult = Error | UpdateUserPasswordFromTokenSuccess;

export type UpdateUserPasswordFromTokenSuccess = {
  __typename?: 'UpdateUserPasswordFromTokenSuccess';
  token: Scalars['String'];
  user: User;
};

export type UploadDonationReceiptSignatureAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  signature?: InputMaybe<Scalars['Upload']>;
};

/** Autogenerated input type of UploadDonationReceiptSignature */
export type UploadDonationReceiptSignatureInput = {
  attributes: UploadDonationReceiptSignatureAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::DonationReceipts::UploadDonationReceiptSignature possible responses */
export type UploadDonationReceiptSignatureResult = Error | UploadDonationReceiptSignatureSuccess;

export type UploadDonationReceiptSignatureSuccess = {
  __typename?: 'UploadDonationReceiptSignatureSuccess';
  donationReceipt: DonationReceipt;
};

export type UploadFundraiserContentImageAttributes = {
  id?: InputMaybe<Scalars['ID']>;
  publicImage?: InputMaybe<Scalars['Upload']>;
};

/** Autogenerated input type of UploadFundraiserContentImage */
export type UploadFundraiserContentImageInput = {
  attributes: UploadFundraiserContentImageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Mutations::Fundraisers::UploadFundraiserContentImage possible responses */
export type UploadFundraiserContentImageResult = Error | UploadFundraiserContentImageSuccess;

export type UploadFundraiserContentImageSuccess = {
  __typename?: 'UploadFundraiserContentImageSuccess';
  fundraiserContent: FundraiserContent;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  level: Scalars['String'];
  me: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type CampaignQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CampaignQuery = { __typename?: 'Query', campaign?: { __typename?: 'Campaign', id: string, name: string, active: boolean, archived: boolean, description?: string | null | undefined, kind: string, startsAt?: any | null | undefined, endsAt?: any | null | undefined, goalAmount?: string | null | undefined, totalRaised?: string | null | undefined, totalDonationCount?: string | null | undefined, sendTickets?: boolean | null | undefined, goalCompletionPercentage?: number | null | undefined, sendDonationReceipt: boolean, canDestroy: boolean, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, default?: boolean | null | undefined, zip?: string | null | undefined, publicName?: string | null | undefined, locationName?: string | null | undefined, timeInfo?: string | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined, donationReceipt?: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, default?: boolean | null | undefined } | null | undefined } | null | undefined };

export type CampaignsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
}>;


export type CampaignsQuery = { __typename?: 'Query', campaigns: { __typename?: 'CampaignConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'CampaignEdge', node?: { __typename?: 'Campaign', id: string, name: string, active: boolean, archived: boolean, description?: string | null | undefined, kind: string, startsAt?: any | null | undefined, endsAt?: any | null | undefined, goalAmount?: string | null | undefined, totalRaised?: string | null | undefined, default?: boolean | null | undefined, totalDonationCount?: string | null | undefined, goalCompletionPercentage?: number | null | undefined, sendDonationReceipt: boolean, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, sendTickets?: boolean | null | undefined, publicName?: string | null | undefined, locationName?: string | null | undefined, timeInfo?: string | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type CreateCampaignMutationVariables = Exact<{
  teamId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  startsAt?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  active: Scalars['Boolean'];
  goalAmount: Scalars['String'];
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  timeInfo?: InputMaybe<Scalars['String']>;
  publicName: Scalars['String'];
  sendDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  locationName?: InputMaybe<Scalars['String']>;
  sendTickets?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateCampaignMutation = { __typename?: 'Mutation', createCampaign?: { __typename?: 'CreateCampaignSuccess', campaign: { __typename?: 'Campaign', id: string, name: string, active: boolean, archived: boolean, description?: string | null | undefined, kind: string, startsAt?: any | null | undefined, endsAt?: any | null | undefined, goalAmount?: string | null | undefined, totalRaised?: string | null | undefined, totalDonationCount?: string | null | undefined, goalCompletionPercentage?: number | null | undefined, sendDonationReceipt: boolean, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, publicName?: string | null | undefined, sendTickets?: boolean | null | undefined, locationName?: string | null | undefined, timeInfo?: string | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCampaignMutation = { __typename?: 'Mutation', deleteCampaign?: { __typename?: 'DeleteCampaignSuccess', campaign: { __typename?: 'Campaign', id: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonationsCampaignsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type DonationsCampaignsQuery = { __typename?: 'Query', donationsCampaigns?: any | null | undefined };

export type EditCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  goalAmount?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  timeInfo?: InputMaybe<Scalars['String']>;
  publicName?: InputMaybe<Scalars['String']>;
  sendDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  sendTickets?: InputMaybe<Scalars['Boolean']>;
  locationName?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['Boolean']>;
}>;


export type EditCampaignMutation = { __typename?: 'Mutation', editCampaign?: { __typename?: 'EditCampaignSuccess', campaign: { __typename?: 'Campaign', id: string, name: string, active: boolean, archived: boolean, description?: string | null | undefined, kind: string, startsAt?: any | null | undefined, endsAt?: any | null | undefined, goalAmount?: string | null | undefined, totalRaised?: string | null | undefined, totalDonationCount?: string | null | undefined, goalCompletionPercentage?: number | null | undefined, sendDonationReceipt: boolean, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, publicName?: string | null | undefined, default?: boolean | null | undefined, sendTickets?: boolean | null | undefined, locationName?: string | null | undefined, timeInfo?: string | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreateEmailMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  content: Scalars['String'];
  subject: Scalars['String'];
}>;


export type CreateEmailMutation = { __typename?: 'Mutation', createEmail?: { __typename?: 'CreateEmailSuccess', email: { __typename?: 'Email', id: string, subject?: string | null | undefined, content?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EditEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
  subject: Scalars['String'];
}>;


export type EditEmailMutation = { __typename?: 'Mutation', editEmail?: { __typename?: 'EditEmailSuccess', email: { __typename?: 'Email', id: string, subject?: string | null | undefined, content?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EmailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmailQuery = { __typename?: 'Query', email: { __typename?: 'Email', id: string, subject?: string | null | undefined, content?: string | null | undefined, sentCount?: number | null | undefined, sentAt?: any | null | undefined, emailEvents?: Array<{ __typename?: 'EmailEvent', id: string, eventable: { __typename?: 'Donation', id: string, donor: { __typename?: 'Donor', id: string, email?: string | null | undefined, name: string } } | { __typename?: 'Donor' } }> | null | undefined } };

export type EmailsQueryVariables = Exact<{
  campaignId: Scalars['ID'];
}>;


export type EmailsQuery = { __typename?: 'Query', emails: { __typename?: 'EmailConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'EmailEdge', node?: { __typename?: 'Email', id: string, subject?: string | null | undefined, content?: string | null | undefined, sentCount?: number | null | undefined, sentAt?: any | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type SendEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  sendTest?: InputMaybe<Scalars['Boolean']>;
}>;


export type SendEmailMutation = { __typename?: 'Mutation', sendEmail?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'SendEmailSuccess', email: { __typename?: 'Email', id: string, subject?: string | null | undefined, content?: string | null | undefined, sentAt?: any | null | undefined } } | null | undefined };

export type CommentsQueryVariables = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
}>;


export type CommentsQuery = { __typename?: 'Query', comments: { __typename?: 'CommentConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'CommentEdge', node?: { __typename?: 'Comment', id: string, comment: string, createdAt: any, user?: { __typename?: 'User', id: string, name?: string | null | undefined, email: string } | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type CreateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
  comment: Scalars['String'];
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment?: { __typename?: 'CreateCommentSuccess', comment: { __typename?: 'Comment', id: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type AssignDonationReceiptMutationVariables = Exact<{
  id: Scalars['ID'];
  donationReceiptId: Scalars['ID'];
}>;


export type AssignDonationReceiptMutation = { __typename?: 'Mutation', assignDonationReceipt?: { __typename?: 'AssignDonationReceiptSuccess', campaign: { __typename?: 'Campaign', id: string, donationReceipt?: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, signatureUrl?: string | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreateDonationReceiptMutationVariables = Exact<{
  teamId: Scalars['ID'];
  mainContent: Scalars['String'];
  title: Scalars['String'];
  signatureName: Scalars['String'];
}>;


export type CreateDonationReceiptMutation = { __typename?: 'Mutation', createDonationReceipt?: { __typename?: 'CreateDonationReceiptSuccess', donationReceipt: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, signatureUrl?: string | null | undefined, default?: boolean | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteDonationReceiptMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDonationReceiptMutation = { __typename?: 'Mutation', deleteDonationReceipt?: { __typename?: 'DeleteDonationReceiptSuccess', donationReceipt: { __typename?: 'DonationReceipt', id: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteDonationReceiptSignatureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDonationReceiptSignatureMutation = { __typename?: 'Mutation', deleteDonationReceiptSignature?: { __typename?: 'DeleteDonationReceiptSignatureSuccess', donationReceipt: { __typename?: 'DonationReceipt', id: string, signatureUrl?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonationReceiptQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonationReceiptQuery = { __typename?: 'Query', donationReceipt?: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, signatureUrl?: string | null | undefined, default?: boolean | null | undefined, canDestroy: boolean } | null | undefined };

export type DonationReceiptsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type DonationReceiptsQuery = { __typename?: 'Query', donationReceipts: { __typename?: 'DonationReceiptConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'DonationReceiptEdge', node?: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, signatureUrl?: string | null | undefined, default?: boolean | null | undefined, canDestroy: boolean } | null | undefined } | null | undefined> | null | undefined } };

export type EditDonationReceiptMutationVariables = Exact<{
  id: Scalars['ID'];
  mainContent: Scalars['String'];
  title: Scalars['String'];
  signatureName: Scalars['String'];
}>;


export type EditDonationReceiptMutation = { __typename?: 'Mutation', editDonationReceipt?: { __typename?: 'EditDonationReceiptSuccess', donationReceipt: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, default?: boolean | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type SetDefaultDonationReceiptMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetDefaultDonationReceiptMutation = { __typename?: 'Mutation', setDefaultDonationReceipt?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'SetDefaultDonationReceiptSuccess', donationReceipt: { __typename?: 'DonationReceipt', id: string, mainContent?: string | null | undefined, title?: string | null | undefined, signatureName?: string | null | undefined, default?: boolean | null | undefined } } | null | undefined };

export type UploadDonationReceiptSignatureMutationVariables = Exact<{
  id: Scalars['ID'];
  signature: Scalars['Upload'];
}>;


export type UploadDonationReceiptSignatureMutation = { __typename?: 'Mutation', uploadDonationReceiptSignature?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'UploadDonationReceiptSignatureSuccess', donationReceipt: { __typename?: 'DonationReceipt', id: string, signatureUrl?: string | null | undefined } } | null | undefined };

export type DonationsAmountChartDataQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type DonationsAmountChartDataQuery = { __typename?: 'Query', donationsAmountChartData?: any | null | undefined };

export type DonationsCampaignsChartDataQueryVariables = Exact<{
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type DonationsCampaignsChartDataQuery = { __typename?: 'Query', donationsCampaignsChartData?: any | null | undefined };

export type ConfirmDonationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConfirmDonationMutation = { __typename?: 'Mutation', confirmDonation?: { __typename?: 'ConfirmDonationSuccess', donation: { __typename?: 'Donation', id: string, needsReviewAt?: any | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreateDonationMutationVariables = Exact<{
  amount: Scalars['String'];
  sentAt: Scalars['String'];
  donorId: Scalars['ID'];
  campaignId: Scalars['ID'];
  sendReceipt: Scalars['Boolean'];
  kind: Scalars['String'];
}>;


export type CreateDonationMutation = { __typename?: 'Mutation', createDonation?: { __typename?: 'CreateDonationSuccess', donation: { __typename?: 'Donation', id: string, amount: string, sentAt?: any | null | undefined, offline: boolean, receiptSent: boolean, kind?: string | null | undefined, campaign: { __typename?: 'Campaign', id: string, name: string }, donor: { __typename?: 'Donor', id: string, email?: string | null | undefined, name: string }, honoree?: { __typename?: 'Honoree', name: string, recipientName?: string | null | undefined, recipientEmail?: string | null | undefined, recipientMessage?: string | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteDonationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDonationMutation = { __typename?: 'Mutation', deleteDonation?: { __typename?: 'DeleteDonationSuccess', donation: { __typename?: 'Donation', id: string, campaign: { __typename?: 'Campaign', id: string } } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonationQuery = { __typename?: 'Query', donation?: { __typename?: 'Donation', id: string, amount: string, sentAt?: any | null | undefined, receiptSent: boolean, employerMatching?: boolean | null | undefined, kind?: string | null | undefined, kindHumanized?: string | null | undefined, refundedAt?: any | null | undefined, needsReviewAt?: any | null | undefined, offline: boolean, checkedInCount: number, stripeId?: string | null | undefined, totalTicketCount: number, employerMatchEmailSentAt?: any | null | undefined, campaign: { __typename?: 'Campaign', id: string, name: string }, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined }, honoree?: { __typename?: 'Honoree', name: string, recipientName?: string | null | undefined, recipientEmail?: string | null | undefined, recipientMessage?: string | null | undefined } | null | undefined, donorTickets?: Array<{ __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined, description?: string | null | undefined, id: string, price?: string | null | undefined, fairMarketValueAmount?: string | null | undefined } | null | undefined }> | null | undefined } | null | undefined };

export type DonationDownloadUrlQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonationDownloadUrlQuery = { __typename?: 'Query', donation?: { __typename?: 'Donation', id: string, receiptDownloadLink?: string | null | undefined } | null | undefined };

export type DonationsQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  needsReview?: InputMaybe<Scalars['Boolean']>;
}>;


export type DonationsQuery = { __typename?: 'Query', donations: { __typename?: 'DonationConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'DonationEdge', node?: { __typename?: 'Donation', id: string, amount: string, sentAt?: any | null | undefined, receiptSent: boolean, offline: boolean, kind?: string | null | undefined, kindHumanized?: string | null | undefined, employerMatching?: boolean | null | undefined, stripeId?: string | null | undefined, refundedAt?: any | null | undefined, needsReviewAt?: any | null | undefined, employerMatchEmailSentAt?: any | null | undefined, campaign: { __typename?: 'Campaign', id: string, name: string }, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined }, honoree?: { __typename?: 'Honoree', name: string, recipientName?: string | null | undefined, recipientEmail?: string | null | undefined, recipientMessage?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type DownloadDonationReceiptMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DownloadDonationReceiptMutation = { __typename?: 'Mutation', downloadDonationReceipt?: { __typename?: 'DownloadDonationReceiptSuccess', donation: { __typename?: 'Donation', id: string, receiptDownloadLink?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EditDonationMutationVariables = Exact<{
  id: Scalars['ID'];
  amount: Scalars['String'];
  sentAt: Scalars['String'];
  donorId: Scalars['ID'];
  campaignId: Scalars['ID'];
  kind: Scalars['String'];
}>;


export type EditDonationMutation = { __typename?: 'Mutation', editDonation?: { __typename?: 'EditDonationSuccess', donation: { __typename?: 'Donation', id: string, amount: string, sentAt?: any | null | undefined, offline: boolean, campaign: { __typename?: 'Campaign', id: string, name: string }, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined }, honoree?: { __typename?: 'Honoree', name: string, recipientName?: string | null | undefined, recipientEmail?: string | null | undefined, recipientMessage?: string | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type ExportDonationsMutationVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ExportDonationsMutation = { __typename?: 'Mutation', exportDonations?: boolean | null | undefined };

export type ManualDonationRefundMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ManualDonationRefundMutation = { __typename?: 'Mutation', manualDonationRefund?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'ManualDonationRefundSuccess', donation: { __typename?: 'Donation', id: string, refundedAt?: any | null | undefined } } | null | undefined };

export type RefundStripeDonationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefundStripeDonationMutation = { __typename?: 'Mutation', refundStripeDonation?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'RefundStripeDonationSuccess', donation: { __typename?: 'Donation', id: string, refundedAt?: any | null | undefined } } | null | undefined };

export type SendDonationReceiptMutationVariables = Exact<{
  id: Scalars['ID'];
  includePdf?: InputMaybe<Scalars['Boolean']>;
}>;


export type SendDonationReceiptMutation = { __typename?: 'Mutation', sendDonationReceipt?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'SendDonationReceiptSuccess', donation: { __typename?: 'Donation', id: string, receiptSent: boolean } } | null | undefined };

export type SendEmployerMatchEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  employerMatchUrl: Scalars['String'];
}>;


export type SendEmployerMatchEmailMutation = { __typename?: 'Mutation', sendEmployerMatchEmail?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'SendEmployerMatchEmailSuccess', donation: { __typename?: 'Donation', id: string, employerMatchEmailSentAt?: any | null | undefined } } | null | undefined };

export type CreateTicketOrderDonationMutationVariables = Exact<{
  amount: Scalars['String'];
  campaignId: Scalars['ID'];
  donorId: Scalars['ID'];
  sentAt?: InputMaybe<Scalars['String']>;
  sendEmail: Scalars['Boolean'];
  sendTaxPdf?: InputMaybe<Scalars['Boolean']>;
  donorTicket?: InputMaybe<Array<Scalars['JSON']> | Scalars['JSON']>;
}>;


export type CreateTicketOrderDonationMutation = { __typename?: 'Mutation', createTicketOrderDonation?: { __typename?: 'CreateTicketOrderDonationSuccess', donation: { __typename?: 'Donation', id: string, amount: string, sentAt?: any | null | undefined, totalTicketCount: number, checkedInCount: number, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined }, campaign: { __typename?: 'Campaign', id: string, name: string }, donorTickets?: Array<{ __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined, description?: string | null | undefined, id: string, price?: string | null | undefined } | null | undefined }> | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type TicketOrderDonationsQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TicketOrderDonationsQuery = { __typename?: 'Query', donations: { __typename?: 'DonationConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'DonationEdge', node?: { __typename?: 'Donation', id: string, amount: string, sentAt?: any | null | undefined, totalTicketCount: number, checkedInCount: number, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined }, campaign: { __typename?: 'Campaign', id: string, name: string } } | null | undefined } | null | undefined> | null | undefined } };

export type AddDonorTicketDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type AddDonorTicketDetailsMutation = { __typename?: 'Mutation', addDonorTicketDetails?: { __typename?: 'AddDonorTicketDetailsSuccess', donorTicket: { __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CheckInMutationVariables = Exact<{
  id: Scalars['ID'];
  checkedIn: Scalars['Boolean'];
}>;


export type CheckInMutation = { __typename?: 'Mutation', checkIn?: { __typename?: 'CheckInSuccess', donorTicket: { __typename?: 'DonorTicket', id: string, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, checkedInAt?: any | null | undefined, donation?: { __typename?: 'Donation', id: string, checkedInCount: number } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreateDonorTicketMutationVariables = Exact<{
  ticketId: Scalars['ID'];
  donationId: Scalars['ID'];
}>;


export type CreateDonorTicketMutation = { __typename?: 'Mutation', createDonorTicket?: { __typename?: 'CreateDonorTicketSuccess', donorTicket: { __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteDonorTicketMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDonorTicketMutation = { __typename?: 'Mutation', deleteDonorTicket?: { __typename?: 'DeleteDonorTicketSuccess', donorTicket: { __typename?: 'DonorTicket', id: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonorTicketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorTicketQuery = { __typename?: 'Query', donorTicket?: { __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined } | null | undefined };

export type DonorTicketByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type DonorTicketByTokenQuery = { __typename?: 'Query', donorTicketByToken?: { __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined, price?: string | null | undefined, id: string } | null | undefined, donation?: { __typename?: 'Donation', id: string, donorTickets?: Array<{ __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined, price?: string | null | undefined, id: string } | null | undefined }> | null | undefined } | null | undefined } | null | undefined };

export type DonorTicketsQueryVariables = Exact<{
  campaignIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  teamId: Scalars['ID'];
  donationId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type DonorTicketsQuery = { __typename?: 'Query', donorTickets: { __typename?: 'DonorTicketConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'DonorTicketEdge', node?: { __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined, description?: string | null | undefined, id: string, price?: string | null | undefined, campaign?: { __typename?: 'Campaign', id: string, name: string } | null | undefined } | null | undefined, donation?: { __typename?: 'Donation', id: string, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined } } | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type ExportDonorTicketsMutationVariables = Exact<{
  campaignIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type ExportDonorTicketsMutation = { __typename?: 'Mutation', exportDonorTickets?: boolean | null | undefined };

export type CreateDonorMutationVariables = Exact<{
  name: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  teamId: Scalars['ID'];
}>;


export type CreateDonorMutation = { __typename?: 'Mutation', createDonor?: { __typename?: 'CreateDonorSuccess', donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, donationCount?: number | null | undefined, donationTotal?: string | null | undefined, employerName?: string | null | undefined, status?: string | null | undefined, uniqueCampaigns?: Array<{ __typename?: 'Campaign', name: string, id: string }> | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteDonorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDonorMutation = { __typename?: 'Mutation', deleteDonor?: { __typename?: 'DeleteDonorSuccess', donor: { __typename?: 'Donor', id: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonationsDonorsQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type DonationsDonorsQuery = { __typename?: 'Query', donationsDonors?: any | null | undefined };

export type DonorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorQuery = { __typename?: 'Query', donor?: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, rfmScore?: number | null | undefined, zip?: string | null | undefined, employerName?: string | null | undefined, donationCount?: number | null | undefined, donationTotal?: string | null | undefined, canDestroy: boolean, status?: string | null | undefined, uniqueCampaigns?: Array<{ __typename?: 'Campaign', name: string, id: string }> | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } | null | undefined };

export type DonorsQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
}>;


export type DonorsQuery = { __typename?: 'Query', donors: { __typename?: 'DonorConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'DonorEdge', node?: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, rfmScore?: number | null | undefined, employerName?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, donationCount?: number | null | undefined, donationTotal?: string | null | undefined, status?: string | null | undefined, canDestroy: boolean, uniqueCampaigns?: Array<{ __typename?: 'Campaign', name: string, id: string }> | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type EditDonorMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type EditDonorMutation = { __typename?: 'Mutation', editDonor?: { __typename?: 'EditDonorSuccess', donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, donationCount?: number | null | undefined, donationTotal?: string | null | undefined, employerName?: string | null | undefined, status?: string | null | undefined, uniqueCampaigns?: Array<{ __typename?: 'Campaign', name: string, id: string }> | null | undefined, lastDonation?: { __typename?: 'Donation', id: string, sentAt?: any | null | undefined } | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EmailEventsQueryVariables = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
}>;


export type EmailEventsQuery = { __typename?: 'Query', emailEvents: { __typename?: 'EmailEventConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'EmailEventEdge', node?: { __typename?: 'EmailEvent', id: string, eventType?: string | null | undefined, timestamp?: any | null | undefined, status?: string | null | undefined, deliveredAt?: any | null | undefined, eventName?: string | null | undefined, attachments: Array<{ __typename?: 'ActiveStorageAttachment', id: string, url: string, filename: string }> } | null | undefined } | null | undefined> | null | undefined } };

export type CreateEventMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  header: Scalars['String'];
  body: Scalars['String'];
  sidebar?: InputMaybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'CreateEventSuccess', fundraiserContent: { __typename?: 'FundraiserContent', id: string, body: string, sidebar: any, header: string, metaDescription?: string | null | undefined, metaTitle?: string | null | undefined, showGoal?: boolean | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreateTicketPaymentIntentMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  tickets: Array<Scalars['JSON']> | Scalars['JSON'];
  coverFees?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateTicketPaymentIntentMutation = { __typename?: 'Mutation', createTicketPaymentIntent?: { __typename?: 'CreateTicketPaymentIntentSuccess', paymentIntent: { __typename?: 'PaymentIntent', paymentIntent: string, stripeAccountId: string, amount: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EditEventMutationVariables = Exact<{
  id: Scalars['ID'];
  header: Scalars['String'];
  body: Scalars['String'];
  sidebar?: InputMaybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
}>;


export type EditEventMutation = { __typename?: 'Mutation', editEvent?: { __typename?: 'EditEventSuccess', fundraiserContent: { __typename?: 'FundraiserContent', id: string, body: string, sidebar: any, header: string, metaDescription?: string | null | undefined, metaTitle?: string | null | undefined, showGoal?: boolean | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EventContentQueryVariables = Exact<{
  campaignId: Scalars['ID'];
}>;


export type EventContentQuery = { __typename?: 'Query', fundraiserContent?: { __typename?: 'FundraiserContent', id: string, body: string, header: string, sidebar: any, logoUrl?: string | null | undefined, publicImageUrl?: string | null | undefined, teamName: string, emailRequired: boolean, addressRequired: boolean, nameRequired: boolean, totalRaised?: string | null | undefined, fundraiserActive: boolean, goalCompletionPercentage?: number | null | undefined, goalAmount?: string | null | undefined, showGoal?: boolean | null | undefined, totalDonationCount?: string | null | undefined, metaDescription?: string | null | undefined, metaTitle?: string | null | undefined, timeInfo?: string | null | undefined, paymentsEnabled?: boolean | null | undefined, locationFullAddress?: string | null | undefined, defaultCampaignUrl?: string | null | undefined, googleAnalyticsId?: string | null | undefined, teamUrl?: string | null | undefined, teamId: string, fundraiserUrl?: string | null | undefined, purchaseableTickets: Array<{ __typename?: 'Ticket', id: string, quantity?: number | null | undefined, price?: string | null | undefined, name?: string | null | undefined, description?: string | null | undefined, attendeesPerTicket?: number | null | undefined, maximumAvailable?: number | null | undefined, maximumPerOrder?: number | null | undefined, endsAt?: any | null | undefined }> } | null | undefined };

export type CreateFundraiserMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  header: Scalars['String'];
  body: Scalars['String'];
  includeRecipient: Scalars['Boolean'];
  includeHonoree: Scalars['Boolean'];
  sidebar?: InputMaybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  allowPublicComments?: InputMaybe<Scalars['Boolean']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateFundraiserMutation = { __typename?: 'Mutation', createFundraiser?: { __typename?: 'CreateFundraiserSuccess', fundraiserContent: { __typename?: 'FundraiserContent', id: string, body: string, sidebar: any, header: string, includeHonoree: boolean, showGoal?: boolean | null | undefined, includeRecipient: boolean, metaDescription?: string | null | undefined, metaTitle?: string | null | undefined, allowPublicComments: boolean } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteFundraiserContentImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFundraiserContentImageMutation = { __typename?: 'Mutation', deleteFundraiserContentImage?: { __typename?: 'DeleteFundraiserContentImageSuccess', fundraiserContent: { __typename?: 'FundraiserContent', id: string, publicImageUrl?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EditFundraiserMutationVariables = Exact<{
  id: Scalars['ID'];
  header: Scalars['String'];
  body: Scalars['String'];
  includeRecipient: Scalars['Boolean'];
  includeHonoree: Scalars['Boolean'];
  sidebar?: InputMaybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  allowPublicComments?: InputMaybe<Scalars['Boolean']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  showGoal?: InputMaybe<Scalars['Boolean']>;
}>;


export type EditFundraiserMutation = { __typename?: 'Mutation', editFundraiser?: { __typename?: 'EditFundraiserSuccess', fundraiserContent: { __typename?: 'FundraiserContent', id: string, body: string, sidebar: any, header: string, includeHonoree: boolean, includeRecipient: boolean, metaDescription?: string | null | undefined, metaTitle?: string | null | undefined, allowPublicComments: boolean, showGoal?: boolean | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type FundraiserContentQueryVariables = Exact<{
  campaignId: Scalars['ID'];
}>;


export type FundraiserContentQuery = { __typename?: 'Query', fundraiserContent?: { __typename?: 'FundraiserContent', id: string, body: string, header: string, sidebar: any, fundraiserActive: boolean, includeRecipient: boolean, includeHonoree: boolean, logoUrl?: string | null | undefined, publicImageUrl?: string | null | undefined, teamName: string, paymentsEnabled?: boolean | null | undefined, teamUrl?: string | null | undefined, defaultCampaignUrl?: string | null | undefined, allowPublicComments: boolean, totalRaised?: string | null | undefined, goalCompletionPercentage?: number | null | undefined, goalAmount?: string | null | undefined, showGoal?: boolean | null | undefined, totalDonationCount?: string | null | undefined, fundraiserUrl?: string | null | undefined, pledgeUrl?: string | null | undefined, metaDescription?: string | null | undefined, metaTitle?: string | null | undefined, addressRequired: boolean, includeFees: boolean, includeEmployerMatch: boolean, includeRecurring: boolean, teamId: string, googleAnalyticsId?: string | null | undefined, purchaseableTickets: Array<{ __typename?: 'Ticket', id: string, quantity?: number | null | undefined, price?: string | null | undefined, name?: string | null | undefined, description?: string | null | undefined, attendeesPerTicket?: number | null | undefined, maximumAvailable?: number | null | undefined, maximumPerOrder?: number | null | undefined, endsAt?: any | null | undefined }> } | null | undefined };

export type UploadFundraiserContentImageMutationVariables = Exact<{
  id: Scalars['ID'];
  publicImage: Scalars['Upload'];
}>;


export type UploadFundraiserContentImageMutation = { __typename?: 'Mutation', uploadFundraiserContentImage?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'UploadFundraiserContentImageSuccess', fundraiserContent: { __typename?: 'FundraiserContent', id: string, publicImageUrl?: string | null | undefined } } | null | undefined };

export type CreatePaymentIntentMutationVariables = Exact<{
  amount: Scalars['String'];
  campaignId: Scalars['ID'];
  honoreeName?: InputMaybe<Scalars['String']>;
  honoreeKind?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  recipientMessage?: InputMaybe<Scalars['String']>;
  employerName?: InputMaybe<Scalars['String']>;
  coverFees?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreatePaymentIntentMutation = { __typename?: 'Mutation', createPaymentIntent?: { __typename?: 'CreatePaymentIntentSuccess', paymentIntent: { __typename?: 'PaymentIntent', paymentIntent: string, stripeAccountId: string, amount: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreateSubscriptionIntentMutationVariables = Exact<{
  amount: Scalars['String'];
  campaignId: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  interval: Scalars['String'];
  honoreeName?: InputMaybe<Scalars['String']>;
  honoreeKind?: InputMaybe<Scalars['String']>;
  recipientName?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  recipientMessage?: InputMaybe<Scalars['String']>;
  coverFees?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateSubscriptionIntentMutation = { __typename?: 'Mutation', createSubscriptionIntent?: { __typename?: 'CreateSubscriptionIntentSuccess', paymentIntent: { __typename?: 'PaymentIntent', paymentIntent: string, stripeAccountId: string, amount: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type PublicCommentsQueryVariables = Exact<{
  campaignId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type PublicCommentsQuery = { __typename?: 'Query', publicComments: { __typename?: 'PublicCommentConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'PublicCommentEdge', node?: { __typename?: 'PublicComment', id: string, message: string, name: string, amount: string, createdAt: any } | null | undefined } | null | undefined> | null | undefined } };

export type AddIntegrationToTeamMutationVariables = Exact<{
  integrationId: Scalars['ID'];
  apiKey: Scalars['String'];
  id: Scalars['ID'];
  apiFieldTwo?: InputMaybe<Scalars['String']>;
  apiFieldThree?: InputMaybe<Scalars['String']>;
}>;


export type AddIntegrationToTeamMutation = { __typename?: 'Mutation', addIntegrationToTeam?: { __typename?: 'AddIntegrationToTeamSuccess', team: { __typename?: 'Team', id: string, integrations?: Array<{ __typename?: 'Integration', id: string, name: string, description?: string | null | undefined }> | null | undefined, availableIntegrations?: Array<{ __typename?: 'Integration', id: string, name: string, description?: string | null | undefined }> | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteTeamIntegrationMutationVariables = Exact<{
  integrationId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type DeleteTeamIntegrationMutation = { __typename?: 'Mutation', deleteTeamIntegration?: { __typename?: 'DeleteTeamIntegrationSuccess', team: { __typename?: 'Team', id: string, integrations?: Array<{ __typename?: 'Integration', id: string, name: string, description?: string | null | undefined }> | null | undefined, availableIntegrations?: Array<{ __typename?: 'Integration', id: string, name: string, description?: string | null | undefined }> | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type IntegrationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IntegrationQuery = { __typename?: 'Query', integration?: { __typename?: 'Integration', id: string, name: string, description?: string | null | undefined, apiFieldTwoName?: string | null | undefined, apiFieldThreeName?: string | null | undefined } | null | undefined };

export type TeamIntegrationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamIntegrationsQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, integrations?: Array<{ __typename?: 'Integration', id: string, name: string, description?: string | null | undefined, apiFieldTwoName?: string | null | undefined, apiFieldThreeName?: string | null | undefined }> | null | undefined, availableIntegrations?: Array<{ __typename?: 'Integration', id: string, name: string, description?: string | null | undefined, apiFieldTwoName?: string | null | undefined, apiFieldThreeName?: string | null | undefined }> | null | undefined } | null | undefined };

export type ConnectAccountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConnectAccountQuery = { __typename?: 'Query', connectAccount?: string | null | undefined };

export type CreatePledgeMutationVariables = Exact<{
  amount: Scalars['String'];
  campaignId: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
}>;


export type CreatePledgeMutation = { __typename?: 'Mutation', createPledge?: { __typename?: 'CreatePledgeSuccess', donation: { __typename?: 'Donation', id: string, amount: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonorTicketsByDonationTokenQueryVariables = Exact<{
  token: Scalars['String'];
  campaignId: Scalars['ID'];
}>;


export type DonorTicketsByDonationTokenQuery = { __typename?: 'Query', donorTicketsByDonationToken: Array<{ __typename?: 'DonorTicket', id: string, token?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, checkedInAt?: any | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined } | null | undefined, donation?: { __typename?: 'Donation', donor: { __typename?: 'Donor', name: string } } | null | undefined }> };

export type ReassignDonorTicketMutationVariables = Exact<{
  token: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}>;


export type ReassignDonorTicketMutation = { __typename?: 'Mutation', reassignDonorTicket?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'ReassignDonorTicketSuccess', donorTicket: { __typename?: 'DonorTicket', id: string, token?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined } } | null | undefined };

export type TeamPortalSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamPortalSessionQuery = { __typename?: 'Query', teamPortalSession: any };

export type TeamSessionCheckoutQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamSessionCheckoutQuery = { __typename?: 'Query', teamSessionCheckout: any };

export type TeamSubscriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamSubscriptionQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, subscriptionStatus?: string | null | undefined } | null | undefined };

export type CancelSubscriptionMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription?: boolean | null | undefined };

export type CreateSubscriptionBillingPortalMutationVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type CreateSubscriptionBillingPortalMutation = { __typename?: 'Mutation', createSubscriptionBillingPortal?: string | null | undefined };

export type GetSubscriptionFromTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetSubscriptionFromTokenQuery = { __typename?: 'Query', getSubscriptionFromToken?: { __typename?: 'StripeSubscription', id: string, amount: string, canceledAt?: any | null | undefined, nextBillingOn?: any | null | undefined, interval?: string | null | undefined, donations: Array<{ __typename?: 'Donation', amount: string, id: string, sentAt?: any | null | undefined }> } | null | undefined };

export type StripeSubscriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StripeSubscriptionQuery = { __typename?: 'Query', stripeSubscription?: { __typename?: 'StripeSubscription', id: string, amount: string, canceledAt?: any | null | undefined, createdAt?: any | null | undefined, campaign: { __typename?: 'Campaign', id: string, name: string }, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined } } | null | undefined };

export type StripeSubscriptionsQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  donorId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type StripeSubscriptionsQuery = { __typename?: 'Query', stripeSubscriptions: { __typename?: 'StripeSubscriptionConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'StripeSubscriptionEdge', node?: { __typename?: 'StripeSubscription', id: string, amount: string, canceledAt?: any | null | undefined, createdAt?: any | null | undefined, nextBillingOn?: any | null | undefined, interval?: string | null | undefined, token?: string | null | undefined, campaign: { __typename?: 'Campaign', id: string, name: string }, donor: { __typename?: 'Donor', id: string, name: string, email?: string | null | undefined } } | null | undefined } | null | undefined> | null | undefined } };

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
  taxId: Scalars['String'];
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam?: { __typename?: 'CreateTeamSuccess', team: { __typename?: 'Team', id: string, name?: string | null | undefined, logoUrl?: string | null | undefined, email?: string | null | undefined, taxId?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, url?: string | null | undefined, zip?: string | null | undefined, totalRaised?: string | null | undefined, totalLastThirtyRaised?: string | null | undefined, totalYearRaised?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type EditTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  taxId: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  url: Scalars['String'];
}>;


export type EditTeamMutation = { __typename?: 'Mutation', editTeam?: { __typename?: 'EditTeamSuccess', team: { __typename?: 'Team', id: string, name?: string | null | undefined, logoUrl?: string | null | undefined, email?: string | null | undefined, taxId?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, totalRaised?: string | null | undefined, totalLastThirtyRaised?: string | null | undefined, totalYearRaised?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteTeamInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  teamId: Scalars['ID'];
}>;


export type DeleteTeamInvitationMutation = { __typename?: 'Mutation', deleteTeamInvitation?: { __typename?: 'DeleteTeamInvitationSuccess', team: { __typename?: 'Team', id: string, pendingTeamInvitations?: Array<{ __typename?: 'TeamInvitation', id: string, name?: string | null | undefined, email?: string | null | undefined }> | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type InviteUserToTeamMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['ID'];
}>;


export type InviteUserToTeamMutation = { __typename?: 'Mutation', inviteUserToTeam?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'InviteUserToTeamSuccess', team: { __typename?: 'Team', id: string, pendingTeamInvitations?: Array<{ __typename?: 'TeamInvitation', id: string, name?: string | null | undefined, email?: string | null | undefined }> | null | undefined } } | null | undefined };

export type TeamUsersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamUsersQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, teamUsers: Array<{ __typename?: 'TeamUser', id: string, user: { __typename?: 'User', id: string, name?: string | null | undefined, email: string, me: boolean } }>, pendingTeamInvitations?: Array<{ __typename?: 'TeamInvitation', id: string, name?: string | null | undefined, email?: string | null | undefined }> | null | undefined } | null | undefined };

export type TeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, name?: string | null | undefined, logoUrl?: string | null | undefined, url?: string | null | undefined, email?: string | null | undefined, taxId?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, totalRaised?: string | null | undefined, totalLastThirtyRaised?: string | null | undefined, totalYearRaised?: string | null | undefined, stripeAccountId?: string | null | undefined, canConnect?: boolean | null | undefined, stripeEnabledAt?: any | null | undefined, addressLine2?: string | null | undefined } | null | undefined };

export type TeamStatsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamStatsQuery = { __typename?: 'Query', team?: { __typename?: 'Team', id: string, totalRaised?: string | null | undefined, averageRaised?: string | null | undefined, averageDonations?: string | null | undefined, donationCount?: string | null | undefined, activeCampaignCount?: string | null | undefined } | null | undefined };

export type TeamsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type TeamsQuery = { __typename?: 'Query', teams: { __typename?: 'TeamConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'TeamEdge', node?: { __typename?: 'Team', id: string, name?: string | null | undefined, logoUrl?: string | null | undefined, url?: string | null | undefined, email?: string | null | undefined, taxId?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, totalRaised?: string | null | undefined, totalLastThirtyRaised?: string | null | undefined, totalYearRaised?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined } };

export type UpdateTeamLogoMutationVariables = Exact<{
  id: Scalars['ID'];
  logo: Scalars['Upload'];
}>;


export type UpdateTeamLogoMutation = { __typename?: 'Mutation', updateTeamLogo?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'UpdateTeamLogoSuccess', team: { __typename?: 'Team', id: string, name?: string | null | undefined, logoUrl?: string | null | undefined, email?: string | null | undefined, taxId?: string | null | undefined, address1?: string | null | undefined, address2?: string | null | undefined, city?: string | null | undefined, url?: string | null | undefined, state?: string | null | undefined, zip?: string | null | undefined, totalRaised?: string | null | undefined, totalLastThirtyRaised?: string | null | undefined, totalYearRaised?: string | null | undefined } } | null | undefined };

export type CampaignTicketsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CampaignTicketsQuery = { __typename?: 'Query', campaign?: { __typename?: 'Campaign', id: string, tickets?: Array<{ __typename?: 'Ticket', id: string, name?: string | null | undefined, quantity?: number | null | undefined, price?: string | null | undefined, description?: string | null | undefined, attendeesPerTicket?: number | null | undefined, maximumPerOrder?: number | null | undefined, maximumAvailable?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, fairMarketValueAmount?: string | null | undefined, purchasedCount?: number | null | undefined, canDestroy: boolean }> | null | undefined } | null | undefined };

export type CreateTicketMutationVariables = Exact<{
  name: Scalars['String'];
  quantity: Scalars['String'];
  price: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  attendeesPerTicket?: InputMaybe<Scalars['String']>;
  maximumPerOrder: Scalars['String'];
  startsAt: Scalars['String'];
  endsAt: Scalars['String'];
  fairMarketValueAmount: Scalars['String'];
  campaignId: Scalars['ID'];
}>;


export type CreateTicketMutation = { __typename?: 'Mutation', createTicket?: { __typename?: 'CreateTicketSuccess', ticket: { __typename?: 'Ticket', id: string, name?: string | null | undefined, quantity?: number | null | undefined, price?: string | null | undefined, description?: string | null | undefined, attendeesPerTicket?: number | null | undefined, maximumPerOrder?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, fairMarketValueAmount?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DeleteTicketMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTicketMutation = { __typename?: 'Mutation', deleteTicket?: { __typename?: 'DeleteTicketSuccess', ticket: { __typename?: 'Ticket', id: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type DonationTicketsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonationTicketsQuery = { __typename?: 'Query', donation?: { __typename?: 'Donation', id: string, amount: string, checkedInCount: number, totalTicketCount: number, donor: { __typename?: 'Donor', id: string }, donorTickets?: Array<{ __typename?: 'DonorTicket', id: string, checkedInAt?: any | null | undefined, comments?: string | null | undefined, name?: string | null | undefined, email?: string | null | undefined, ticket?: { __typename?: 'Ticket', name?: string | null | undefined, description?: string | null | undefined, id: string, price?: string | null | undefined } | null | undefined }> | null | undefined } | null | undefined };

export type EditTicketMutationVariables = Exact<{
  name: Scalars['String'];
  quantity: Scalars['String'];
  price: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  attendeesPerTicket?: InputMaybe<Scalars['String']>;
  maximumPerOrder: Scalars['String'];
  startsAt: Scalars['String'];
  endsAt: Scalars['String'];
  fairMarketValueAmount?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}>;


export type EditTicketMutation = { __typename?: 'Mutation', editTicket?: { __typename?: 'EditTicketSuccess', ticket: { __typename?: 'Ticket', id: string, name?: string | null | undefined, quantity?: number | null | undefined, price?: string | null | undefined, description?: string | null | undefined, attendeesPerTicket?: number | null | undefined, maximumPerOrder?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, fairMarketValueAmount?: string | null | undefined } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type ReorderTicketMutationVariables = Exact<{
  position: Scalars['Int'];
  id: Scalars['ID'];
}>;


export type ReorderTicketMutation = { __typename?: 'Mutation', reorderTicket?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'ReorderTicketSuccess', ticket: { __typename?: 'Ticket', id: string, position?: number | null | undefined } } | null | undefined };

export type SendTicketDonationReceiptsMutationVariables = Exact<{
  id: Scalars['ID'];
  includePdf: Scalars['Boolean'];
}>;


export type SendTicketDonationReceiptsMutation = { __typename?: 'Mutation', sendTicketDonationReceipts?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'SendTicketDonationReceiptsSuccess', ticket: { __typename?: 'Ticket', id: string } } | null | undefined };

export type TicketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TicketQuery = { __typename?: 'Query', ticket?: { __typename?: 'Ticket', id: string, name?: string | null | undefined, quantity?: number | null | undefined, price?: string | null | undefined, description?: string | null | undefined, attendeesPerTicket?: number | null | undefined, maximumPerOrder?: number | null | undefined, startsAt?: any | null | undefined, endsAt?: any | null | undefined, fairMarketValueAmount?: string | null | undefined, canDestroy: boolean } | null | undefined };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: boolean | null | undefined };

export type CreateSessionMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateSessionMutation = { __typename?: 'Mutation', createSession?: { __typename?: 'CreateSessionSuccess', token: string, user: { __typename?: 'User', id: string, email: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | null | undefined, email: string } | null | undefined };

export type UpdateUserPasswordFromTokenMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateUserPasswordFromTokenMutation = { __typename?: 'Mutation', updateUserPasswordFromToken?: { __typename?: 'Error', errors?: Array<any> | null | undefined } | { __typename?: 'UpdateUserPasswordFromTokenSuccess', token: string, user: { __typename?: 'User', id: string, email: string } } | null | undefined };

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  teamInvitationToken?: InputMaybe<Scalars['String']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'CreateUserSuccess', token: string, user: { __typename?: 'User', id: string, name?: string | null | undefined, email: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };

export type CreatePublicCommentMutationVariables = Exact<{
  message: Scalars['String'];
  displayDonorInfo: Scalars['Boolean'];
  displayAmount: Scalars['Boolean'];
  stripeId: Scalars['String'];
}>;


export type CreatePublicCommentMutation = { __typename?: 'Mutation', createPublicComment?: { __typename?: 'CreatePublicCommentSuccess', publicComment: { __typename?: 'PublicComment', id: string, message: string, redirectUrl: string } } | { __typename?: 'Error', errors?: Array<any> | null | undefined } | null | undefined };


export const CampaignDocument = gql`
    query campaign($id: ID!) {
  campaign(id: $id) {
    id
    name
    active
    archived
    description
    kind
    startsAt
    endsAt
    goalAmount
    totalRaised
    totalDonationCount
    sendTickets
    goalCompletionPercentage
    sendDonationReceipt
    canDestroy
    address1
    address2
    city
    state
    default
    zip
    publicName
    locationName
    timeInfo
    lastDonation {
      id
      sentAt
    }
    donationReceipt {
      id
      mainContent
      title
      signatureName
      default
    }
  }
}
    `;

/**
 * __useCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;
export const CampaignsDocument = gql`
    query campaigns($teamId: ID!, $first: Int, $after: String, $searchTerm: String, $sortBy: String, $archived: Boolean) {
  campaigns(
    teamId: $teamId
    first: $first
    after: $after
    searchTerm: $searchTerm
    sortBy: $sortBy
    archived: $archived
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        active
        archived
        description
        kind
        startsAt
        endsAt
        goalAmount
        totalRaised
        default
        totalDonationCount
        goalCompletionPercentage
        sendDonationReceipt
        address1
        address2
        city
        state
        zip
        sendTickets
        publicName
        locationName
        timeInfo
        lastDonation {
          id
          sentAt
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *      sortBy: // value for 'sortBy'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const CreateCampaignDocument = gql`
    mutation createCampaign($teamId: ID!, $name: String!, $description: String!, $startsAt: String, $endsAt: String, $kind: String!, $active: Boolean!, $goalAmount: String!, $address1: String, $address2: String, $city: String, $state: String, $zip: String, $timeInfo: String, $publicName: String!, $sendDonationReceipt: Boolean, $locationName: String, $sendTickets: Boolean) {
  createCampaign(
    input: {attributes: {teamId: $teamId, name: $name, description: $description, startsAt: $startsAt, endsAt: $endsAt, kind: $kind, active: $active, goalAmount: $goalAmount, sendDonationReceipt: $sendDonationReceipt, sendTickets: $sendTickets, address1: $address1, address2: $address2, city: $city, state: $state, zip: $zip, timeInfo: $timeInfo, publicName: $publicName, locationName: $locationName}}
  ) {
    ... on CreateCampaignSuccess {
      campaign {
        id
        name
        active
        archived
        description
        kind
        startsAt
        endsAt
        goalAmount
        totalRaised
        totalDonationCount
        goalCompletionPercentage
        sendDonationReceipt
        address1
        address2
        city
        state
        zip
        publicName
        sendTickets
        locationName
        timeInfo
        lastDonation {
          id
          sentAt
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      kind: // value for 'kind'
 *      active: // value for 'active'
 *      goalAmount: // value for 'goalAmount'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      timeInfo: // value for 'timeInfo'
 *      publicName: // value for 'publicName'
 *      sendDonationReceipt: // value for 'sendDonationReceipt'
 *      locationName: // value for 'locationName'
 *      sendTickets: // value for 'sendTickets'
 *   },
 * });
 */
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<CreateCampaignMutation, CreateCampaignMutationVariables>;
export const DeleteCampaignDocument = gql`
    mutation deleteCampaign($id: ID!) {
  deleteCampaign(input: {attributes: {id: $id}}) {
    ... on DeleteCampaignSuccess {
      campaign {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<DeleteCampaignMutation, DeleteCampaignMutationVariables>;

/**
 * __useDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMutation, { data, loading, error }] = useDeleteCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignMutation, DeleteCampaignMutationVariables>(DeleteCampaignDocument, options);
      }
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<DeleteCampaignMutation>;
export type DeleteCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>;
export const DonationsCampaignsDocument = gql`
    query donationsCampaigns($teamId: ID!, $donorId: ID, $kind: [String!], $searchTerm: String, $startDate: String, $endDate: String) {
  donationsCampaigns(
    teamId: $teamId
    kind: $kind
    donorId: $donorId
    searchTerm: $searchTerm
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useDonationsCampaignsQuery__
 *
 * To run a query within a React component, call `useDonationsCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsCampaignsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      kind: // value for 'kind'
 *      searchTerm: // value for 'searchTerm'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDonationsCampaignsQuery(baseOptions: Apollo.QueryHookOptions<DonationsCampaignsQuery, DonationsCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationsCampaignsQuery, DonationsCampaignsQueryVariables>(DonationsCampaignsDocument, options);
      }
export function useDonationsCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationsCampaignsQuery, DonationsCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationsCampaignsQuery, DonationsCampaignsQueryVariables>(DonationsCampaignsDocument, options);
        }
export type DonationsCampaignsQueryHookResult = ReturnType<typeof useDonationsCampaignsQuery>;
export type DonationsCampaignsLazyQueryHookResult = ReturnType<typeof useDonationsCampaignsLazyQuery>;
export type DonationsCampaignsQueryResult = Apollo.QueryResult<DonationsCampaignsQuery, DonationsCampaignsQueryVariables>;
export const EditCampaignDocument = gql`
    mutation editCampaign($id: ID!, $name: String, $description: String, $startsAt: String, $endsAt: String, $kind: String, $active: Boolean, $archived: Boolean, $goalAmount: String, $address1: String, $address2: String, $city: String, $state: String, $zip: String, $timeInfo: String, $publicName: String, $sendDonationReceipt: Boolean, $sendTickets: Boolean, $locationName: String, $default: Boolean) {
  editCampaign(
    input: {attributes: {id: $id, name: $name, description: $description, startsAt: $startsAt, endsAt: $endsAt, kind: $kind, active: $active, archived: $archived, goalAmount: $goalAmount, sendDonationReceipt: $sendDonationReceipt, address1: $address1, address2: $address2, city: $city, state: $state, zip: $zip, timeInfo: $timeInfo, sendTickets: $sendTickets, publicName: $publicName, locationName: $locationName, default: $default}}
  ) {
    ... on EditCampaignSuccess {
      campaign {
        id
        name
        active
        archived
        description
        kind
        startsAt
        endsAt
        goalAmount
        totalRaised
        totalDonationCount
        goalCompletionPercentage
        sendDonationReceipt
        address1
        address2
        city
        state
        zip
        publicName
        default
        sendTickets
        locationName
        timeInfo
        lastDonation {
          id
          sentAt
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditCampaignMutationFn = Apollo.MutationFunction<EditCampaignMutation, EditCampaignMutationVariables>;

/**
 * __useEditCampaignMutation__
 *
 * To run a mutation, you first call `useEditCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCampaignMutation, { data, loading, error }] = useEditCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      kind: // value for 'kind'
 *      active: // value for 'active'
 *      archived: // value for 'archived'
 *      goalAmount: // value for 'goalAmount'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      timeInfo: // value for 'timeInfo'
 *      publicName: // value for 'publicName'
 *      sendDonationReceipt: // value for 'sendDonationReceipt'
 *      sendTickets: // value for 'sendTickets'
 *      locationName: // value for 'locationName'
 *      default: // value for 'default'
 *   },
 * });
 */
export function useEditCampaignMutation(baseOptions?: Apollo.MutationHookOptions<EditCampaignMutation, EditCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCampaignMutation, EditCampaignMutationVariables>(EditCampaignDocument, options);
      }
export type EditCampaignMutationHookResult = ReturnType<typeof useEditCampaignMutation>;
export type EditCampaignMutationResult = Apollo.MutationResult<EditCampaignMutation>;
export type EditCampaignMutationOptions = Apollo.BaseMutationOptions<EditCampaignMutation, EditCampaignMutationVariables>;
export const CreateEmailDocument = gql`
    mutation createEmail($campaignId: ID!, $content: String!, $subject: String!) {
  createEmail(
    input: {attributes: {campaignId: $campaignId, content: $content, subject: $subject}}
  ) {
    ... on CreateEmailSuccess {
      email {
        id
        subject
        content
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateEmailMutationFn = Apollo.MutationFunction<CreateEmailMutation, CreateEmailMutationVariables>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      content: // value for 'content'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useCreateEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailMutation, CreateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailMutation, CreateEmailMutationVariables>(CreateEmailDocument, options);
      }
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = Apollo.MutationResult<CreateEmailMutation>;
export type CreateEmailMutationOptions = Apollo.BaseMutationOptions<CreateEmailMutation, CreateEmailMutationVariables>;
export const EditEmailDocument = gql`
    mutation editEmail($id: ID!, $content: String!, $subject: String!) {
  editEmail(input: {attributes: {id: $id, content: $content, subject: $subject}}) {
    ... on EditEmailSuccess {
      email {
        id
        subject
        content
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditEmailMutationFn = Apollo.MutationFunction<EditEmailMutation, EditEmailMutationVariables>;

/**
 * __useEditEmailMutation__
 *
 * To run a mutation, you first call `useEditEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmailMutation, { data, loading, error }] = useEditEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useEditEmailMutation(baseOptions?: Apollo.MutationHookOptions<EditEmailMutation, EditEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEmailMutation, EditEmailMutationVariables>(EditEmailDocument, options);
      }
export type EditEmailMutationHookResult = ReturnType<typeof useEditEmailMutation>;
export type EditEmailMutationResult = Apollo.MutationResult<EditEmailMutation>;
export type EditEmailMutationOptions = Apollo.BaseMutationOptions<EditEmailMutation, EditEmailMutationVariables>;
export const EmailDocument = gql`
    query email($id: ID!) {
  email(id: $id) {
    id
    subject
    content
    sentCount
    sentAt
    emailEvents {
      id
      eventable {
        ... on Donation {
          id
          donor {
            id
            email
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEmailQuery__
 *
 * To run a query within a React component, call `useEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailQuery(baseOptions: Apollo.QueryHookOptions<EmailQuery, EmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailQuery, EmailQueryVariables>(EmailDocument, options);
      }
export function useEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailQuery, EmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailQuery, EmailQueryVariables>(EmailDocument, options);
        }
export type EmailQueryHookResult = ReturnType<typeof useEmailQuery>;
export type EmailLazyQueryHookResult = ReturnType<typeof useEmailLazyQuery>;
export type EmailQueryResult = Apollo.QueryResult<EmailQuery, EmailQueryVariables>;
export const EmailsDocument = gql`
    query emails($campaignId: ID!) {
  emails(campaignId: $campaignId) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        subject
        content
        sentCount
        sentAt
      }
    }
  }
}
    `;

/**
 * __useEmailsQuery__
 *
 * To run a query within a React component, call `useEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useEmailsQuery(baseOptions: Apollo.QueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, options);
      }
export function useEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailsQuery, EmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailsQuery, EmailsQueryVariables>(EmailsDocument, options);
        }
export type EmailsQueryHookResult = ReturnType<typeof useEmailsQuery>;
export type EmailsLazyQueryHookResult = ReturnType<typeof useEmailsLazyQuery>;
export type EmailsQueryResult = Apollo.QueryResult<EmailsQuery, EmailsQueryVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($id: ID!, $sendTest: Boolean) {
  sendEmail(input: {attributes: {id: $id, sendTest: $sendTest}}) {
    ... on SendEmailSuccess {
      email {
        id
        subject
        content
        sentAt
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendTest: // value for 'sendTest'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const CommentsDocument = gql`
    query comments($id: ID!, $type: String!) {
  comments(id: $id, type: $type) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        comment
        createdAt
        user {
          id
          name
          email
        }
      }
    }
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($id: ID!, $type: String!, $comment: String!) {
  createComment(input: {attributes: {id: $id, type: $type, comment: $comment}}) {
    ... on CreateCommentSuccess {
      comment {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const AssignDonationReceiptDocument = gql`
    mutation assignDonationReceipt($id: ID!, $donationReceiptId: ID!) {
  assignDonationReceipt(
    input: {attributes: {id: $id, donationReceiptId: $donationReceiptId}}
  ) {
    ... on AssignDonationReceiptSuccess {
      campaign {
        id
        donationReceipt {
          id
          mainContent
          title
          signatureName
          signatureUrl
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type AssignDonationReceiptMutationFn = Apollo.MutationFunction<AssignDonationReceiptMutation, AssignDonationReceiptMutationVariables>;

/**
 * __useAssignDonationReceiptMutation__
 *
 * To run a mutation, you first call `useAssignDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDonationReceiptMutation, { data, loading, error }] = useAssignDonationReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      donationReceiptId: // value for 'donationReceiptId'
 *   },
 * });
 */
export function useAssignDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<AssignDonationReceiptMutation, AssignDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignDonationReceiptMutation, AssignDonationReceiptMutationVariables>(AssignDonationReceiptDocument, options);
      }
export type AssignDonationReceiptMutationHookResult = ReturnType<typeof useAssignDonationReceiptMutation>;
export type AssignDonationReceiptMutationResult = Apollo.MutationResult<AssignDonationReceiptMutation>;
export type AssignDonationReceiptMutationOptions = Apollo.BaseMutationOptions<AssignDonationReceiptMutation, AssignDonationReceiptMutationVariables>;
export const CreateDonationReceiptDocument = gql`
    mutation createDonationReceipt($teamId: ID!, $mainContent: String!, $title: String!, $signatureName: String!) {
  createDonationReceipt(
    input: {attributes: {teamId: $teamId, mainContent: $mainContent, title: $title, signatureName: $signatureName}}
  ) {
    ... on CreateDonationReceiptSuccess {
      donationReceipt {
        id
        mainContent
        title
        signatureName
        signatureUrl
        default
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateDonationReceiptMutationFn = Apollo.MutationFunction<CreateDonationReceiptMutation, CreateDonationReceiptMutationVariables>;

/**
 * __useCreateDonationReceiptMutation__
 *
 * To run a mutation, you first call `useCreateDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonationReceiptMutation, { data, loading, error }] = useCreateDonationReceiptMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      mainContent: // value for 'mainContent'
 *      title: // value for 'title'
 *      signatureName: // value for 'signatureName'
 *   },
 * });
 */
export function useCreateDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateDonationReceiptMutation, CreateDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDonationReceiptMutation, CreateDonationReceiptMutationVariables>(CreateDonationReceiptDocument, options);
      }
export type CreateDonationReceiptMutationHookResult = ReturnType<typeof useCreateDonationReceiptMutation>;
export type CreateDonationReceiptMutationResult = Apollo.MutationResult<CreateDonationReceiptMutation>;
export type CreateDonationReceiptMutationOptions = Apollo.BaseMutationOptions<CreateDonationReceiptMutation, CreateDonationReceiptMutationVariables>;
export const DeleteDonationReceiptDocument = gql`
    mutation deleteDonationReceipt($id: ID!) {
  deleteDonationReceipt(input: {attributes: {id: $id}}) {
    ... on DeleteDonationReceiptSuccess {
      donationReceipt {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteDonationReceiptMutationFn = Apollo.MutationFunction<DeleteDonationReceiptMutation, DeleteDonationReceiptMutationVariables>;

/**
 * __useDeleteDonationReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonationReceiptMutation, { data, loading, error }] = useDeleteDonationReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDonationReceiptMutation, DeleteDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDonationReceiptMutation, DeleteDonationReceiptMutationVariables>(DeleteDonationReceiptDocument, options);
      }
export type DeleteDonationReceiptMutationHookResult = ReturnType<typeof useDeleteDonationReceiptMutation>;
export type DeleteDonationReceiptMutationResult = Apollo.MutationResult<DeleteDonationReceiptMutation>;
export type DeleteDonationReceiptMutationOptions = Apollo.BaseMutationOptions<DeleteDonationReceiptMutation, DeleteDonationReceiptMutationVariables>;
export const DeleteDonationReceiptSignatureDocument = gql`
    mutation deleteDonationReceiptSignature($id: ID!) {
  deleteDonationReceiptSignature(input: {attributes: {id: $id}}) {
    ... on DeleteDonationReceiptSignatureSuccess {
      donationReceipt {
        id
        signatureUrl
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteDonationReceiptSignatureMutationFn = Apollo.MutationFunction<DeleteDonationReceiptSignatureMutation, DeleteDonationReceiptSignatureMutationVariables>;

/**
 * __useDeleteDonationReceiptSignatureMutation__
 *
 * To run a mutation, you first call `useDeleteDonationReceiptSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonationReceiptSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonationReceiptSignatureMutation, { data, loading, error }] = useDeleteDonationReceiptSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonationReceiptSignatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDonationReceiptSignatureMutation, DeleteDonationReceiptSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDonationReceiptSignatureMutation, DeleteDonationReceiptSignatureMutationVariables>(DeleteDonationReceiptSignatureDocument, options);
      }
export type DeleteDonationReceiptSignatureMutationHookResult = ReturnType<typeof useDeleteDonationReceiptSignatureMutation>;
export type DeleteDonationReceiptSignatureMutationResult = Apollo.MutationResult<DeleteDonationReceiptSignatureMutation>;
export type DeleteDonationReceiptSignatureMutationOptions = Apollo.BaseMutationOptions<DeleteDonationReceiptSignatureMutation, DeleteDonationReceiptSignatureMutationVariables>;
export const DonationReceiptDocument = gql`
    query donationReceipt($id: ID!) {
  donationReceipt(id: $id) {
    id
    mainContent
    title
    signatureName
    signatureUrl
    default
    canDestroy
  }
}
    `;

/**
 * __useDonationReceiptQuery__
 *
 * To run a query within a React component, call `useDonationReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationReceiptQuery(baseOptions: Apollo.QueryHookOptions<DonationReceiptQuery, DonationReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationReceiptQuery, DonationReceiptQueryVariables>(DonationReceiptDocument, options);
      }
export function useDonationReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationReceiptQuery, DonationReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationReceiptQuery, DonationReceiptQueryVariables>(DonationReceiptDocument, options);
        }
export type DonationReceiptQueryHookResult = ReturnType<typeof useDonationReceiptQuery>;
export type DonationReceiptLazyQueryHookResult = ReturnType<typeof useDonationReceiptLazyQuery>;
export type DonationReceiptQueryResult = Apollo.QueryResult<DonationReceiptQuery, DonationReceiptQueryVariables>;
export const DonationReceiptsDocument = gql`
    query donationReceipts($teamId: ID!, $first: Int, $after: String, $searchTerm: String) {
  donationReceipts(
    teamId: $teamId
    first: $first
    after: $after
    searchTerm: $searchTerm
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        mainContent
        title
        signatureName
        signatureUrl
        default
        canDestroy
      }
    }
  }
}
    `;

/**
 * __useDonationReceiptsQuery__
 *
 * To run a query within a React component, call `useDonationReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationReceiptsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDonationReceiptsQuery(baseOptions: Apollo.QueryHookOptions<DonationReceiptsQuery, DonationReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationReceiptsQuery, DonationReceiptsQueryVariables>(DonationReceiptsDocument, options);
      }
export function useDonationReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationReceiptsQuery, DonationReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationReceiptsQuery, DonationReceiptsQueryVariables>(DonationReceiptsDocument, options);
        }
export type DonationReceiptsQueryHookResult = ReturnType<typeof useDonationReceiptsQuery>;
export type DonationReceiptsLazyQueryHookResult = ReturnType<typeof useDonationReceiptsLazyQuery>;
export type DonationReceiptsQueryResult = Apollo.QueryResult<DonationReceiptsQuery, DonationReceiptsQueryVariables>;
export const EditDonationReceiptDocument = gql`
    mutation editDonationReceipt($id: ID!, $mainContent: String!, $title: String!, $signatureName: String!) {
  editDonationReceipt(
    input: {attributes: {id: $id, mainContent: $mainContent, title: $title, signatureName: $signatureName}}
  ) {
    ... on EditDonationReceiptSuccess {
      donationReceipt {
        id
        mainContent
        title
        signatureName
        default
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditDonationReceiptMutationFn = Apollo.MutationFunction<EditDonationReceiptMutation, EditDonationReceiptMutationVariables>;

/**
 * __useEditDonationReceiptMutation__
 *
 * To run a mutation, you first call `useEditDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDonationReceiptMutation, { data, loading, error }] = useEditDonationReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mainContent: // value for 'mainContent'
 *      title: // value for 'title'
 *      signatureName: // value for 'signatureName'
 *   },
 * });
 */
export function useEditDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<EditDonationReceiptMutation, EditDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDonationReceiptMutation, EditDonationReceiptMutationVariables>(EditDonationReceiptDocument, options);
      }
export type EditDonationReceiptMutationHookResult = ReturnType<typeof useEditDonationReceiptMutation>;
export type EditDonationReceiptMutationResult = Apollo.MutationResult<EditDonationReceiptMutation>;
export type EditDonationReceiptMutationOptions = Apollo.BaseMutationOptions<EditDonationReceiptMutation, EditDonationReceiptMutationVariables>;
export const SetDefaultDonationReceiptDocument = gql`
    mutation setDefaultDonationReceipt($id: ID!) {
  setDefaultDonationReceipt(input: {attributes: {id: $id}}) {
    ... on SetDefaultDonationReceiptSuccess {
      donationReceipt {
        id
        mainContent
        title
        signatureName
        default
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type SetDefaultDonationReceiptMutationFn = Apollo.MutationFunction<SetDefaultDonationReceiptMutation, SetDefaultDonationReceiptMutationVariables>;

/**
 * __useSetDefaultDonationReceiptMutation__
 *
 * To run a mutation, you first call `useSetDefaultDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultDonationReceiptMutation, { data, loading, error }] = useSetDefaultDonationReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDefaultDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultDonationReceiptMutation, SetDefaultDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultDonationReceiptMutation, SetDefaultDonationReceiptMutationVariables>(SetDefaultDonationReceiptDocument, options);
      }
export type SetDefaultDonationReceiptMutationHookResult = ReturnType<typeof useSetDefaultDonationReceiptMutation>;
export type SetDefaultDonationReceiptMutationResult = Apollo.MutationResult<SetDefaultDonationReceiptMutation>;
export type SetDefaultDonationReceiptMutationOptions = Apollo.BaseMutationOptions<SetDefaultDonationReceiptMutation, SetDefaultDonationReceiptMutationVariables>;
export const UploadDonationReceiptSignatureDocument = gql`
    mutation uploadDonationReceiptSignature($id: ID!, $signature: Upload!) {
  uploadDonationReceiptSignature(
    input: {attributes: {id: $id, signature: $signature}}
  ) {
    ... on UploadDonationReceiptSignatureSuccess {
      donationReceipt {
        id
        signatureUrl
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type UploadDonationReceiptSignatureMutationFn = Apollo.MutationFunction<UploadDonationReceiptSignatureMutation, UploadDonationReceiptSignatureMutationVariables>;

/**
 * __useUploadDonationReceiptSignatureMutation__
 *
 * To run a mutation, you first call `useUploadDonationReceiptSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDonationReceiptSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDonationReceiptSignatureMutation, { data, loading, error }] = useUploadDonationReceiptSignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useUploadDonationReceiptSignatureMutation(baseOptions?: Apollo.MutationHookOptions<UploadDonationReceiptSignatureMutation, UploadDonationReceiptSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDonationReceiptSignatureMutation, UploadDonationReceiptSignatureMutationVariables>(UploadDonationReceiptSignatureDocument, options);
      }
export type UploadDonationReceiptSignatureMutationHookResult = ReturnType<typeof useUploadDonationReceiptSignatureMutation>;
export type UploadDonationReceiptSignatureMutationResult = Apollo.MutationResult<UploadDonationReceiptSignatureMutation>;
export type UploadDonationReceiptSignatureMutationOptions = Apollo.BaseMutationOptions<UploadDonationReceiptSignatureMutation, UploadDonationReceiptSignatureMutationVariables>;
export const DonationsAmountChartDataDocument = gql`
    query donationsAmountChartData($campaignId: ID, $teamId: ID!, $donorId: ID, $kind: [String!], $searchTerm: String, $startDate: String, $endDate: String) {
  donationsAmountChartData(
    campaignId: $campaignId
    teamId: $teamId
    kind: $kind
    donorId: $donorId
    searchTerm: $searchTerm
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useDonationsAmountChartDataQuery__
 *
 * To run a query within a React component, call `useDonationsAmountChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsAmountChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsAmountChartDataQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      kind: // value for 'kind'
 *      searchTerm: // value for 'searchTerm'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDonationsAmountChartDataQuery(baseOptions: Apollo.QueryHookOptions<DonationsAmountChartDataQuery, DonationsAmountChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationsAmountChartDataQuery, DonationsAmountChartDataQueryVariables>(DonationsAmountChartDataDocument, options);
      }
export function useDonationsAmountChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationsAmountChartDataQuery, DonationsAmountChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationsAmountChartDataQuery, DonationsAmountChartDataQueryVariables>(DonationsAmountChartDataDocument, options);
        }
export type DonationsAmountChartDataQueryHookResult = ReturnType<typeof useDonationsAmountChartDataQuery>;
export type DonationsAmountChartDataLazyQueryHookResult = ReturnType<typeof useDonationsAmountChartDataLazyQuery>;
export type DonationsAmountChartDataQueryResult = Apollo.QueryResult<DonationsAmountChartDataQuery, DonationsAmountChartDataQueryVariables>;
export const DonationsCampaignsChartDataDocument = gql`
    query donationsCampaignsChartData($teamId: ID!, $donorId: ID, $kind: [String!], $searchTerm: String, $startDate: String, $endDate: String) {
  donationsCampaignsChartData(
    teamId: $teamId
    kind: $kind
    donorId: $donorId
    searchTerm: $searchTerm
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useDonationsCampaignsChartDataQuery__
 *
 * To run a query within a React component, call `useDonationsCampaignsChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsCampaignsChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsCampaignsChartDataQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      kind: // value for 'kind'
 *      searchTerm: // value for 'searchTerm'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDonationsCampaignsChartDataQuery(baseOptions: Apollo.QueryHookOptions<DonationsCampaignsChartDataQuery, DonationsCampaignsChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationsCampaignsChartDataQuery, DonationsCampaignsChartDataQueryVariables>(DonationsCampaignsChartDataDocument, options);
      }
export function useDonationsCampaignsChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationsCampaignsChartDataQuery, DonationsCampaignsChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationsCampaignsChartDataQuery, DonationsCampaignsChartDataQueryVariables>(DonationsCampaignsChartDataDocument, options);
        }
export type DonationsCampaignsChartDataQueryHookResult = ReturnType<typeof useDonationsCampaignsChartDataQuery>;
export type DonationsCampaignsChartDataLazyQueryHookResult = ReturnType<typeof useDonationsCampaignsChartDataLazyQuery>;
export type DonationsCampaignsChartDataQueryResult = Apollo.QueryResult<DonationsCampaignsChartDataQuery, DonationsCampaignsChartDataQueryVariables>;
export const ConfirmDonationDocument = gql`
    mutation confirmDonation($id: ID!) {
  confirmDonation(input: {attributes: {id: $id}}) {
    ... on ConfirmDonationSuccess {
      donation {
        id
        needsReviewAt
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type ConfirmDonationMutationFn = Apollo.MutationFunction<ConfirmDonationMutation, ConfirmDonationMutationVariables>;

/**
 * __useConfirmDonationMutation__
 *
 * To run a mutation, you first call `useConfirmDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDonationMutation, { data, loading, error }] = useConfirmDonationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmDonationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmDonationMutation, ConfirmDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmDonationMutation, ConfirmDonationMutationVariables>(ConfirmDonationDocument, options);
      }
export type ConfirmDonationMutationHookResult = ReturnType<typeof useConfirmDonationMutation>;
export type ConfirmDonationMutationResult = Apollo.MutationResult<ConfirmDonationMutation>;
export type ConfirmDonationMutationOptions = Apollo.BaseMutationOptions<ConfirmDonationMutation, ConfirmDonationMutationVariables>;
export const CreateDonationDocument = gql`
    mutation createDonation($amount: String!, $sentAt: String!, $donorId: ID!, $campaignId: ID!, $sendReceipt: Boolean!, $kind: String!) {
  createDonation(
    input: {attributes: {amount: $amount, sentAt: $sentAt, donorId: $donorId, campaignId: $campaignId, sendReceipt: $sendReceipt, kind: $kind}}
  ) {
    ... on CreateDonationSuccess {
      donation {
        id
        amount
        sentAt
        offline
        receiptSent
        kind
        campaign {
          id
          name
        }
        donor {
          id
          email
          name
        }
        honoree {
          name
          recipientName
          recipientEmail
          recipientMessage
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateDonationMutationFn = Apollo.MutationFunction<CreateDonationMutation, CreateDonationMutationVariables>;

/**
 * __useCreateDonationMutation__
 *
 * To run a mutation, you first call `useCreateDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonationMutation, { data, loading, error }] = useCreateDonationMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      sentAt: // value for 'sentAt'
 *      donorId: // value for 'donorId'
 *      campaignId: // value for 'campaignId'
 *      sendReceipt: // value for 'sendReceipt'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCreateDonationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDonationMutation, CreateDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDonationMutation, CreateDonationMutationVariables>(CreateDonationDocument, options);
      }
export type CreateDonationMutationHookResult = ReturnType<typeof useCreateDonationMutation>;
export type CreateDonationMutationResult = Apollo.MutationResult<CreateDonationMutation>;
export type CreateDonationMutationOptions = Apollo.BaseMutationOptions<CreateDonationMutation, CreateDonationMutationVariables>;
export const DeleteDonationDocument = gql`
    mutation deleteDonation($id: ID!) {
  deleteDonation(input: {attributes: {id: $id}}) {
    ... on DeleteDonationSuccess {
      donation {
        id
        campaign {
          id
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteDonationMutationFn = Apollo.MutationFunction<DeleteDonationMutation, DeleteDonationMutationVariables>;

/**
 * __useDeleteDonationMutation__
 *
 * To run a mutation, you first call `useDeleteDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonationMutation, { data, loading, error }] = useDeleteDonationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDonationMutation, DeleteDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDonationMutation, DeleteDonationMutationVariables>(DeleteDonationDocument, options);
      }
export type DeleteDonationMutationHookResult = ReturnType<typeof useDeleteDonationMutation>;
export type DeleteDonationMutationResult = Apollo.MutationResult<DeleteDonationMutation>;
export type DeleteDonationMutationOptions = Apollo.BaseMutationOptions<DeleteDonationMutation, DeleteDonationMutationVariables>;
export const DonationDocument = gql`
    query donation($id: ID!) {
  donation(id: $id) {
    id
    amount
    sentAt
    receiptSent
    employerMatching
    kind
    kindHumanized
    refundedAt
    needsReviewAt
    campaign {
      id
      name
    }
    offline
    donor {
      id
      name
      email
    }
    honoree {
      name
      recipientName
      recipientEmail
      recipientMessage
    }
    checkedInCount
    stripeId
    totalTicketCount
    employerMatchEmailSentAt
    donorTickets {
      id
      checkedInAt
      comments
      name
      email
      ticket {
        name
        description
        id
        price
        fairMarketValueAmount
      }
    }
  }
}
    `;

/**
 * __useDonationQuery__
 *
 * To run a query within a React component, call `useDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationQuery(baseOptions: Apollo.QueryHookOptions<DonationQuery, DonationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationQuery, DonationQueryVariables>(DonationDocument, options);
      }
export function useDonationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationQuery, DonationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationQuery, DonationQueryVariables>(DonationDocument, options);
        }
export type DonationQueryHookResult = ReturnType<typeof useDonationQuery>;
export type DonationLazyQueryHookResult = ReturnType<typeof useDonationLazyQuery>;
export type DonationQueryResult = Apollo.QueryResult<DonationQuery, DonationQueryVariables>;
export const DonationDownloadUrlDocument = gql`
    query donationDownloadUrl($id: ID!) {
  donation(id: $id) {
    id
    receiptDownloadLink
  }
}
    `;

/**
 * __useDonationDownloadUrlQuery__
 *
 * To run a query within a React component, call `useDonationDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationDownloadUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<DonationDownloadUrlQuery, DonationDownloadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationDownloadUrlQuery, DonationDownloadUrlQueryVariables>(DonationDownloadUrlDocument, options);
      }
export function useDonationDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationDownloadUrlQuery, DonationDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationDownloadUrlQuery, DonationDownloadUrlQueryVariables>(DonationDownloadUrlDocument, options);
        }
export type DonationDownloadUrlQueryHookResult = ReturnType<typeof useDonationDownloadUrlQuery>;
export type DonationDownloadUrlLazyQueryHookResult = ReturnType<typeof useDonationDownloadUrlLazyQuery>;
export type DonationDownloadUrlQueryResult = Apollo.QueryResult<DonationDownloadUrlQuery, DonationDownloadUrlQueryVariables>;
export const DonationsDocument = gql`
    query donations($campaignId: ID, $teamId: ID!, $donorId: ID, $kind: [String!], $first: Int, $after: String, $searchTerm: String, $needsReview: Boolean) {
  donations(
    campaignId: $campaignId
    teamId: $teamId
    kind: $kind
    first: $first
    after: $after
    donorId: $donorId
    searchTerm: $searchTerm
    needsReview: $needsReview
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        amount
        sentAt
        receiptSent
        offline
        kind
        kindHumanized
        employerMatching
        stripeId
        refundedAt
        needsReviewAt
        employerMatchEmailSentAt
        campaign {
          id
          name
        }
        donor {
          id
          name
          email
        }
        honoree {
          name
          recipientName
          recipientEmail
          recipientMessage
        }
      }
    }
  }
}
    `;

/**
 * __useDonationsQuery__
 *
 * To run a query within a React component, call `useDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      kind: // value for 'kind'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *      needsReview: // value for 'needsReview'
 *   },
 * });
 */
export function useDonationsQuery(baseOptions: Apollo.QueryHookOptions<DonationsQuery, DonationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationsQuery, DonationsQueryVariables>(DonationsDocument, options);
      }
export function useDonationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationsQuery, DonationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationsQuery, DonationsQueryVariables>(DonationsDocument, options);
        }
export type DonationsQueryHookResult = ReturnType<typeof useDonationsQuery>;
export type DonationsLazyQueryHookResult = ReturnType<typeof useDonationsLazyQuery>;
export type DonationsQueryResult = Apollo.QueryResult<DonationsQuery, DonationsQueryVariables>;
export const DownloadDonationReceiptDocument = gql`
    mutation downloadDonationReceipt($id: ID!) {
  downloadDonationReceipt(input: {attributes: {id: $id}}) {
    ... on DownloadDonationReceiptSuccess {
      donation {
        id
        receiptDownloadLink
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DownloadDonationReceiptMutationFn = Apollo.MutationFunction<DownloadDonationReceiptMutation, DownloadDonationReceiptMutationVariables>;

/**
 * __useDownloadDonationReceiptMutation__
 *
 * To run a mutation, you first call `useDownloadDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadDonationReceiptMutation, { data, loading, error }] = useDownloadDonationReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<DownloadDonationReceiptMutation, DownloadDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadDonationReceiptMutation, DownloadDonationReceiptMutationVariables>(DownloadDonationReceiptDocument, options);
      }
export type DownloadDonationReceiptMutationHookResult = ReturnType<typeof useDownloadDonationReceiptMutation>;
export type DownloadDonationReceiptMutationResult = Apollo.MutationResult<DownloadDonationReceiptMutation>;
export type DownloadDonationReceiptMutationOptions = Apollo.BaseMutationOptions<DownloadDonationReceiptMutation, DownloadDonationReceiptMutationVariables>;
export const EditDonationDocument = gql`
    mutation editDonation($id: ID!, $amount: String!, $sentAt: String!, $donorId: ID!, $campaignId: ID!, $kind: String!) {
  editDonation(
    input: {attributes: {id: $id, amount: $amount, sentAt: $sentAt, donorId: $donorId, campaignId: $campaignId, kind: $kind}}
  ) {
    ... on EditDonationSuccess {
      donation {
        id
        amount
        sentAt
        offline
        campaign {
          id
          name
        }
        donor {
          id
          name
          email
        }
        honoree {
          name
          recipientName
          recipientEmail
          recipientMessage
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditDonationMutationFn = Apollo.MutationFunction<EditDonationMutation, EditDonationMutationVariables>;

/**
 * __useEditDonationMutation__
 *
 * To run a mutation, you first call `useEditDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDonationMutation, { data, loading, error }] = useEditDonationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *      sentAt: // value for 'sentAt'
 *      donorId: // value for 'donorId'
 *      campaignId: // value for 'campaignId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useEditDonationMutation(baseOptions?: Apollo.MutationHookOptions<EditDonationMutation, EditDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDonationMutation, EditDonationMutationVariables>(EditDonationDocument, options);
      }
export type EditDonationMutationHookResult = ReturnType<typeof useEditDonationMutation>;
export type EditDonationMutationResult = Apollo.MutationResult<EditDonationMutation>;
export type EditDonationMutationOptions = Apollo.BaseMutationOptions<EditDonationMutation, EditDonationMutationVariables>;
export const ExportDonationsDocument = gql`
    mutation exportDonations($campaignId: ID, $teamId: ID!, $donorId: ID, $searchTerm: String, $kind: [String!]) {
  exportDonations(
    input: {attributes: {campaignId: $campaignId, teamId: $teamId, donorId: $donorId, searchTerm: $searchTerm, kind: $kind}}
  )
}
    `;
export type ExportDonationsMutationFn = Apollo.MutationFunction<ExportDonationsMutation, ExportDonationsMutationVariables>;

/**
 * __useExportDonationsMutation__
 *
 * To run a mutation, you first call `useExportDonationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDonationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDonationsMutation, { data, loading, error }] = useExportDonationsMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      searchTerm: // value for 'searchTerm'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useExportDonationsMutation(baseOptions?: Apollo.MutationHookOptions<ExportDonationsMutation, ExportDonationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportDonationsMutation, ExportDonationsMutationVariables>(ExportDonationsDocument, options);
      }
export type ExportDonationsMutationHookResult = ReturnType<typeof useExportDonationsMutation>;
export type ExportDonationsMutationResult = Apollo.MutationResult<ExportDonationsMutation>;
export type ExportDonationsMutationOptions = Apollo.BaseMutationOptions<ExportDonationsMutation, ExportDonationsMutationVariables>;
export const ManualDonationRefundDocument = gql`
    mutation manualDonationRefund($id: ID!) {
  manualDonationRefund(input: {attributes: {id: $id}}) {
    ... on ManualDonationRefundSuccess {
      donation {
        id
        refundedAt
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type ManualDonationRefundMutationFn = Apollo.MutationFunction<ManualDonationRefundMutation, ManualDonationRefundMutationVariables>;

/**
 * __useManualDonationRefundMutation__
 *
 * To run a mutation, you first call `useManualDonationRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualDonationRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualDonationRefundMutation, { data, loading, error }] = useManualDonationRefundMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManualDonationRefundMutation(baseOptions?: Apollo.MutationHookOptions<ManualDonationRefundMutation, ManualDonationRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManualDonationRefundMutation, ManualDonationRefundMutationVariables>(ManualDonationRefundDocument, options);
      }
export type ManualDonationRefundMutationHookResult = ReturnType<typeof useManualDonationRefundMutation>;
export type ManualDonationRefundMutationResult = Apollo.MutationResult<ManualDonationRefundMutation>;
export type ManualDonationRefundMutationOptions = Apollo.BaseMutationOptions<ManualDonationRefundMutation, ManualDonationRefundMutationVariables>;
export const RefundStripeDonationDocument = gql`
    mutation refundStripeDonation($id: ID!) {
  refundStripeDonation(input: {attributes: {id: $id}}) {
    ... on RefundStripeDonationSuccess {
      donation {
        id
        refundedAt
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type RefundStripeDonationMutationFn = Apollo.MutationFunction<RefundStripeDonationMutation, RefundStripeDonationMutationVariables>;

/**
 * __useRefundStripeDonationMutation__
 *
 * To run a mutation, you first call `useRefundStripeDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundStripeDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundStripeDonationMutation, { data, loading, error }] = useRefundStripeDonationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundStripeDonationMutation(baseOptions?: Apollo.MutationHookOptions<RefundStripeDonationMutation, RefundStripeDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundStripeDonationMutation, RefundStripeDonationMutationVariables>(RefundStripeDonationDocument, options);
      }
export type RefundStripeDonationMutationHookResult = ReturnType<typeof useRefundStripeDonationMutation>;
export type RefundStripeDonationMutationResult = Apollo.MutationResult<RefundStripeDonationMutation>;
export type RefundStripeDonationMutationOptions = Apollo.BaseMutationOptions<RefundStripeDonationMutation, RefundStripeDonationMutationVariables>;
export const SendDonationReceiptDocument = gql`
    mutation sendDonationReceipt($id: ID!, $includePdf: Boolean) {
  sendDonationReceipt(input: {attributes: {id: $id, includePdf: $includePdf}}) {
    ... on SendDonationReceiptSuccess {
      donation {
        id
        receiptSent
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type SendDonationReceiptMutationFn = Apollo.MutationFunction<SendDonationReceiptMutation, SendDonationReceiptMutationVariables>;

/**
 * __useSendDonationReceiptMutation__
 *
 * To run a mutation, you first call `useSendDonationReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDonationReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDonationReceiptMutation, { data, loading, error }] = useSendDonationReceiptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      includePdf: // value for 'includePdf'
 *   },
 * });
 */
export function useSendDonationReceiptMutation(baseOptions?: Apollo.MutationHookOptions<SendDonationReceiptMutation, SendDonationReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDonationReceiptMutation, SendDonationReceiptMutationVariables>(SendDonationReceiptDocument, options);
      }
export type SendDonationReceiptMutationHookResult = ReturnType<typeof useSendDonationReceiptMutation>;
export type SendDonationReceiptMutationResult = Apollo.MutationResult<SendDonationReceiptMutation>;
export type SendDonationReceiptMutationOptions = Apollo.BaseMutationOptions<SendDonationReceiptMutation, SendDonationReceiptMutationVariables>;
export const SendEmployerMatchEmailDocument = gql`
    mutation sendEmployerMatchEmail($id: ID!, $employerMatchUrl: String!) {
  sendEmployerMatchEmail(
    input: {attributes: {id: $id, employerMatchUrl: $employerMatchUrl}}
  ) {
    ... on SendEmployerMatchEmailSuccess {
      donation {
        id
        employerMatchEmailSentAt
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type SendEmployerMatchEmailMutationFn = Apollo.MutationFunction<SendEmployerMatchEmailMutation, SendEmployerMatchEmailMutationVariables>;

/**
 * __useSendEmployerMatchEmailMutation__
 *
 * To run a mutation, you first call `useSendEmployerMatchEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmployerMatchEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmployerMatchEmailMutation, { data, loading, error }] = useSendEmployerMatchEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      employerMatchUrl: // value for 'employerMatchUrl'
 *   },
 * });
 */
export function useSendEmployerMatchEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmployerMatchEmailMutation, SendEmployerMatchEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmployerMatchEmailMutation, SendEmployerMatchEmailMutationVariables>(SendEmployerMatchEmailDocument, options);
      }
export type SendEmployerMatchEmailMutationHookResult = ReturnType<typeof useSendEmployerMatchEmailMutation>;
export type SendEmployerMatchEmailMutationResult = Apollo.MutationResult<SendEmployerMatchEmailMutation>;
export type SendEmployerMatchEmailMutationOptions = Apollo.BaseMutationOptions<SendEmployerMatchEmailMutation, SendEmployerMatchEmailMutationVariables>;
export const CreateTicketOrderDonationDocument = gql`
    mutation createTicketOrderDonation($amount: String!, $campaignId: ID!, $donorId: ID!, $sentAt: String, $sendEmail: Boolean!, $sendTaxPdf: Boolean, $donorTicket: [JSON!]) {
  createTicketOrderDonation(
    input: {attributes: {amount: $amount, campaignId: $campaignId, donorId: $donorId, sentAt: $sentAt, sendEmail: $sendEmail, sendTaxPdf: $sendTaxPdf, donorTicket: $donorTicket}}
  ) {
    ... on CreateTicketOrderDonationSuccess {
      donation {
        id
        amount
        sentAt
        totalTicketCount
        checkedInCount
        donor {
          id
          name
          email
        }
        campaign {
          id
          name
        }
        donorTickets {
          id
          checkedInAt
          comments
          name
          email
          ticket {
            name
            description
            id
            price
          }
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateTicketOrderDonationMutationFn = Apollo.MutationFunction<CreateTicketOrderDonationMutation, CreateTicketOrderDonationMutationVariables>;

/**
 * __useCreateTicketOrderDonationMutation__
 *
 * To run a mutation, you first call `useCreateTicketOrderDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketOrderDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketOrderDonationMutation, { data, loading, error }] = useCreateTicketOrderDonationMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      campaignId: // value for 'campaignId'
 *      donorId: // value for 'donorId'
 *      sentAt: // value for 'sentAt'
 *      sendEmail: // value for 'sendEmail'
 *      sendTaxPdf: // value for 'sendTaxPdf'
 *      donorTicket: // value for 'donorTicket'
 *   },
 * });
 */
export function useCreateTicketOrderDonationMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketOrderDonationMutation, CreateTicketOrderDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketOrderDonationMutation, CreateTicketOrderDonationMutationVariables>(CreateTicketOrderDonationDocument, options);
      }
export type CreateTicketOrderDonationMutationHookResult = ReturnType<typeof useCreateTicketOrderDonationMutation>;
export type CreateTicketOrderDonationMutationResult = Apollo.MutationResult<CreateTicketOrderDonationMutation>;
export type CreateTicketOrderDonationMutationOptions = Apollo.BaseMutationOptions<CreateTicketOrderDonationMutation, CreateTicketOrderDonationMutationVariables>;
export const TicketOrderDonationsDocument = gql`
    query ticketOrderDonations($campaignId: ID, $teamId: ID!, $donorId: ID, $first: Int, $after: String, $searchTerm: String, $kind: [String!]) {
  donations(
    campaignId: $campaignId
    teamId: $teamId
    first: $first
    after: $after
    donorId: $donorId
    searchTerm: $searchTerm
    kind: $kind
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        amount
        sentAt
        totalTicketCount
        checkedInCount
        donor {
          id
          name
          email
        }
        campaign {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTicketOrderDonationsQuery__
 *
 * To run a query within a React component, call `useTicketOrderDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketOrderDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketOrderDonationsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useTicketOrderDonationsQuery(baseOptions: Apollo.QueryHookOptions<TicketOrderDonationsQuery, TicketOrderDonationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketOrderDonationsQuery, TicketOrderDonationsQueryVariables>(TicketOrderDonationsDocument, options);
      }
export function useTicketOrderDonationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketOrderDonationsQuery, TicketOrderDonationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketOrderDonationsQuery, TicketOrderDonationsQueryVariables>(TicketOrderDonationsDocument, options);
        }
export type TicketOrderDonationsQueryHookResult = ReturnType<typeof useTicketOrderDonationsQuery>;
export type TicketOrderDonationsLazyQueryHookResult = ReturnType<typeof useTicketOrderDonationsLazyQuery>;
export type TicketOrderDonationsQueryResult = Apollo.QueryResult<TicketOrderDonationsQuery, TicketOrderDonationsQueryVariables>;
export const AddDonorTicketDetailsDocument = gql`
    mutation addDonorTicketDetails($id: ID!, $comments: String, $email: String, $name: String) {
  addDonorTicketDetails(
    input: {attributes: {id: $id, comments: $comments, name: $name, email: $email}}
  ) {
    ... on AddDonorTicketDetailsSuccess {
      donorTicket {
        id
        checkedInAt
        comments
        name
        email
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type AddDonorTicketDetailsMutationFn = Apollo.MutationFunction<AddDonorTicketDetailsMutation, AddDonorTicketDetailsMutationVariables>;

/**
 * __useAddDonorTicketDetailsMutation__
 *
 * To run a mutation, you first call `useAddDonorTicketDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDonorTicketDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDonorTicketDetailsMutation, { data, loading, error }] = useAddDonorTicketDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comments: // value for 'comments'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddDonorTicketDetailsMutation(baseOptions?: Apollo.MutationHookOptions<AddDonorTicketDetailsMutation, AddDonorTicketDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDonorTicketDetailsMutation, AddDonorTicketDetailsMutationVariables>(AddDonorTicketDetailsDocument, options);
      }
export type AddDonorTicketDetailsMutationHookResult = ReturnType<typeof useAddDonorTicketDetailsMutation>;
export type AddDonorTicketDetailsMutationResult = Apollo.MutationResult<AddDonorTicketDetailsMutation>;
export type AddDonorTicketDetailsMutationOptions = Apollo.BaseMutationOptions<AddDonorTicketDetailsMutation, AddDonorTicketDetailsMutationVariables>;
export const CheckInDocument = gql`
    mutation checkIn($id: ID!, $checkedIn: Boolean!) {
  checkIn(input: {attributes: {id: $id, checkedIn: $checkedIn}}) {
    ... on CheckInSuccess {
      donorTicket {
        id
        comments
        name
        email
        checkedInAt
        donation {
          id
          checkedInCount
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CheckInMutationFn = Apollo.MutationFunction<CheckInMutation, CheckInMutationVariables>;

/**
 * __useCheckInMutation__
 *
 * To run a mutation, you first call `useCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInMutation, { data, loading, error }] = useCheckInMutation({
 *   variables: {
 *      id: // value for 'id'
 *      checkedIn: // value for 'checkedIn'
 *   },
 * });
 */
export function useCheckInMutation(baseOptions?: Apollo.MutationHookOptions<CheckInMutation, CheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInMutation, CheckInMutationVariables>(CheckInDocument, options);
      }
export type CheckInMutationHookResult = ReturnType<typeof useCheckInMutation>;
export type CheckInMutationResult = Apollo.MutationResult<CheckInMutation>;
export type CheckInMutationOptions = Apollo.BaseMutationOptions<CheckInMutation, CheckInMutationVariables>;
export const CreateDonorTicketDocument = gql`
    mutation createDonorTicket($ticketId: ID!, $donationId: ID!) {
  createDonorTicket(
    input: {attributes: {ticketId: $ticketId, donationId: $donationId}}
  ) {
    ... on CreateDonorTicketSuccess {
      donorTicket {
        id
        checkedInAt
        comments
        name
        email
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateDonorTicketMutationFn = Apollo.MutationFunction<CreateDonorTicketMutation, CreateDonorTicketMutationVariables>;

/**
 * __useCreateDonorTicketMutation__
 *
 * To run a mutation, you first call `useCreateDonorTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorTicketMutation, { data, loading, error }] = useCreateDonorTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      donationId: // value for 'donationId'
 *   },
 * });
 */
export function useCreateDonorTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateDonorTicketMutation, CreateDonorTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDonorTicketMutation, CreateDonorTicketMutationVariables>(CreateDonorTicketDocument, options);
      }
export type CreateDonorTicketMutationHookResult = ReturnType<typeof useCreateDonorTicketMutation>;
export type CreateDonorTicketMutationResult = Apollo.MutationResult<CreateDonorTicketMutation>;
export type CreateDonorTicketMutationOptions = Apollo.BaseMutationOptions<CreateDonorTicketMutation, CreateDonorTicketMutationVariables>;
export const DeleteDonorTicketDocument = gql`
    mutation deleteDonorTicket($id: ID!) {
  deleteDonorTicket(input: {attributes: {id: $id}}) {
    ... on DeleteDonorTicketSuccess {
      donorTicket {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteDonorTicketMutationFn = Apollo.MutationFunction<DeleteDonorTicketMutation, DeleteDonorTicketMutationVariables>;

/**
 * __useDeleteDonorTicketMutation__
 *
 * To run a mutation, you first call `useDeleteDonorTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonorTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonorTicketMutation, { data, loading, error }] = useDeleteDonorTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonorTicketMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDonorTicketMutation, DeleteDonorTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDonorTicketMutation, DeleteDonorTicketMutationVariables>(DeleteDonorTicketDocument, options);
      }
export type DeleteDonorTicketMutationHookResult = ReturnType<typeof useDeleteDonorTicketMutation>;
export type DeleteDonorTicketMutationResult = Apollo.MutationResult<DeleteDonorTicketMutation>;
export type DeleteDonorTicketMutationOptions = Apollo.BaseMutationOptions<DeleteDonorTicketMutation, DeleteDonorTicketMutationVariables>;
export const DonorTicketDocument = gql`
    query donorTicket($id: ID!) {
  donorTicket(id: $id) {
    id
    checkedInAt
    comments
    name
    email
  }
}
    `;

/**
 * __useDonorTicketQuery__
 *
 * To run a query within a React component, call `useDonorTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorTicketQuery(baseOptions: Apollo.QueryHookOptions<DonorTicketQuery, DonorTicketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonorTicketQuery, DonorTicketQueryVariables>(DonorTicketDocument, options);
      }
export function useDonorTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorTicketQuery, DonorTicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonorTicketQuery, DonorTicketQueryVariables>(DonorTicketDocument, options);
        }
export type DonorTicketQueryHookResult = ReturnType<typeof useDonorTicketQuery>;
export type DonorTicketLazyQueryHookResult = ReturnType<typeof useDonorTicketLazyQuery>;
export type DonorTicketQueryResult = Apollo.QueryResult<DonorTicketQuery, DonorTicketQueryVariables>;
export const DonorTicketByTokenDocument = gql`
    query donorTicketByToken($token: String!) {
  donorTicketByToken(token: $token) {
    id
    checkedInAt
    comments
    name
    email
    ticket {
      name
      price
      id
    }
    donation {
      id
      donorTickets {
        id
        checkedInAt
        comments
        name
        email
        ticket {
          name
          price
          id
        }
      }
    }
  }
}
    `;

/**
 * __useDonorTicketByTokenQuery__
 *
 * To run a query within a React component, call `useDonorTicketByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorTicketByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorTicketByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDonorTicketByTokenQuery(baseOptions: Apollo.QueryHookOptions<DonorTicketByTokenQuery, DonorTicketByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonorTicketByTokenQuery, DonorTicketByTokenQueryVariables>(DonorTicketByTokenDocument, options);
      }
export function useDonorTicketByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorTicketByTokenQuery, DonorTicketByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonorTicketByTokenQuery, DonorTicketByTokenQueryVariables>(DonorTicketByTokenDocument, options);
        }
export type DonorTicketByTokenQueryHookResult = ReturnType<typeof useDonorTicketByTokenQuery>;
export type DonorTicketByTokenLazyQueryHookResult = ReturnType<typeof useDonorTicketByTokenLazyQuery>;
export type DonorTicketByTokenQueryResult = Apollo.QueryResult<DonorTicketByTokenQuery, DonorTicketByTokenQueryVariables>;
export const DonorTicketsDocument = gql`
    query donorTickets($campaignIds: [ID!], $teamId: ID!, $donationId: ID, $first: Int, $after: String, $searchTerm: String) {
  donorTickets(
    campaignIds: $campaignIds
    teamId: $teamId
    first: $first
    after: $after
    searchTerm: $searchTerm
    donationId: $donationId
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        checkedInAt
        comments
        name
        email
        ticket {
          name
          description
          id
          price
          campaign {
            id
            name
          }
        }
        donation {
          id
          donor {
            id
            name
            email
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDonorTicketsQuery__
 *
 * To run a query within a React component, call `useDonorTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorTicketsQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *      teamId: // value for 'teamId'
 *      donationId: // value for 'donationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDonorTicketsQuery(baseOptions: Apollo.QueryHookOptions<DonorTicketsQuery, DonorTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonorTicketsQuery, DonorTicketsQueryVariables>(DonorTicketsDocument, options);
      }
export function useDonorTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorTicketsQuery, DonorTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonorTicketsQuery, DonorTicketsQueryVariables>(DonorTicketsDocument, options);
        }
export type DonorTicketsQueryHookResult = ReturnType<typeof useDonorTicketsQuery>;
export type DonorTicketsLazyQueryHookResult = ReturnType<typeof useDonorTicketsLazyQuery>;
export type DonorTicketsQueryResult = Apollo.QueryResult<DonorTicketsQuery, DonorTicketsQueryVariables>;
export const ExportDonorTicketsDocument = gql`
    mutation exportDonorTickets($campaignIds: [ID!], $teamId: ID!, $donorId: ID, $searchTerm: String) {
  exportDonorTickets(
    input: {attributes: {campaignIds: $campaignIds, teamId: $teamId, donorId: $donorId, searchTerm: $searchTerm}}
  )
}
    `;
export type ExportDonorTicketsMutationFn = Apollo.MutationFunction<ExportDonorTicketsMutation, ExportDonorTicketsMutationVariables>;

/**
 * __useExportDonorTicketsMutation__
 *
 * To run a mutation, you first call `useExportDonorTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDonorTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDonorTicketsMutation, { data, loading, error }] = useExportDonorTicketsMutation({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useExportDonorTicketsMutation(baseOptions?: Apollo.MutationHookOptions<ExportDonorTicketsMutation, ExportDonorTicketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportDonorTicketsMutation, ExportDonorTicketsMutationVariables>(ExportDonorTicketsDocument, options);
      }
export type ExportDonorTicketsMutationHookResult = ReturnType<typeof useExportDonorTicketsMutation>;
export type ExportDonorTicketsMutationResult = Apollo.MutationResult<ExportDonorTicketsMutation>;
export type ExportDonorTicketsMutationOptions = Apollo.BaseMutationOptions<ExportDonorTicketsMutation, ExportDonorTicketsMutationVariables>;
export const CreateDonorDocument = gql`
    mutation createDonor($name: String!, $email: String, $address1: String, $address2: String, $state: String, $zip: String, $city: String, $phone: String, $teamId: ID!) {
  createDonor(
    input: {attributes: {name: $name, email: $email, address1: $address1, address2: $address2, state: $state, zip: $zip, city: $city, phone: $phone, teamId: $teamId}}
  ) {
    ... on CreateDonorSuccess {
      donor {
        id
        name
        email
        address1
        address2
        addressLine1
        addressLine2
        city
        state
        zip
        donationCount
        donationTotal
        employerName
        uniqueCampaigns {
          name
          id
        }
        status
        lastDonation {
          id
          sentAt
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateDonorMutationFn = Apollo.MutationFunction<CreateDonorMutation, CreateDonorMutationVariables>;

/**
 * __useCreateDonorMutation__
 *
 * To run a mutation, you first call `useCreateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorMutation, { data, loading, error }] = useCreateDonorMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      city: // value for 'city'
 *      phone: // value for 'phone'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useCreateDonorMutation(baseOptions?: Apollo.MutationHookOptions<CreateDonorMutation, CreateDonorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDonorMutation, CreateDonorMutationVariables>(CreateDonorDocument, options);
      }
export type CreateDonorMutationHookResult = ReturnType<typeof useCreateDonorMutation>;
export type CreateDonorMutationResult = Apollo.MutationResult<CreateDonorMutation>;
export type CreateDonorMutationOptions = Apollo.BaseMutationOptions<CreateDonorMutation, CreateDonorMutationVariables>;
export const DeleteDonorDocument = gql`
    mutation deleteDonor($id: ID!) {
  deleteDonor(input: {attributes: {id: $id}}) {
    ... on DeleteDonorSuccess {
      donor {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteDonorMutationFn = Apollo.MutationFunction<DeleteDonorMutation, DeleteDonorMutationVariables>;

/**
 * __useDeleteDonorMutation__
 *
 * To run a mutation, you first call `useDeleteDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonorMutation, { data, loading, error }] = useDeleteDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDonorMutation, DeleteDonorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDonorMutation, DeleteDonorMutationVariables>(DeleteDonorDocument, options);
      }
export type DeleteDonorMutationHookResult = ReturnType<typeof useDeleteDonorMutation>;
export type DeleteDonorMutationResult = Apollo.MutationResult<DeleteDonorMutation>;
export type DeleteDonorMutationOptions = Apollo.BaseMutationOptions<DeleteDonorMutation, DeleteDonorMutationVariables>;
export const DonationsDonorsDocument = gql`
    query donationsDonors($campaignId: ID, $teamId: ID!, $donorId: ID, $kind: [String!], $searchTerm: String, $startDate: String, $endDate: String) {
  donationsDonors(
    campaignId: $campaignId
    teamId: $teamId
    kind: $kind
    donorId: $donorId
    searchTerm: $searchTerm
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useDonationsDonorsQuery__
 *
 * To run a query within a React component, call `useDonationsDonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsDonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsDonorsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      kind: // value for 'kind'
 *      searchTerm: // value for 'searchTerm'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDonationsDonorsQuery(baseOptions: Apollo.QueryHookOptions<DonationsDonorsQuery, DonationsDonorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationsDonorsQuery, DonationsDonorsQueryVariables>(DonationsDonorsDocument, options);
      }
export function useDonationsDonorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationsDonorsQuery, DonationsDonorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationsDonorsQuery, DonationsDonorsQueryVariables>(DonationsDonorsDocument, options);
        }
export type DonationsDonorsQueryHookResult = ReturnType<typeof useDonationsDonorsQuery>;
export type DonationsDonorsLazyQueryHookResult = ReturnType<typeof useDonationsDonorsLazyQuery>;
export type DonationsDonorsQueryResult = Apollo.QueryResult<DonationsDonorsQuery, DonationsDonorsQueryVariables>;
export const DonorDocument = gql`
    query donor($id: ID!) {
  donor(id: $id) {
    id
    name
    email
    address1
    address2
    addressLine1
    addressLine2
    city
    state
    rfmScore
    zip
    employerName
    donationCount
    donationTotal
    canDestroy
    uniqueCampaigns {
      name
      id
    }
    status
    lastDonation {
      id
      sentAt
    }
  }
}
    `;

/**
 * __useDonorQuery__
 *
 * To run a query within a React component, call `useDonorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorQuery(baseOptions: Apollo.QueryHookOptions<DonorQuery, DonorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonorQuery, DonorQueryVariables>(DonorDocument, options);
      }
export function useDonorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorQuery, DonorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonorQuery, DonorQueryVariables>(DonorDocument, options);
        }
export type DonorQueryHookResult = ReturnType<typeof useDonorQuery>;
export type DonorLazyQueryHookResult = ReturnType<typeof useDonorLazyQuery>;
export type DonorQueryResult = Apollo.QueryResult<DonorQuery, DonorQueryVariables>;
export const DonorsDocument = gql`
    query donors($campaignId: ID, $teamId: ID!, $first: Int, $after: String, $searchTerm: String, $sortBy: String) {
  donors(
    campaignId: $campaignId
    teamId: $teamId
    first: $first
    after: $after
    searchTerm: $searchTerm
    sortBy: $sortBy
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        email
        address1
        address2
        addressLine1
        addressLine2
        rfmScore
        employerName
        uniqueCampaigns {
          name
          id
        }
        city
        state
        zip
        donationCount
        donationTotal
        status
        canDestroy
        lastDonation {
          id
          sentAt
        }
      }
    }
  }
}
    `;

/**
 * __useDonorsQuery__
 *
 * To run a query within a React component, call `useDonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDonorsQuery(baseOptions: Apollo.QueryHookOptions<DonorsQuery, DonorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonorsQuery, DonorsQueryVariables>(DonorsDocument, options);
      }
export function useDonorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorsQuery, DonorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonorsQuery, DonorsQueryVariables>(DonorsDocument, options);
        }
export type DonorsQueryHookResult = ReturnType<typeof useDonorsQuery>;
export type DonorsLazyQueryHookResult = ReturnType<typeof useDonorsLazyQuery>;
export type DonorsQueryResult = Apollo.QueryResult<DonorsQuery, DonorsQueryVariables>;
export const EditDonorDocument = gql`
    mutation editDonor($id: ID!, $name: String!, $email: String, $address1: String, $address2: String, $state: String, $zip: String, $city: String, $phone: String) {
  editDonor(
    input: {attributes: {id: $id, name: $name, email: $email, address1: $address1, address2: $address2, state: $state, zip: $zip, city: $city, phone: $phone}}
  ) {
    ... on EditDonorSuccess {
      donor {
        id
        name
        email
        address1
        address2
        addressLine1
        addressLine2
        city
        state
        zip
        donationCount
        donationTotal
        employerName
        uniqueCampaigns {
          name
          id
        }
        status
        lastDonation {
          id
          sentAt
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditDonorMutationFn = Apollo.MutationFunction<EditDonorMutation, EditDonorMutationVariables>;

/**
 * __useEditDonorMutation__
 *
 * To run a mutation, you first call `useEditDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDonorMutation, { data, loading, error }] = useEditDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      city: // value for 'city'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useEditDonorMutation(baseOptions?: Apollo.MutationHookOptions<EditDonorMutation, EditDonorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDonorMutation, EditDonorMutationVariables>(EditDonorDocument, options);
      }
export type EditDonorMutationHookResult = ReturnType<typeof useEditDonorMutation>;
export type EditDonorMutationResult = Apollo.MutationResult<EditDonorMutation>;
export type EditDonorMutationOptions = Apollo.BaseMutationOptions<EditDonorMutation, EditDonorMutationVariables>;
export const EmailEventsDocument = gql`
    query emailEvents($id: ID!, $type: String!) {
  emailEvents(id: $id, type: $type) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        eventType
        timestamp
        status
        deliveredAt
        eventName
        attachments {
          id
          url
          filename
        }
      }
    }
  }
}
    `;

/**
 * __useEmailEventsQuery__
 *
 * To run a query within a React component, call `useEmailEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useEmailEventsQuery(baseOptions: Apollo.QueryHookOptions<EmailEventsQuery, EmailEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailEventsQuery, EmailEventsQueryVariables>(EmailEventsDocument, options);
      }
export function useEmailEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailEventsQuery, EmailEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailEventsQuery, EmailEventsQueryVariables>(EmailEventsDocument, options);
        }
export type EmailEventsQueryHookResult = ReturnType<typeof useEmailEventsQuery>;
export type EmailEventsLazyQueryHookResult = ReturnType<typeof useEmailEventsLazyQuery>;
export type EmailEventsQueryResult = Apollo.QueryResult<EmailEventsQuery, EmailEventsQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($campaignId: ID!, $header: String!, $body: String!, $sidebar: [JSON!], $metaDescription: String, $metaTitle: String, $showGoal: Boolean) {
  createEvent(
    input: {attributes: {campaignId: $campaignId, header: $header, body: $body, sidebar: $sidebar, metaDescription: $metaDescription, metaTitle: $metaTitle, showGoal: $showGoal}}
  ) {
    ... on CreateEventSuccess {
      fundraiserContent {
        id
        body
        sidebar
        header
        metaDescription
        metaTitle
        showGoal
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      header: // value for 'header'
 *      body: // value for 'body'
 *      sidebar: // value for 'sidebar'
 *      metaDescription: // value for 'metaDescription'
 *      metaTitle: // value for 'metaTitle'
 *      showGoal: // value for 'showGoal'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateTicketPaymentIntentDocument = gql`
    mutation createTicketPaymentIntent($campaignId: ID!, $tickets: [JSON!]!, $coverFees: Boolean) {
  createTicketPaymentIntent(
    input: {attributes: {campaignId: $campaignId, tickets: $tickets, coverFees: $coverFees}}
  ) {
    ... on CreateTicketPaymentIntentSuccess {
      paymentIntent {
        paymentIntent
        stripeAccountId
        amount
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateTicketPaymentIntentMutationFn = Apollo.MutationFunction<CreateTicketPaymentIntentMutation, CreateTicketPaymentIntentMutationVariables>;

/**
 * __useCreateTicketPaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreateTicketPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketPaymentIntentMutation, { data, loading, error }] = useCreateTicketPaymentIntentMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      tickets: // value for 'tickets'
 *      coverFees: // value for 'coverFees'
 *   },
 * });
 */
export function useCreateTicketPaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketPaymentIntentMutation, CreateTicketPaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketPaymentIntentMutation, CreateTicketPaymentIntentMutationVariables>(CreateTicketPaymentIntentDocument, options);
      }
export type CreateTicketPaymentIntentMutationHookResult = ReturnType<typeof useCreateTicketPaymentIntentMutation>;
export type CreateTicketPaymentIntentMutationResult = Apollo.MutationResult<CreateTicketPaymentIntentMutation>;
export type CreateTicketPaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreateTicketPaymentIntentMutation, CreateTicketPaymentIntentMutationVariables>;
export const EditEventDocument = gql`
    mutation editEvent($id: ID!, $header: String!, $body: String!, $sidebar: [JSON!], $metaDescription: String, $metaTitle: String, $showGoal: Boolean) {
  editEvent(
    input: {attributes: {id: $id, header: $header, body: $body, sidebar: $sidebar, metaDescription: $metaDescription, metaTitle: $metaTitle, showGoal: $showGoal}}
  ) {
    ... on EditEventSuccess {
      fundraiserContent {
        id
        body
        sidebar
        header
        metaDescription
        metaTitle
        showGoal
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditEventMutationFn = Apollo.MutationFunction<EditEventMutation, EditEventMutationVariables>;

/**
 * __useEditEventMutation__
 *
 * To run a mutation, you first call `useEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventMutation, { data, loading, error }] = useEditEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      header: // value for 'header'
 *      body: // value for 'body'
 *      sidebar: // value for 'sidebar'
 *      metaDescription: // value for 'metaDescription'
 *      metaTitle: // value for 'metaTitle'
 *      showGoal: // value for 'showGoal'
 *   },
 * });
 */
export function useEditEventMutation(baseOptions?: Apollo.MutationHookOptions<EditEventMutation, EditEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEventMutation, EditEventMutationVariables>(EditEventDocument, options);
      }
export type EditEventMutationHookResult = ReturnType<typeof useEditEventMutation>;
export type EditEventMutationResult = Apollo.MutationResult<EditEventMutation>;
export type EditEventMutationOptions = Apollo.BaseMutationOptions<EditEventMutation, EditEventMutationVariables>;
export const EventContentDocument = gql`
    query eventContent($campaignId: ID!) {
  fundraiserContent(campaignId: $campaignId) {
    id
    body
    header
    sidebar
    logoUrl
    publicImageUrl
    teamName
    emailRequired
    addressRequired
    nameRequired
    totalRaised
    fundraiserActive
    goalCompletionPercentage
    goalAmount
    showGoal
    totalDonationCount
    metaDescription
    metaTitle
    timeInfo
    paymentsEnabled
    locationFullAddress
    defaultCampaignUrl
    googleAnalyticsId
    teamUrl
    teamId
    fundraiserUrl
    purchaseableTickets {
      id
      quantity
      price
      name
      description
      attendeesPerTicket
      maximumAvailable
      maximumPerOrder
      endsAt
    }
  }
}
    `;

/**
 * __useEventContentQuery__
 *
 * To run a query within a React component, call `useEventContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventContentQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useEventContentQuery(baseOptions: Apollo.QueryHookOptions<EventContentQuery, EventContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventContentQuery, EventContentQueryVariables>(EventContentDocument, options);
      }
export function useEventContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventContentQuery, EventContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventContentQuery, EventContentQueryVariables>(EventContentDocument, options);
        }
export type EventContentQueryHookResult = ReturnType<typeof useEventContentQuery>;
export type EventContentLazyQueryHookResult = ReturnType<typeof useEventContentLazyQuery>;
export type EventContentQueryResult = Apollo.QueryResult<EventContentQuery, EventContentQueryVariables>;
export const CreateFundraiserDocument = gql`
    mutation createFundraiser($campaignId: ID!, $header: String!, $body: String!, $includeRecipient: Boolean!, $includeHonoree: Boolean!, $sidebar: [JSON!], $allowPublicComments: Boolean, $metaDescription: String, $metaTitle: String, $showGoal: Boolean) {
  createFundraiser(
    input: {attributes: {campaignId: $campaignId, header: $header, body: $body, includeRecipient: $includeRecipient, includeHonoree: $includeHonoree, sidebar: $sidebar, allowPublicComments: $allowPublicComments, metaDescription: $metaDescription, metaTitle: $metaTitle, showGoal: $showGoal}}
  ) {
    ... on CreateFundraiserSuccess {
      fundraiserContent {
        id
        body
        sidebar
        header
        includeHonoree
        showGoal
        includeRecipient
        metaDescription
        metaTitle
        allowPublicComments
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateFundraiserMutationFn = Apollo.MutationFunction<CreateFundraiserMutation, CreateFundraiserMutationVariables>;

/**
 * __useCreateFundraiserMutation__
 *
 * To run a mutation, you first call `useCreateFundraiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFundraiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFundraiserMutation, { data, loading, error }] = useCreateFundraiserMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      header: // value for 'header'
 *      body: // value for 'body'
 *      includeRecipient: // value for 'includeRecipient'
 *      includeHonoree: // value for 'includeHonoree'
 *      sidebar: // value for 'sidebar'
 *      allowPublicComments: // value for 'allowPublicComments'
 *      metaDescription: // value for 'metaDescription'
 *      metaTitle: // value for 'metaTitle'
 *      showGoal: // value for 'showGoal'
 *   },
 * });
 */
export function useCreateFundraiserMutation(baseOptions?: Apollo.MutationHookOptions<CreateFundraiserMutation, CreateFundraiserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFundraiserMutation, CreateFundraiserMutationVariables>(CreateFundraiserDocument, options);
      }
export type CreateFundraiserMutationHookResult = ReturnType<typeof useCreateFundraiserMutation>;
export type CreateFundraiserMutationResult = Apollo.MutationResult<CreateFundraiserMutation>;
export type CreateFundraiserMutationOptions = Apollo.BaseMutationOptions<CreateFundraiserMutation, CreateFundraiserMutationVariables>;
export const DeleteFundraiserContentImageDocument = gql`
    mutation deleteFundraiserContentImage($id: ID!) {
  deleteFundraiserContentImage(input: {attributes: {id: $id}}) {
    ... on DeleteFundraiserContentImageSuccess {
      fundraiserContent {
        id
        publicImageUrl
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteFundraiserContentImageMutationFn = Apollo.MutationFunction<DeleteFundraiserContentImageMutation, DeleteFundraiserContentImageMutationVariables>;

/**
 * __useDeleteFundraiserContentImageMutation__
 *
 * To run a mutation, you first call `useDeleteFundraiserContentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFundraiserContentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFundraiserContentImageMutation, { data, loading, error }] = useDeleteFundraiserContentImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFundraiserContentImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFundraiserContentImageMutation, DeleteFundraiserContentImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFundraiserContentImageMutation, DeleteFundraiserContentImageMutationVariables>(DeleteFundraiserContentImageDocument, options);
      }
export type DeleteFundraiserContentImageMutationHookResult = ReturnType<typeof useDeleteFundraiserContentImageMutation>;
export type DeleteFundraiserContentImageMutationResult = Apollo.MutationResult<DeleteFundraiserContentImageMutation>;
export type DeleteFundraiserContentImageMutationOptions = Apollo.BaseMutationOptions<DeleteFundraiserContentImageMutation, DeleteFundraiserContentImageMutationVariables>;
export const EditFundraiserDocument = gql`
    mutation editFundraiser($id: ID!, $header: String!, $body: String!, $includeRecipient: Boolean!, $includeHonoree: Boolean!, $sidebar: [JSON!], $allowPublicComments: Boolean, $metaDescription: String, $metaTitle: String, $showGoal: Boolean) {
  editFundraiser(
    input: {attributes: {id: $id, header: $header, body: $body, includeRecipient: $includeRecipient, includeHonoree: $includeHonoree, sidebar: $sidebar, allowPublicComments: $allowPublicComments, metaDescription: $metaDescription, metaTitle: $metaTitle, showGoal: $showGoal}}
  ) {
    ... on EditFundraiserSuccess {
      fundraiserContent {
        id
        body
        sidebar
        header
        includeHonoree
        includeRecipient
        metaDescription
        metaTitle
        allowPublicComments
        showGoal
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditFundraiserMutationFn = Apollo.MutationFunction<EditFundraiserMutation, EditFundraiserMutationVariables>;

/**
 * __useEditFundraiserMutation__
 *
 * To run a mutation, you first call `useEditFundraiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFundraiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFundraiserMutation, { data, loading, error }] = useEditFundraiserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      header: // value for 'header'
 *      body: // value for 'body'
 *      includeRecipient: // value for 'includeRecipient'
 *      includeHonoree: // value for 'includeHonoree'
 *      sidebar: // value for 'sidebar'
 *      allowPublicComments: // value for 'allowPublicComments'
 *      metaDescription: // value for 'metaDescription'
 *      metaTitle: // value for 'metaTitle'
 *      showGoal: // value for 'showGoal'
 *   },
 * });
 */
export function useEditFundraiserMutation(baseOptions?: Apollo.MutationHookOptions<EditFundraiserMutation, EditFundraiserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFundraiserMutation, EditFundraiserMutationVariables>(EditFundraiserDocument, options);
      }
export type EditFundraiserMutationHookResult = ReturnType<typeof useEditFundraiserMutation>;
export type EditFundraiserMutationResult = Apollo.MutationResult<EditFundraiserMutation>;
export type EditFundraiserMutationOptions = Apollo.BaseMutationOptions<EditFundraiserMutation, EditFundraiserMutationVariables>;
export const FundraiserContentDocument = gql`
    query fundraiserContent($campaignId: ID!) {
  fundraiserContent(campaignId: $campaignId) {
    id
    body
    header
    sidebar
    fundraiserActive
    includeRecipient
    includeHonoree
    logoUrl
    publicImageUrl
    teamName
    paymentsEnabled
    teamUrl
    defaultCampaignUrl
    allowPublicComments
    totalRaised
    goalCompletionPercentage
    goalAmount
    showGoal
    totalDonationCount
    fundraiserUrl
    pledgeUrl
    metaDescription
    metaTitle
    addressRequired
    includeFees
    includeEmployerMatch
    includeRecurring
    allowPublicComments
    teamId
    googleAnalyticsId
    purchaseableTickets {
      id
      quantity
      price
      name
      description
      attendeesPerTicket
      maximumAvailable
      maximumPerOrder
      endsAt
    }
  }
}
    `;

/**
 * __useFundraiserContentQuery__
 *
 * To run a query within a React component, call `useFundraiserContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraiserContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraiserContentQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useFundraiserContentQuery(baseOptions: Apollo.QueryHookOptions<FundraiserContentQuery, FundraiserContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundraiserContentQuery, FundraiserContentQueryVariables>(FundraiserContentDocument, options);
      }
export function useFundraiserContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundraiserContentQuery, FundraiserContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundraiserContentQuery, FundraiserContentQueryVariables>(FundraiserContentDocument, options);
        }
export type FundraiserContentQueryHookResult = ReturnType<typeof useFundraiserContentQuery>;
export type FundraiserContentLazyQueryHookResult = ReturnType<typeof useFundraiserContentLazyQuery>;
export type FundraiserContentQueryResult = Apollo.QueryResult<FundraiserContentQuery, FundraiserContentQueryVariables>;
export const UploadFundraiserContentImageDocument = gql`
    mutation uploadFundraiserContentImage($id: ID!, $publicImage: Upload!) {
  uploadFundraiserContentImage(
    input: {attributes: {id: $id, publicImage: $publicImage}}
  ) {
    ... on UploadFundraiserContentImageSuccess {
      fundraiserContent {
        id
        publicImageUrl
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type UploadFundraiserContentImageMutationFn = Apollo.MutationFunction<UploadFundraiserContentImageMutation, UploadFundraiserContentImageMutationVariables>;

/**
 * __useUploadFundraiserContentImageMutation__
 *
 * To run a mutation, you first call `useUploadFundraiserContentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFundraiserContentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFundraiserContentImageMutation, { data, loading, error }] = useUploadFundraiserContentImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publicImage: // value for 'publicImage'
 *   },
 * });
 */
export function useUploadFundraiserContentImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadFundraiserContentImageMutation, UploadFundraiserContentImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFundraiserContentImageMutation, UploadFundraiserContentImageMutationVariables>(UploadFundraiserContentImageDocument, options);
      }
export type UploadFundraiserContentImageMutationHookResult = ReturnType<typeof useUploadFundraiserContentImageMutation>;
export type UploadFundraiserContentImageMutationResult = Apollo.MutationResult<UploadFundraiserContentImageMutation>;
export type UploadFundraiserContentImageMutationOptions = Apollo.BaseMutationOptions<UploadFundraiserContentImageMutation, UploadFundraiserContentImageMutationVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation createPaymentIntent($amount: String!, $campaignId: ID!, $honoreeName: String, $honoreeKind: String, $recipientName: String, $recipientEmail: String, $recipientMessage: String, $employerName: String, $coverFees: Boolean) {
  createPaymentIntent(
    input: {attributes: {amount: $amount, campaignId: $campaignId, honoreeName: $honoreeName, honoreeKind: $honoreeKind, recipientName: $recipientName, recipientEmail: $recipientEmail, recipientMessage: $recipientMessage, employerName: $employerName, coverFees: $coverFees}}
  ) {
    ... on CreatePaymentIntentSuccess {
      paymentIntent {
        paymentIntent
        stripeAccountId
        amount
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      campaignId: // value for 'campaignId'
 *      honoreeName: // value for 'honoreeName'
 *      honoreeKind: // value for 'honoreeKind'
 *      recipientName: // value for 'recipientName'
 *      recipientEmail: // value for 'recipientEmail'
 *      recipientMessage: // value for 'recipientMessage'
 *      employerName: // value for 'employerName'
 *      coverFees: // value for 'coverFees'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const CreateSubscriptionIntentDocument = gql`
    mutation createSubscriptionIntent($amount: String!, $campaignId: ID!, $name: String!, $email: String!, $interval: String!, $honoreeName: String, $honoreeKind: String, $recipientName: String, $recipientEmail: String, $recipientMessage: String, $coverFees: Boolean) {
  createSubscriptionIntent(
    input: {attributes: {amount: $amount, campaignId: $campaignId, name: $name, email: $email, interval: $interval, honoreeName: $honoreeName, honoreeKind: $honoreeKind, recipientName: $recipientName, recipientEmail: $recipientEmail, recipientMessage: $recipientMessage, coverFees: $coverFees}}
  ) {
    ... on CreateSubscriptionIntentSuccess {
      paymentIntent {
        paymentIntent
        stripeAccountId
        amount
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateSubscriptionIntentMutationFn = Apollo.MutationFunction<CreateSubscriptionIntentMutation, CreateSubscriptionIntentMutationVariables>;

/**
 * __useCreateSubscriptionIntentMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionIntentMutation, { data, loading, error }] = useCreateSubscriptionIntentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      campaignId: // value for 'campaignId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      interval: // value for 'interval'
 *      honoreeName: // value for 'honoreeName'
 *      honoreeKind: // value for 'honoreeKind'
 *      recipientName: // value for 'recipientName'
 *      recipientEmail: // value for 'recipientEmail'
 *      recipientMessage: // value for 'recipientMessage'
 *      coverFees: // value for 'coverFees'
 *   },
 * });
 */
export function useCreateSubscriptionIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionIntentMutation, CreateSubscriptionIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionIntentMutation, CreateSubscriptionIntentMutationVariables>(CreateSubscriptionIntentDocument, options);
      }
export type CreateSubscriptionIntentMutationHookResult = ReturnType<typeof useCreateSubscriptionIntentMutation>;
export type CreateSubscriptionIntentMutationResult = Apollo.MutationResult<CreateSubscriptionIntentMutation>;
export type CreateSubscriptionIntentMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionIntentMutation, CreateSubscriptionIntentMutationVariables>;
export const PublicCommentsDocument = gql`
    query publicComments($campaignId: ID!, $first: Int, $after: String) {
  publicComments(campaignId: $campaignId, first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        message
        name
        amount
        createdAt
      }
    }
  }
}
    `;

/**
 * __usePublicCommentsQuery__
 *
 * To run a query within a React component, call `usePublicCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCommentsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePublicCommentsQuery(baseOptions: Apollo.QueryHookOptions<PublicCommentsQuery, PublicCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicCommentsQuery, PublicCommentsQueryVariables>(PublicCommentsDocument, options);
      }
export function usePublicCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicCommentsQuery, PublicCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicCommentsQuery, PublicCommentsQueryVariables>(PublicCommentsDocument, options);
        }
export type PublicCommentsQueryHookResult = ReturnType<typeof usePublicCommentsQuery>;
export type PublicCommentsLazyQueryHookResult = ReturnType<typeof usePublicCommentsLazyQuery>;
export type PublicCommentsQueryResult = Apollo.QueryResult<PublicCommentsQuery, PublicCommentsQueryVariables>;
export const AddIntegrationToTeamDocument = gql`
    mutation addIntegrationToTeam($integrationId: ID!, $apiKey: String!, $id: ID!, $apiFieldTwo: String, $apiFieldThree: String) {
  addIntegrationToTeam(
    input: {attributes: {integrationId: $integrationId, apiKey: $apiKey, id: $id, apiFieldTwo: $apiFieldTwo, apiFieldThree: $apiFieldThree}}
  ) {
    ... on AddIntegrationToTeamSuccess {
      team {
        id
        integrations {
          id
          name
          description
        }
        availableIntegrations {
          id
          name
          description
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type AddIntegrationToTeamMutationFn = Apollo.MutationFunction<AddIntegrationToTeamMutation, AddIntegrationToTeamMutationVariables>;

/**
 * __useAddIntegrationToTeamMutation__
 *
 * To run a mutation, you first call `useAddIntegrationToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationToTeamMutation, { data, loading, error }] = useAddIntegrationToTeamMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      apiKey: // value for 'apiKey'
 *      id: // value for 'id'
 *      apiFieldTwo: // value for 'apiFieldTwo'
 *      apiFieldThree: // value for 'apiFieldThree'
 *   },
 * });
 */
export function useAddIntegrationToTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddIntegrationToTeamMutation, AddIntegrationToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIntegrationToTeamMutation, AddIntegrationToTeamMutationVariables>(AddIntegrationToTeamDocument, options);
      }
export type AddIntegrationToTeamMutationHookResult = ReturnType<typeof useAddIntegrationToTeamMutation>;
export type AddIntegrationToTeamMutationResult = Apollo.MutationResult<AddIntegrationToTeamMutation>;
export type AddIntegrationToTeamMutationOptions = Apollo.BaseMutationOptions<AddIntegrationToTeamMutation, AddIntegrationToTeamMutationVariables>;
export const DeleteTeamIntegrationDocument = gql`
    mutation deleteTeamIntegration($integrationId: ID!, $id: ID!) {
  deleteTeamIntegration(
    input: {attributes: {integrationId: $integrationId, id: $id}}
  ) {
    ... on DeleteTeamIntegrationSuccess {
      team {
        id
        integrations {
          id
          name
          description
        }
        availableIntegrations {
          id
          name
          description
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteTeamIntegrationMutationFn = Apollo.MutationFunction<DeleteTeamIntegrationMutation, DeleteTeamIntegrationMutationVariables>;

/**
 * __useDeleteTeamIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteTeamIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamIntegrationMutation, { data, loading, error }] = useDeleteTeamIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamIntegrationMutation, DeleteTeamIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamIntegrationMutation, DeleteTeamIntegrationMutationVariables>(DeleteTeamIntegrationDocument, options);
      }
export type DeleteTeamIntegrationMutationHookResult = ReturnType<typeof useDeleteTeamIntegrationMutation>;
export type DeleteTeamIntegrationMutationResult = Apollo.MutationResult<DeleteTeamIntegrationMutation>;
export type DeleteTeamIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteTeamIntegrationMutation, DeleteTeamIntegrationMutationVariables>;
export const IntegrationDocument = gql`
    query integration($id: ID!) {
  integration(id: $id) {
    id
    name
    description
    apiFieldTwoName
    apiFieldThreeName
  }
}
    `;

/**
 * __useIntegrationQuery__
 *
 * To run a query within a React component, call `useIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationQuery(baseOptions: Apollo.QueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
      }
export function useIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
        }
export type IntegrationQueryHookResult = ReturnType<typeof useIntegrationQuery>;
export type IntegrationLazyQueryHookResult = ReturnType<typeof useIntegrationLazyQuery>;
export type IntegrationQueryResult = Apollo.QueryResult<IntegrationQuery, IntegrationQueryVariables>;
export const TeamIntegrationsDocument = gql`
    query teamIntegrations($id: ID!) {
  team(id: $id) {
    id
    integrations {
      id
      name
      description
      apiFieldTwoName
      apiFieldThreeName
    }
    availableIntegrations {
      id
      name
      description
      apiFieldTwoName
      apiFieldThreeName
    }
  }
}
    `;

/**
 * __useTeamIntegrationsQuery__
 *
 * To run a query within a React component, call `useTeamIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamIntegrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<TeamIntegrationsQuery, TeamIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamIntegrationsQuery, TeamIntegrationsQueryVariables>(TeamIntegrationsDocument, options);
      }
export function useTeamIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamIntegrationsQuery, TeamIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamIntegrationsQuery, TeamIntegrationsQueryVariables>(TeamIntegrationsDocument, options);
        }
export type TeamIntegrationsQueryHookResult = ReturnType<typeof useTeamIntegrationsQuery>;
export type TeamIntegrationsLazyQueryHookResult = ReturnType<typeof useTeamIntegrationsLazyQuery>;
export type TeamIntegrationsQueryResult = Apollo.QueryResult<TeamIntegrationsQuery, TeamIntegrationsQueryVariables>;
export const ConnectAccountDocument = gql`
    query connectAccount($id: ID!) {
  connectAccount(id: $id)
}
    `;

/**
 * __useConnectAccountQuery__
 *
 * To run a query within a React component, call `useConnectAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectAccountQuery(baseOptions: Apollo.QueryHookOptions<ConnectAccountQuery, ConnectAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectAccountQuery, ConnectAccountQueryVariables>(ConnectAccountDocument, options);
      }
export function useConnectAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectAccountQuery, ConnectAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectAccountQuery, ConnectAccountQueryVariables>(ConnectAccountDocument, options);
        }
export type ConnectAccountQueryHookResult = ReturnType<typeof useConnectAccountQuery>;
export type ConnectAccountLazyQueryHookResult = ReturnType<typeof useConnectAccountLazyQuery>;
export type ConnectAccountQueryResult = Apollo.QueryResult<ConnectAccountQuery, ConnectAccountQueryVariables>;
export const CreatePledgeDocument = gql`
    mutation createPledge($amount: String!, $campaignId: ID!, $name: String!, $email: String!) {
  createPledge(
    input: {attributes: {amount: $amount, campaignId: $campaignId, email: $email, name: $name}}
  ) {
    ... on CreatePledgeSuccess {
      donation {
        id
        amount
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreatePledgeMutationFn = Apollo.MutationFunction<CreatePledgeMutation, CreatePledgeMutationVariables>;

/**
 * __useCreatePledgeMutation__
 *
 * To run a mutation, you first call `useCreatePledgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePledgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPledgeMutation, { data, loading, error }] = useCreatePledgeMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      campaignId: // value for 'campaignId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreatePledgeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePledgeMutation, CreatePledgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePledgeMutation, CreatePledgeMutationVariables>(CreatePledgeDocument, options);
      }
export type CreatePledgeMutationHookResult = ReturnType<typeof useCreatePledgeMutation>;
export type CreatePledgeMutationResult = Apollo.MutationResult<CreatePledgeMutation>;
export type CreatePledgeMutationOptions = Apollo.BaseMutationOptions<CreatePledgeMutation, CreatePledgeMutationVariables>;
export const DonorTicketsByDonationTokenDocument = gql`
    query donorTicketsByDonationToken($token: String!, $campaignId: ID!) {
  donorTicketsByDonationToken(token: $token, campaignId: $campaignId) {
    id
    token
    name
    email
    checkedInAt
    ticket {
      name
    }
    donation {
      donor {
        name
      }
    }
  }
}
    `;

/**
 * __useDonorTicketsByDonationTokenQuery__
 *
 * To run a query within a React component, call `useDonorTicketsByDonationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorTicketsByDonationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorTicketsByDonationTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDonorTicketsByDonationTokenQuery(baseOptions: Apollo.QueryHookOptions<DonorTicketsByDonationTokenQuery, DonorTicketsByDonationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonorTicketsByDonationTokenQuery, DonorTicketsByDonationTokenQueryVariables>(DonorTicketsByDonationTokenDocument, options);
      }
export function useDonorTicketsByDonationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorTicketsByDonationTokenQuery, DonorTicketsByDonationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonorTicketsByDonationTokenQuery, DonorTicketsByDonationTokenQueryVariables>(DonorTicketsByDonationTokenDocument, options);
        }
export type DonorTicketsByDonationTokenQueryHookResult = ReturnType<typeof useDonorTicketsByDonationTokenQuery>;
export type DonorTicketsByDonationTokenLazyQueryHookResult = ReturnType<typeof useDonorTicketsByDonationTokenLazyQuery>;
export type DonorTicketsByDonationTokenQueryResult = Apollo.QueryResult<DonorTicketsByDonationTokenQuery, DonorTicketsByDonationTokenQueryVariables>;
export const ReassignDonorTicketDocument = gql`
    mutation reassignDonorTicket($token: String!, $name: String, $email: String) {
  reassignDonorTicket(
    input: {attributes: {token: $token, name: $name, email: $email}}
  ) {
    ... on ReassignDonorTicketSuccess {
      donorTicket {
        id
        token
        name
        email
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type ReassignDonorTicketMutationFn = Apollo.MutationFunction<ReassignDonorTicketMutation, ReassignDonorTicketMutationVariables>;

/**
 * __useReassignDonorTicketMutation__
 *
 * To run a mutation, you first call `useReassignDonorTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignDonorTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignDonorTicketMutation, { data, loading, error }] = useReassignDonorTicketMutation({
 *   variables: {
 *      token: // value for 'token'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useReassignDonorTicketMutation(baseOptions?: Apollo.MutationHookOptions<ReassignDonorTicketMutation, ReassignDonorTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReassignDonorTicketMutation, ReassignDonorTicketMutationVariables>(ReassignDonorTicketDocument, options);
      }
export type ReassignDonorTicketMutationHookResult = ReturnType<typeof useReassignDonorTicketMutation>;
export type ReassignDonorTicketMutationResult = Apollo.MutationResult<ReassignDonorTicketMutation>;
export type ReassignDonorTicketMutationOptions = Apollo.BaseMutationOptions<ReassignDonorTicketMutation, ReassignDonorTicketMutationVariables>;
export const TeamPortalSessionDocument = gql`
    query teamPortalSession($id: ID!) {
  teamPortalSession(id: $id)
}
    `;

/**
 * __useTeamPortalSessionQuery__
 *
 * To run a query within a React component, call `useTeamPortalSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPortalSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPortalSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamPortalSessionQuery(baseOptions: Apollo.QueryHookOptions<TeamPortalSessionQuery, TeamPortalSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamPortalSessionQuery, TeamPortalSessionQueryVariables>(TeamPortalSessionDocument, options);
      }
export function useTeamPortalSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamPortalSessionQuery, TeamPortalSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamPortalSessionQuery, TeamPortalSessionQueryVariables>(TeamPortalSessionDocument, options);
        }
export type TeamPortalSessionQueryHookResult = ReturnType<typeof useTeamPortalSessionQuery>;
export type TeamPortalSessionLazyQueryHookResult = ReturnType<typeof useTeamPortalSessionLazyQuery>;
export type TeamPortalSessionQueryResult = Apollo.QueryResult<TeamPortalSessionQuery, TeamPortalSessionQueryVariables>;
export const TeamSessionCheckoutDocument = gql`
    query teamSessionCheckout($id: ID!) {
  teamSessionCheckout(id: $id)
}
    `;

/**
 * __useTeamSessionCheckoutQuery__
 *
 * To run a query within a React component, call `useTeamSessionCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSessionCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSessionCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamSessionCheckoutQuery(baseOptions: Apollo.QueryHookOptions<TeamSessionCheckoutQuery, TeamSessionCheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamSessionCheckoutQuery, TeamSessionCheckoutQueryVariables>(TeamSessionCheckoutDocument, options);
      }
export function useTeamSessionCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamSessionCheckoutQuery, TeamSessionCheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamSessionCheckoutQuery, TeamSessionCheckoutQueryVariables>(TeamSessionCheckoutDocument, options);
        }
export type TeamSessionCheckoutQueryHookResult = ReturnType<typeof useTeamSessionCheckoutQuery>;
export type TeamSessionCheckoutLazyQueryHookResult = ReturnType<typeof useTeamSessionCheckoutLazyQuery>;
export type TeamSessionCheckoutQueryResult = Apollo.QueryResult<TeamSessionCheckoutQuery, TeamSessionCheckoutQueryVariables>;
export const TeamSubscriptionDocument = gql`
    query teamSubscription($id: ID!) {
  team(id: $id) {
    id
    subscriptionStatus
  }
}
    `;

/**
 * __useTeamSubscriptionQuery__
 *
 * To run a query within a React component, call `useTeamSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<TeamSubscriptionQuery, TeamSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamSubscriptionQuery, TeamSubscriptionQueryVariables>(TeamSubscriptionDocument, options);
      }
export function useTeamSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamSubscriptionQuery, TeamSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamSubscriptionQuery, TeamSubscriptionQueryVariables>(TeamSubscriptionDocument, options);
        }
export type TeamSubscriptionQueryHookResult = ReturnType<typeof useTeamSubscriptionQuery>;
export type TeamSubscriptionLazyQueryHookResult = ReturnType<typeof useTeamSubscriptionLazyQuery>;
export type TeamSubscriptionQueryResult = Apollo.QueryResult<TeamSubscriptionQuery, TeamSubscriptionQueryVariables>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($token: String!) {
  cancelSubscription(input: {attributes: {token: $token}})
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const CreateSubscriptionBillingPortalDocument = gql`
    mutation createSubscriptionBillingPortal($customerId: String!) {
  createSubscriptionBillingPortal(input: {attributes: {customerId: $customerId}})
}
    `;
export type CreateSubscriptionBillingPortalMutationFn = Apollo.MutationFunction<CreateSubscriptionBillingPortalMutation, CreateSubscriptionBillingPortalMutationVariables>;

/**
 * __useCreateSubscriptionBillingPortalMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionBillingPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionBillingPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionBillingPortalMutation, { data, loading, error }] = useCreateSubscriptionBillingPortalMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCreateSubscriptionBillingPortalMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionBillingPortalMutation, CreateSubscriptionBillingPortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionBillingPortalMutation, CreateSubscriptionBillingPortalMutationVariables>(CreateSubscriptionBillingPortalDocument, options);
      }
export type CreateSubscriptionBillingPortalMutationHookResult = ReturnType<typeof useCreateSubscriptionBillingPortalMutation>;
export type CreateSubscriptionBillingPortalMutationResult = Apollo.MutationResult<CreateSubscriptionBillingPortalMutation>;
export type CreateSubscriptionBillingPortalMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionBillingPortalMutation, CreateSubscriptionBillingPortalMutationVariables>;
export const GetSubscriptionFromTokenDocument = gql`
    query getSubscriptionFromToken($token: String!) {
  getSubscriptionFromToken(token: $token) {
    id
    amount
    donations {
      amount
      id
      sentAt
    }
    canceledAt
    nextBillingOn
    interval
  }
}
    `;

/**
 * __useGetSubscriptionFromTokenQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionFromTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionFromTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionFromTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetSubscriptionFromTokenQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionFromTokenQuery, GetSubscriptionFromTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionFromTokenQuery, GetSubscriptionFromTokenQueryVariables>(GetSubscriptionFromTokenDocument, options);
      }
export function useGetSubscriptionFromTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionFromTokenQuery, GetSubscriptionFromTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionFromTokenQuery, GetSubscriptionFromTokenQueryVariables>(GetSubscriptionFromTokenDocument, options);
        }
export type GetSubscriptionFromTokenQueryHookResult = ReturnType<typeof useGetSubscriptionFromTokenQuery>;
export type GetSubscriptionFromTokenLazyQueryHookResult = ReturnType<typeof useGetSubscriptionFromTokenLazyQuery>;
export type GetSubscriptionFromTokenQueryResult = Apollo.QueryResult<GetSubscriptionFromTokenQuery, GetSubscriptionFromTokenQueryVariables>;
export const StripeSubscriptionDocument = gql`
    query stripeSubscription($id: ID!) {
  stripeSubscription(id: $id) {
    id
    amount
    canceledAt
    campaign {
      id
      name
    }
    createdAt
    donor {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useStripeSubscriptionQuery__
 *
 * To run a query within a React component, call `useStripeSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStripeSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>(StripeSubscriptionDocument, options);
      }
export function useStripeSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>(StripeSubscriptionDocument, options);
        }
export type StripeSubscriptionQueryHookResult = ReturnType<typeof useStripeSubscriptionQuery>;
export type StripeSubscriptionLazyQueryHookResult = ReturnType<typeof useStripeSubscriptionLazyQuery>;
export type StripeSubscriptionQueryResult = Apollo.QueryResult<StripeSubscriptionQuery, StripeSubscriptionQueryVariables>;
export const StripeSubscriptionsDocument = gql`
    query stripeSubscriptions($campaignId: ID, $teamId: ID!, $donorId: ID, $first: Int, $after: String, $searchTerm: String) {
  stripeSubscriptions(
    campaignId: $campaignId
    teamId: $teamId
    first: $first
    after: $after
    donorId: $donorId
    searchTerm: $searchTerm
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        amount
        canceledAt
        createdAt
        nextBillingOn
        interval
        token
        campaign {
          id
          name
        }
        donor {
          id
          name
          email
        }
      }
    }
  }
}
    `;

/**
 * __useStripeSubscriptionsQuery__
 *
 * To run a query within a React component, call `useStripeSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSubscriptionsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      teamId: // value for 'teamId'
 *      donorId: // value for 'donorId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useStripeSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>(StripeSubscriptionsDocument, options);
      }
export function useStripeSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>(StripeSubscriptionsDocument, options);
        }
export type StripeSubscriptionsQueryHookResult = ReturnType<typeof useStripeSubscriptionsQuery>;
export type StripeSubscriptionsLazyQueryHookResult = ReturnType<typeof useStripeSubscriptionsLazyQuery>;
export type StripeSubscriptionsQueryResult = Apollo.QueryResult<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($name: String!, $taxId: String!) {
  createTeam(input: {attributes: {name: $name, taxId: $taxId}}) {
    ... on CreateTeamSuccess {
      team {
        id
        name
        logoUrl
        email
        taxId
        address1
        address2
        city
        state
        url
        zip
        totalRaised
        totalLastThirtyRaised
        totalYearRaised
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const EditTeamDocument = gql`
    mutation editTeam($id: ID!, $name: String!, $email: String!, $taxId: String!, $address1: String!, $address2: String!, $city: String!, $state: String!, $zip: String!, $url: String!) {
  editTeam(
    input: {attributes: {id: $id, name: $name, email: $email, taxId: $taxId, address1: $address1, address2: $address2, city: $city, state: $state, zip: $zip, url: $url}}
  ) {
    ... on EditTeamSuccess {
      team {
        id
        name
        logoUrl
        email
        taxId
        address1
        address2
        city
        state
        zip
        totalRaised
        totalLastThirtyRaised
        totalYearRaised
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditTeamMutationFn = Apollo.MutationFunction<EditTeamMutation, EditTeamMutationVariables>;

/**
 * __useEditTeamMutation__
 *
 * To run a mutation, you first call `useEditTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMutation, { data, loading, error }] = useEditTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      taxId: // value for 'taxId'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useEditTeamMutation(baseOptions?: Apollo.MutationHookOptions<EditTeamMutation, EditTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTeamMutation, EditTeamMutationVariables>(EditTeamDocument, options);
      }
export type EditTeamMutationHookResult = ReturnType<typeof useEditTeamMutation>;
export type EditTeamMutationResult = Apollo.MutationResult<EditTeamMutation>;
export type EditTeamMutationOptions = Apollo.BaseMutationOptions<EditTeamMutation, EditTeamMutationVariables>;
export const DeleteTeamInvitationDocument = gql`
    mutation deleteTeamInvitation($id: ID!, $teamId: ID!) {
  deleteTeamInvitation(input: {attributes: {id: $id, teamId: $teamId}}) {
    ... on DeleteTeamInvitationSuccess {
      team {
        id
        pendingTeamInvitations {
          id
          name
          email
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteTeamInvitationMutationFn = Apollo.MutationFunction<DeleteTeamInvitationMutation, DeleteTeamInvitationMutationVariables>;

/**
 * __useDeleteTeamInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteTeamInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamInvitationMutation, { data, loading, error }] = useDeleteTeamInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamInvitationMutation, DeleteTeamInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamInvitationMutation, DeleteTeamInvitationMutationVariables>(DeleteTeamInvitationDocument, options);
      }
export type DeleteTeamInvitationMutationHookResult = ReturnType<typeof useDeleteTeamInvitationMutation>;
export type DeleteTeamInvitationMutationResult = Apollo.MutationResult<DeleteTeamInvitationMutation>;
export type DeleteTeamInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteTeamInvitationMutation, DeleteTeamInvitationMutationVariables>;
export const InviteUserToTeamDocument = gql`
    mutation inviteUserToTeam($email: String!, $name: String!, $id: ID!) {
  inviteUserToTeam(input: {attributes: {email: $email, name: $name, id: $id}}) {
    ... on InviteUserToTeamSuccess {
      team {
        id
        pendingTeamInvitations {
          id
          name
          email
        }
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type InviteUserToTeamMutationFn = Apollo.MutationFunction<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>;

/**
 * __useInviteUserToTeamMutation__
 *
 * To run a mutation, you first call `useInviteUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToTeamMutation, { data, loading, error }] = useInviteUserToTeamMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteUserToTeamMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>(InviteUserToTeamDocument, options);
      }
export type InviteUserToTeamMutationHookResult = ReturnType<typeof useInviteUserToTeamMutation>;
export type InviteUserToTeamMutationResult = Apollo.MutationResult<InviteUserToTeamMutation>;
export type InviteUserToTeamMutationOptions = Apollo.BaseMutationOptions<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>;
export const TeamUsersDocument = gql`
    query teamUsers($id: ID!) {
  team(id: $id) {
    id
    teamUsers {
      id
      user {
        id
        name
        email
        me
      }
    }
    pendingTeamInvitations {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useTeamUsersQuery__
 *
 * To run a query within a React component, call `useTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamUsersQuery(baseOptions: Apollo.QueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
      }
export function useTeamUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export type TeamUsersQueryHookResult = ReturnType<typeof useTeamUsersQuery>;
export type TeamUsersLazyQueryHookResult = ReturnType<typeof useTeamUsersLazyQuery>;
export type TeamUsersQueryResult = Apollo.QueryResult<TeamUsersQuery, TeamUsersQueryVariables>;
export const TeamDocument = gql`
    query team($id: ID!) {
  team(id: $id) {
    id
    name
    logoUrl
    url
    email
    taxId
    address1
    address2
    city
    state
    zip
    totalRaised
    totalLastThirtyRaised
    totalYearRaised
    stripeAccountId
    canConnect
    stripeEnabledAt
    addressLine2
  }
}
    `;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const TeamStatsDocument = gql`
    query teamStats($id: ID!) {
  team(id: $id) {
    id
    totalRaised
    averageRaised
    averageDonations
    donationCount
    activeCampaignCount
  }
}
    `;

/**
 * __useTeamStatsQuery__
 *
 * To run a query within a React component, call `useTeamStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamStatsQuery(baseOptions: Apollo.QueryHookOptions<TeamStatsQuery, TeamStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamStatsQuery, TeamStatsQueryVariables>(TeamStatsDocument, options);
      }
export function useTeamStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamStatsQuery, TeamStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamStatsQuery, TeamStatsQueryVariables>(TeamStatsDocument, options);
        }
export type TeamStatsQueryHookResult = ReturnType<typeof useTeamStatsQuery>;
export type TeamStatsLazyQueryHookResult = ReturnType<typeof useTeamStatsLazyQuery>;
export type TeamStatsQueryResult = Apollo.QueryResult<TeamStatsQuery, TeamStatsQueryVariables>;
export const TeamsDocument = gql`
    query teams($first: Int, $after: String) {
  teams(first: $first, after: $after) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        logoUrl
        url
        email
        taxId
        address1
        address2
        city
        state
        zip
        totalRaised
        totalLastThirtyRaised
        totalYearRaised
      }
    }
  }
}
    `;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTeamsQuery(baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
      }
export function useTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsDocument, options);
        }
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
export const UpdateTeamLogoDocument = gql`
    mutation updateTeamLogo($id: ID!, $logo: Upload!) {
  updateTeamLogo(input: {attributes: {id: $id, logo: $logo}}) {
    ... on UpdateTeamLogoSuccess {
      team {
        id
        name
        logoUrl
        email
        taxId
        address1
        address2
        city
        url
        state
        zip
        totalRaised
        totalLastThirtyRaised
        totalYearRaised
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type UpdateTeamLogoMutationFn = Apollo.MutationFunction<UpdateTeamLogoMutation, UpdateTeamLogoMutationVariables>;

/**
 * __useUpdateTeamLogoMutation__
 *
 * To run a mutation, you first call `useUpdateTeamLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamLogoMutation, { data, loading, error }] = useUpdateTeamLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateTeamLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamLogoMutation, UpdateTeamLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamLogoMutation, UpdateTeamLogoMutationVariables>(UpdateTeamLogoDocument, options);
      }
export type UpdateTeamLogoMutationHookResult = ReturnType<typeof useUpdateTeamLogoMutation>;
export type UpdateTeamLogoMutationResult = Apollo.MutationResult<UpdateTeamLogoMutation>;
export type UpdateTeamLogoMutationOptions = Apollo.BaseMutationOptions<UpdateTeamLogoMutation, UpdateTeamLogoMutationVariables>;
export const CampaignTicketsDocument = gql`
    query campaignTickets($id: ID!) {
  campaign(id: $id) {
    id
    tickets {
      id
      name
      quantity
      price
      description
      attendeesPerTicket
      maximumPerOrder
      maximumAvailable
      startsAt
      endsAt
      fairMarketValueAmount
      purchasedCount
      canDestroy
    }
  }
}
    `;

/**
 * __useCampaignTicketsQuery__
 *
 * To run a query within a React component, call `useCampaignTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTicketsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignTicketsQuery(baseOptions: Apollo.QueryHookOptions<CampaignTicketsQuery, CampaignTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignTicketsQuery, CampaignTicketsQueryVariables>(CampaignTicketsDocument, options);
      }
export function useCampaignTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignTicketsQuery, CampaignTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignTicketsQuery, CampaignTicketsQueryVariables>(CampaignTicketsDocument, options);
        }
export type CampaignTicketsQueryHookResult = ReturnType<typeof useCampaignTicketsQuery>;
export type CampaignTicketsLazyQueryHookResult = ReturnType<typeof useCampaignTicketsLazyQuery>;
export type CampaignTicketsQueryResult = Apollo.QueryResult<CampaignTicketsQuery, CampaignTicketsQueryVariables>;
export const CreateTicketDocument = gql`
    mutation createTicket($name: String!, $quantity: String!, $price: String!, $description: String, $attendeesPerTicket: String, $maximumPerOrder: String!, $startsAt: String!, $endsAt: String!, $fairMarketValueAmount: String!, $campaignId: ID!) {
  createTicket(
    input: {attributes: {name: $name, quantity: $quantity, price: $price, description: $description, attendeesPerTicket: $attendeesPerTicket, maximumPerOrder: $maximumPerOrder, startsAt: $startsAt, endsAt: $endsAt, fairMarketValueAmount: $fairMarketValueAmount, campaignId: $campaignId}}
  ) {
    ... on CreateTicketSuccess {
      ticket {
        id
        name
        quantity
        price
        description
        attendeesPerTicket
        maximumPerOrder
        startsAt
        endsAt
        fairMarketValueAmount
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateTicketMutationFn = Apollo.MutationFunction<CreateTicketMutation, CreateTicketMutationVariables>;

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      name: // value for 'name'
 *      quantity: // value for 'quantity'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *      attendeesPerTicket: // value for 'attendeesPerTicket'
 *      maximumPerOrder: // value for 'maximumPerOrder'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      fairMarketValueAmount: // value for 'fairMarketValueAmount'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCreateTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketMutation, CreateTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketMutation, CreateTicketMutationVariables>(CreateTicketDocument, options);
      }
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>;
export type CreateTicketMutationResult = Apollo.MutationResult<CreateTicketMutation>;
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<CreateTicketMutation, CreateTicketMutationVariables>;
export const DeleteTicketDocument = gql`
    mutation deleteTicket($id: ID!) {
  deleteTicket(input: {attributes: {id: $id}}) {
    ... on DeleteTicketSuccess {
      ticket {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type DeleteTicketMutationFn = Apollo.MutationFunction<DeleteTicketMutation, DeleteTicketMutationVariables>;

/**
 * __useDeleteTicketMutation__
 *
 * To run a mutation, you first call `useDeleteTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketMutation, { data, loading, error }] = useDeleteTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTicketMutation, DeleteTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTicketMutation, DeleteTicketMutationVariables>(DeleteTicketDocument, options);
      }
export type DeleteTicketMutationHookResult = ReturnType<typeof useDeleteTicketMutation>;
export type DeleteTicketMutationResult = Apollo.MutationResult<DeleteTicketMutation>;
export type DeleteTicketMutationOptions = Apollo.BaseMutationOptions<DeleteTicketMutation, DeleteTicketMutationVariables>;
export const DonationTicketsDocument = gql`
    query donationTickets($id: ID!) {
  donation(id: $id) {
    id
    donor {
      id
    }
    amount
    checkedInCount
    totalTicketCount
    donorTickets {
      id
      checkedInAt
      comments
      name
      email
      ticket {
        name
        description
        id
        price
      }
    }
  }
}
    `;

/**
 * __useDonationTicketsQuery__
 *
 * To run a query within a React component, call `useDonationTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationTicketsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationTicketsQuery(baseOptions: Apollo.QueryHookOptions<DonationTicketsQuery, DonationTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationTicketsQuery, DonationTicketsQueryVariables>(DonationTicketsDocument, options);
      }
export function useDonationTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationTicketsQuery, DonationTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationTicketsQuery, DonationTicketsQueryVariables>(DonationTicketsDocument, options);
        }
export type DonationTicketsQueryHookResult = ReturnType<typeof useDonationTicketsQuery>;
export type DonationTicketsLazyQueryHookResult = ReturnType<typeof useDonationTicketsLazyQuery>;
export type DonationTicketsQueryResult = Apollo.QueryResult<DonationTicketsQuery, DonationTicketsQueryVariables>;
export const EditTicketDocument = gql`
    mutation editTicket($name: String!, $quantity: String!, $price: String!, $description: String, $attendeesPerTicket: String, $maximumPerOrder: String!, $startsAt: String!, $endsAt: String!, $fairMarketValueAmount: String, $id: ID!) {
  editTicket(
    input: {attributes: {name: $name, quantity: $quantity, price: $price, description: $description, attendeesPerTicket: $attendeesPerTicket, maximumPerOrder: $maximumPerOrder, startsAt: $startsAt, endsAt: $endsAt, fairMarketValueAmount: $fairMarketValueAmount, id: $id}}
  ) {
    ... on EditTicketSuccess {
      ticket {
        id
        name
        quantity
        price
        description
        attendeesPerTicket
        maximumPerOrder
        startsAt
        endsAt
        fairMarketValueAmount
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type EditTicketMutationFn = Apollo.MutationFunction<EditTicketMutation, EditTicketMutationVariables>;

/**
 * __useEditTicketMutation__
 *
 * To run a mutation, you first call `useEditTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTicketMutation, { data, loading, error }] = useEditTicketMutation({
 *   variables: {
 *      name: // value for 'name'
 *      quantity: // value for 'quantity'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *      attendeesPerTicket: // value for 'attendeesPerTicket'
 *      maximumPerOrder: // value for 'maximumPerOrder'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      fairMarketValueAmount: // value for 'fairMarketValueAmount'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditTicketMutation(baseOptions?: Apollo.MutationHookOptions<EditTicketMutation, EditTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTicketMutation, EditTicketMutationVariables>(EditTicketDocument, options);
      }
export type EditTicketMutationHookResult = ReturnType<typeof useEditTicketMutation>;
export type EditTicketMutationResult = Apollo.MutationResult<EditTicketMutation>;
export type EditTicketMutationOptions = Apollo.BaseMutationOptions<EditTicketMutation, EditTicketMutationVariables>;
export const ReorderTicketDocument = gql`
    mutation reorderTicket($position: Int!, $id: ID!) {
  reorderTicket(input: {attributes: {position: $position, id: $id}}) {
    ... on ReorderTicketSuccess {
      ticket {
        id
        position
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type ReorderTicketMutationFn = Apollo.MutationFunction<ReorderTicketMutation, ReorderTicketMutationVariables>;

/**
 * __useReorderTicketMutation__
 *
 * To run a mutation, you first call `useReorderTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderTicketMutation, { data, loading, error }] = useReorderTicketMutation({
 *   variables: {
 *      position: // value for 'position'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReorderTicketMutation(baseOptions?: Apollo.MutationHookOptions<ReorderTicketMutation, ReorderTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderTicketMutation, ReorderTicketMutationVariables>(ReorderTicketDocument, options);
      }
export type ReorderTicketMutationHookResult = ReturnType<typeof useReorderTicketMutation>;
export type ReorderTicketMutationResult = Apollo.MutationResult<ReorderTicketMutation>;
export type ReorderTicketMutationOptions = Apollo.BaseMutationOptions<ReorderTicketMutation, ReorderTicketMutationVariables>;
export const SendTicketDonationReceiptsDocument = gql`
    mutation sendTicketDonationReceipts($id: ID!, $includePdf: Boolean!) {
  sendTicketDonationReceipts(
    input: {attributes: {id: $id, includePdf: $includePdf}}
  ) {
    ... on SendTicketDonationReceiptsSuccess {
      ticket {
        id
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type SendTicketDonationReceiptsMutationFn = Apollo.MutationFunction<SendTicketDonationReceiptsMutation, SendTicketDonationReceiptsMutationVariables>;

/**
 * __useSendTicketDonationReceiptsMutation__
 *
 * To run a mutation, you first call `useSendTicketDonationReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTicketDonationReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTicketDonationReceiptsMutation, { data, loading, error }] = useSendTicketDonationReceiptsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      includePdf: // value for 'includePdf'
 *   },
 * });
 */
export function useSendTicketDonationReceiptsMutation(baseOptions?: Apollo.MutationHookOptions<SendTicketDonationReceiptsMutation, SendTicketDonationReceiptsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTicketDonationReceiptsMutation, SendTicketDonationReceiptsMutationVariables>(SendTicketDonationReceiptsDocument, options);
      }
export type SendTicketDonationReceiptsMutationHookResult = ReturnType<typeof useSendTicketDonationReceiptsMutation>;
export type SendTicketDonationReceiptsMutationResult = Apollo.MutationResult<SendTicketDonationReceiptsMutation>;
export type SendTicketDonationReceiptsMutationOptions = Apollo.BaseMutationOptions<SendTicketDonationReceiptsMutation, SendTicketDonationReceiptsMutationVariables>;
export const TicketDocument = gql`
    query ticket($id: ID!) {
  ticket(id: $id) {
    id
    name
    quantity
    price
    description
    attendeesPerTicket
    maximumPerOrder
    startsAt
    endsAt
    fairMarketValueAmount
    canDestroy
  }
}
    `;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(baseOptions: Apollo.QueryHookOptions<TicketQuery, TicketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
      }
export function useTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
        }
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = Apollo.QueryResult<TicketQuery, TicketQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(input: {attributes: {email: $email}})
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const CreateSessionDocument = gql`
    mutation createSession($email: String!, $password: String!) {
  createSession(input: {attributes: {email: $email, password: $password}}) {
    ... on CreateSessionSuccess {
      token
      user {
        id
        email
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserPasswordFromTokenDocument = gql`
    mutation updateUserPasswordFromToken($token: String!, $password: String!) {
  updateUserPasswordFromToken(
    input: {attributes: {token: $token, password: $password}}
  ) {
    ... on UpdateUserPasswordFromTokenSuccess {
      token
      user {
        id
        email
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type UpdateUserPasswordFromTokenMutationFn = Apollo.MutationFunction<UpdateUserPasswordFromTokenMutation, UpdateUserPasswordFromTokenMutationVariables>;

/**
 * __useUpdateUserPasswordFromTokenMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordFromTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordFromTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordFromTokenMutation, { data, loading, error }] = useUpdateUserPasswordFromTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserPasswordFromTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordFromTokenMutation, UpdateUserPasswordFromTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordFromTokenMutation, UpdateUserPasswordFromTokenMutationVariables>(UpdateUserPasswordFromTokenDocument, options);
      }
export type UpdateUserPasswordFromTokenMutationHookResult = ReturnType<typeof useUpdateUserPasswordFromTokenMutation>;
export type UpdateUserPasswordFromTokenMutationResult = Apollo.MutationResult<UpdateUserPasswordFromTokenMutation>;
export type UpdateUserPasswordFromTokenMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordFromTokenMutation, UpdateUserPasswordFromTokenMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($email: String!, $password: String!, $name: String!, $teamInvitationToken: String) {
  createUser(
    input: {attributes: {email: $email, password: $password, name: $name, teamInvitationToken: $teamInvitationToken}}
  ) {
    ... on CreateUserSuccess {
      token
      user {
        id
        name
        email
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *      teamInvitationToken: // value for 'teamInvitationToken'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreatePublicCommentDocument = gql`
    mutation createPublicComment($message: String!, $displayDonorInfo: Boolean!, $displayAmount: Boolean!, $stripeId: String!) {
  createPublicComment(
    input: {attributes: {message: $message, displayDonorInfo: $displayDonorInfo, displayAmount: $displayAmount, stripeId: $stripeId}}
  ) {
    ... on CreatePublicCommentSuccess {
      publicComment {
        id
        message
        redirectUrl
      }
    }
    ... on Error {
      errors
    }
  }
}
    `;
export type CreatePublicCommentMutationFn = Apollo.MutationFunction<CreatePublicCommentMutation, CreatePublicCommentMutationVariables>;

/**
 * __useCreatePublicCommentMutation__
 *
 * To run a mutation, you first call `useCreatePublicCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicCommentMutation, { data, loading, error }] = useCreatePublicCommentMutation({
 *   variables: {
 *      message: // value for 'message'
 *      displayDonorInfo: // value for 'displayDonorInfo'
 *      displayAmount: // value for 'displayAmount'
 *      stripeId: // value for 'stripeId'
 *   },
 * });
 */
export function useCreatePublicCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicCommentMutation, CreatePublicCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublicCommentMutation, CreatePublicCommentMutationVariables>(CreatePublicCommentDocument, options);
      }
export type CreatePublicCommentMutationHookResult = ReturnType<typeof useCreatePublicCommentMutation>;
export type CreatePublicCommentMutationResult = Apollo.MutationResult<CreatePublicCommentMutation>;
export type CreatePublicCommentMutationOptions = Apollo.BaseMutationOptions<CreatePublicCommentMutation, CreatePublicCommentMutationVariables>;