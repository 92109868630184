import { useCookies } from "react-cookie";
import uuid from "./uuid";

const useSetUUID = () => {
  const [cookies, setCookie] = useCookies();

  const setUuid = ({ userId }) => {
    // Currently will not be reset when someone logs out or users share computer
    if (!cookies.uuid) {
      setCookie("uuid", userId || uuid(), {
        sameSite: "strict",
        path: "/"
      })
    } else if (userId && userId !== cookies.uuid) {
      setCookie("uuid", userId, {
        sameSite: "strict",
        path: "/"
      })
    }
  };

  return setUuid;
};

export default useSetUUID;
