import Footer from "../../components/footer";
import Features from "../../components/home/features";
import Hero from "../../components/home/Hero";
import Pricing from "../../components/home/pricing";
import NavBar from "../../components/navbar/Header";

const Home = () => {
  return (
    <>
      <NavBar />
      <Hero />
      <Features />
      <Pricing />
      <Footer />
    </>
  );
};

export default Home;
